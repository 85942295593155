<footer class="footer bg-dark text-light mt-3">
    <div class="row">
        <div class="col-lg-3">
            <a href="https://www.webeventstudios.com" target="blank"><img src="../../../assets/images/wew.png" width="50%"></a>
        </div>
        <div class="col-lg-6 my-3">
            <div class="mx-3">
                <h4 class="text-light">Unterstützung</h4>
                <p class="text-light">Sie benötigen Unterstützung für Ihren digitalen Messeauftritt? Unser Expertenteam unterstützt Sie gerne!</p>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <a type="button" class="btn btn-primary btn-block mx-3" href="mailto:jw@event-coach.com?subject=Coaching%20anfordern" target="blank">Coaching anfordern</a>
                    <a type="button" class="btn btn-primary btn-block mx-3" href="mailto:jw@event-coach.com?subject=Designberatung%20anfordern" target="blank">Designberatung anfordern</a>
                </div>
                <div class="col-sm-6">
                    <a type="button" class="btn btn-primary btn-block mx-3" href="mailto:vhs-arberland@webeventsudios.com?subject=Technik%20anmieten" target="blank">Technik anmieten</a>
                    <a type="button" class="btn btn-primary btn-block mx-3" href="mailto:vhs-arberland@webeventsudios.com?subject=Imagefilm%20erstellen" target="blank">Imagefilm erstellen</a>
                </div>
            </div>
        </div>
        <div class="col-lg-3 my-3">
            <p class="text-center"><a href="https://www.webeventstudios.com/page-3" target="blank" class="text-light">Impressum</a>&nbsp;-&nbsp;<a href="https://www.webeventstudios.com/page-4" target="blank" class="text-light">Datenschutz</a></p>
        </div>
    </div>
</footer>
    

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Chatroom } from '../../_models/chatroom';
import { ChatroomService } from '../../_services/chatroom.service';

@Component({
  selector: 'app-chat-create',
  templateUrl: './chat-create.component.html',
  styleUrls: ['./chat-create.component.css']
})
export class ChatCreateComponent implements OnInit {

  chatroom: Chatroom = new Chatroom();

  constructor(
    private chatroomService: ChatroomService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  save(): void {
    this.chatroomService.createChatroom(this.chatroom).subscribe(() =>
    this.router.navigate(['/admin/chat'])
  );
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Company } from '../../_models/company';
import { Role } from '../../_models/role';
import { User } from '../../_models/user';
import { CompanyService } from '../../_services/company.service';
import { RoleService } from '../../_services/role.service';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {

  user: User = new User();
  roles: Role[];
  companies: Company[];

  constructor(
    private userService: UserService,
    private router: Router,
    private roleService: RoleService,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    this.user.role = new Role();
    this.user.company = new Company();

    this.getRoles();
    this.getCompanies();
  }

  getRoles(): void {
    this.roleService.getRoles().subscribe(roles =>
      this.roles = roles
    );
  }

  getCompanies(): void {
    this.companyService.getCompanies().subscribe(companies =>
      this.companies = companies
    );
  }

  save(): void {
    this.userService.createUser(this.user).subscribe(() =>
      this.router.navigate(['/admin/user'])
    );
  }
}

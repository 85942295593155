import { Injectable } from '@angular/core';
import {UsersService} from './users.service';
import {DecodeTokenService} from './decode-token.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class TeleporterService {
userData: any;
  constructor(
      private usersService: UsersService,
      private decodeTokenService: DecodeTokenService,
      private route: ActivatedRoute
  ){}

  teleport(id){
      
    console.log(id);
    var userId = this.decodeTokenService.decodeUserId();
    this.usersService.getUser(userId)
    .subscribe(userData => this.teleport2(userData ,id));
    
  }
  
  teleport2(data, id){
    if (data.currentLocation.id.toString() == id) {
        
    }else{
        window.location.href = "map/"+id;
    }
  }
}



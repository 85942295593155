import { Component, OnInit, AfterViewInit } from '@angular/core';
import * as Leaflet from 'leaflet';
import { antPath } from 'leaflet-ant-path';
import { CheckboxRequiredValidator } from '@angular/forms';
import { __values } from 'tslib';
import { visitValue } from '@angular/compiler/src/util';
import { MapService } from '../services/map.service';
import { Map } from '../models/map';
import { ActivatedRoute } from '@angular/router';
import { Branches } from '../models/branches';
import { BranchesService } from '../services/branches.service';
import { forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import {DecodeTokenService} from '../services/decode-token.service';
import {Marker} from '../classes/markers';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
     constructor(private sanitizer: DomSanitizer) {}
     transform(url) {
       return this.sanitizer.bypassSecurityTrustResourceUrl(url);
     }
} 

@Component({
  selector: 'app-map2',
  templateUrl: './map2.component.html',
  styleUrls: ['./map2.component.css']
})
export class Map2Component implements OnInit {
  firma: any;
  antwort: Map;
  branches: any;
  laenge: any;
  title = 'leafletApps';
  map: Leaflet.Map;
  marks: any[];
  term2;
  mar : Leaflet.marker[];
  markers = [];


  constructor(
    private route: ActivatedRoute, 
    private mapService: MapService, 
    private branchesService: BranchesService,
    private domSanitizer: DomSanitizer,
    private decodeTokenService: DecodeTokenService
  ){}

  

  //Legacy, muss erneuert werden.
  einblenden(id): void {
    console.log(id);
  }

  //Map erstellen
  generateMap(mapJson, filter){

    
    //Bei Partnergebäude keinen Filter anzeigen
    if (mapJson.type == "partner") {
      document.getElementById("filter").style.display = "none";
    }



    if(filter == ''){
      this.laenge = 0;


   


    var h = mapJson.backgroundHeight;
    var w = mapJson.backgroundWidth;
    this.map = Leaflet.map('map', {crs: Leaflet.CRS.Simple, center: [mapJson.midX, mapJson.midY], minZoom: mapJson.zoomMin, maxZoom: mapJson.zoomMax}).setView([mapJson.midX, mapJson.midY], mapJson.zoomDefault);
    /*var southWest = this.map.unproject([0, h], this.map.getMaxZoom() - 1);
    var northEast = this.map.unproject([w, 0], this.map.getMaxZoom() - 1);
    var bounds = new Leaflet.LatLngBounds(southWest, northEast);*/
    var bounds = [[0,0], [h,w]]; 
    var image = Leaflet.imageOverlay(mapJson.background.url, bounds).addTo(this.map);
    this.map.setMaxBounds(bounds);








//Zoom auslesen und Popups ein/ausblenden
//this.map.on('zoom', console.log("Zoom"));

}

this.addMarkers(mapJson);
  }



  addMarkers(mapJson){
     //eigenen Marker für Standardgebäude definieren
     var mediaMarker = Leaflet.icon({
      iconUrl: 'assets/images/transparent.png',
      iconSize: [1, 1],
      iconAnchor: [1, 1],
      });

    

    //Unterscheidung zwischen Partner- und Hauptkarte,
    //dementsprechende Zuordnung der Marker in das Array;
    //bei Hauptkarte: Verlinkungen auf Partner
    //bei Partner: Modalöffner (Bootstrap) für Medien
    if(mapJson.type == 'partner'){
      console.log(mapJson.type);
      for (let i = 0; i < mapJson.media.length; i++) {
        this.markers.push({pos: [mapJson.media[i].positionX, mapJson.media[i].positionY],
        popup: '<a data-toggle="modal" data-target=".'+mapJson.media[i].media.id+'" (click)="videoStarten(media.media.id)"><button class="bild" (click)="videoStarten(media.media.id)"><img src="'+mapJson.media[i].icon.url+'" style="max-width: 3em; max-height: 3em;" /></button><br><button class="partnerMediaBeschreibung" (click)="videoStarten(media.media.id)">'+mapJson.media[i].media.description+'</button>',
        tooltip: mapJson.media[i].name,
        type: 'media',
        id: mapJson.media[i].media.id });
      }
    }else if (mapJson.type == 'default'){
        for (let i = 0; i < mapJson.mapChilds.length; i++) {
            if (mapJson.mapChilds[i].icon.id == 10) {
              this.markers.push({pos: [mapJson.mapChilds[i].positionX, mapJson.mapChilds[i].positionY],
              popup: '<a href="map/'+mapJson.mapChilds[i].childMap.id + '">'+mapJson.mapChilds[i].childMap.description,
              tooltip: mapJson.mapChilds[i].childMap.name,
              type: mapJson.mapChilds[i].childMap.type,
              id: mapJson.mapChilds[i].childMap.id});
            }else{
              this.markers.push({pos: [mapJson.mapChilds[i].positionX, mapJson.mapChilds[i].positionY],
              popup: '<a href="map/'+mapJson.mapChilds[i].childMap.id + '">'+'<img src="'+mapJson.mapChilds[i].icon.url+'" />'+mapJson.mapChilds[i].childMap.description,
              tooltip: mapJson.mapChilds[i].childMap.name,
              type: mapJson.mapChilds[i].childMap.type,
              id: mapJson.mapChilds[i].childMap.id});
            }
        }
    }


    var p = Array();
    var m = Array();
    console.log(this.markers);

  //Marker zur Karte hinzufügen
  this.markers.forEach(function(obj){
    if (obj.type=='default') {
      this.mar[obj.id] = Leaflet.marker(obj.pos);
      p[obj.id] = new Leaflet.Popup({
        autoClose: false,
        closeOnClick: false,
        autoPan: false,
        className: 'popp'
      })
        .setContent(obj.popup)
        .setLatLng(obj.pos);
        this.mar[obj.id].bindPopup(p[obj.id]);
        this.mar[obj.id].bindTooltip(obj.tooltip);
    } else if(obj.type =='partner') {
      this.mar[obj.id] = Leaflet.marker(obj.pos);
      p[obj.id] = new Leaflet.Popup({
        autoClose: false,
        closeOnClick: false,
        autoPan: false,
      })
        .setContent(obj.popup)
        .setLatLng(obj.pos);
        this.mar[obj.id].bindPopup(p[obj.id]);
        this.mar[obj.id].bindTooltip(obj.tooltip);
    } else if(obj.type='media'){
      this.mar[obj.id] = Leaflet.marker(obj.pos, {icon: mediaMarker});
      p[obj.id] = new Leaflet.Popup({
        autoClose: false,
        closeOnClick: false,
        autoPan: false,
        className: 'media'
      })
        .setContent(obj.popup)
        .setLatLng(obj.pos);
        this.mar[obj.id].bindPopup(p[obj.id]);
    }

    
  })

  for (let index = 0; index < this.mar.length; index++) {
    try{
      this.mar[index].addTo(this.map);
      this.mar[index].openPopup();} catch{}
    this.laenge = this.laenge +1;
  }
  }


  //Datenbankanbindung
  getBranches(){
    this.branchesService.getBranches()
    .subscribe(branches => this.branches = branches);
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    //evtl Weiterleiten auf Standardgebäude
    if (id == '4') {
      window.location.href = "welcomecenter";
    }else if (id == '5') {
      window.location.href = "cityhall";
    }else if (id == '8') {
      window.location.href = "mediacenter";
    }
    //Datenbankverbindung
    this.mapService.getMap(id)
      .subscribe(firma => this.firma = firma);
    this.mapService.getMap(id)
      .subscribe(antwort => this.generateMap(antwort, ''));
    this.getBranches();
    //this.decodeTokenService.decodeUserId(); //UserID erhalten
}

  //Legacy: Branchenfilter; muss überarbeitet werden
  filterAnwenden(name): void{
    if (name == 'alle') {
      this.generateMap('', 'alle');
      for (let index = 0; index < 100; index++) {
        try {
          var element2 = <HTMLInputElement> document.getElementById(index.toString());
          element2.checked = true;
        } catch (error) {}
      
      }  
      this.refresh();
    }else{
      this.generateMap('', name);
      var element = <HTMLInputElement> document.getElementById(name);
    var isChecked = element.checked;
    } 
    console.log(name + '. Checkbox: ' + isChecked)
  }

  //Seite neu laden
  refresh(){
    window.location.reload();
  }

  //Suchergebnisse im Filter anzeigen/ausblenden
  ergebnisseAnzeigen(term2){
    if(term2 == ''){
      document.getElementById("suchErgebnisse").style.display = "none";
    }else{
      document.getElementById("suchErgebnisse").style.display = "block";
    }
  }

  //Dokumenteinbindung: Quelle als sicher deklarieren
  getSource(url){
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  videoStarten(id){
    let videoPlayer: HTMLMediaElement = document.querySelector("[id='" + id.toString() + "']");
    videoPlayer.setAttribute('src', id);
    console.log("Video gestartet");
    videoPlayer.play();
  }

  videoStoppen(id){
    var id2 = id.toString();
    let videoPlayer: HTMLMediaElement = document.querySelector("[id='" + id + "']");
    videoPlayer.muted = true;
    console.log('Video gestoppt');
    videoPlayer.pause();
  }

  ytStop(){
    var frame = document.getElementById("iframeid");
    //frame.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
  }

}
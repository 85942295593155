
<body>
  <ul class="circles">
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
    <li></li>
  </ul>
  <!--<img class="LogoEinloggen" src="assets/images/wew_cropped.png" />-->
    <div class="loginForm">
     
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">

            <div class="form-group">
              
                <h1>Anmelden</h1>
                <p>Um Ihnen das bestmögliche Erlebnis bieten zu können, bitten wir Sie, sich anzumelden. Nur so können Sie die interaktive Welt der WebEventStudios voll genießen.</p>
                <label for="exampleInputEmail1">Email Adresse</label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Emailadresse eingeben" formControlName="email">
                <small id="emailHelp" class="form-text text-muted">Wir geben Ihre Emailadresse nicht an Dritte weiter.</small>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Passwort</label>
                <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Passwort" formControlName="password">
              </div>
              <!--<div class="form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                <label class="form-check-label" for="exampleCheck1">Ich bin mit den AGB einverstanden.</label>
              </div>-->
              <button type="submit" class="btn btn-primary">Einloggen</button>
        </form>
        <br>
        <p>Sie haben noch keine Zugangsdaten? <a href="registrieren">Registrieren Sie sich hier!</a></p>
    </div>


    <div class="btn-group impressBtn" role="group" aria-label="Basic example">
      <a href="https://webeventstudios.com/page-3/" target="blank" type="button" class="btn btn-outline-light">Impressum</a>
      <a href="https://webeventstudios.com/page-4/" target="blank" type="button" class="btn btn-outline-light">Datenschutz</a>
      <a href="mailto:info@webeventstudios.com" target="blank" type="button" class="btn btn-outline-light">Technischer Support</a>
    </div>
</body>

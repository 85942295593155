<p *ngIf="chatmessages.length === 0">Aktuell sind keine Fragen vorhanden!</p>

<div id="privateNachrichten">
    <ul id="myList2">
        <li *ngFor="let chatmessage of chatmessages; index as index" >
            <div *ngIf="chatmessage?.sender.id == userId" class="dummy speech-bubble-self">
                <italic>{{chatmessage?.created | date:'shortTime'}}: </italic>
                <bold class="fett">{{chatmessage?.sender.firstName}} {{chatmessage?.sender.lastName}}: </bold>
                {{chatmessage?.content}}
            </div>
            <div *ngIf="chatmessage?.sender.id != userId" class="dummy speech-bubble" [ngClass]="{sent: chatmessage.sent}">
                <div class="d-flex justify-content-between">
                    <div>
                        <italic>{{chatmessage?.created | date:'shortTime'}}: </italic>
                        <bold class="fett">{{chatmessage?.sender.firstName}} {{chatmessage?.sender.lastName}}: </bold>
                        {{chatmessage?.content}}
                    </div>
                    <button *ngIf="!chatmessage.sent" type="button" class="btn btn-primary" (click)="sendToModerator(index)">Senden</button>
                </div>
            </div>
        </li>
    </ul>
</div>
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class DatabaseService {
  returnDatabaseUrl(){
      return this.returnDatabaseUrlWithoutSlash()+"/"; //Wichtig: MIT "/" am Ende angeben!
  }

  returnDatabaseUrlWithoutSlash(){
    return `${environment.frontendApiUrl}/api`;
  }
}



<h3>Chaträume</h3>

<p>
    <a type="button" class="btn btn-primary mr-1" routerLink="/admin/chat/create">Neu erstellen</a>
    <a type="button" class="btn btn-secondary ml-1" routerLink="/admin/chat/createbatch">CSV Import</a>
</p>

<table class="table table-hover">
    <thead>
        <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Beschreibung</th>
            <th scope="col">Privat</th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let chatroom of chatrooms" routerLink="/admin/chat/edit/{{chatroom.id}}">
            <th scope="row">{{chatroom.id}}</th>
            <td>{{chatroom.name}}</td>
            <td>{{chatroom.description}}</td>
            <td>{{chatroom.private}}</td>
        </tr>
    </tbody>
</table>
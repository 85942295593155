import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Media } from '../../_models/media';
import { Mediatype } from '../../_models/mediatype';
import { MediaService } from '../../_services/media.service';
import { MediatypeService } from '../../_services/mediatype.service';

@Component({
  selector: 'app-media-link-create',
  templateUrl: './media-link-create.component.html',
  styleUrls: ['./media-link-create.component.css']
})
export class MediaLinkCreateComponent implements OnInit {

  media: Media = new Media();
  mediatypes: Mediatype[];

  @Output() selectMediaEvent = new EventEmitter<Media>();

  constructor(
    private mediaService: MediaService,
    private mediatypeService: MediatypeService
    ) { }

  ngOnInit(): void {
    this.getMediatypes();
    this.media.mediatype = new Mediatype();
  }

  getMediatypes(): void {
    this.mediatypeService.getMediatypes().subscribe(mediatypes => {
      console.log(mediatypes);
      this.mediatypes = mediatypes;
    });
  }

  saveMedia(): void {
    this.media.mediatype.id = 12;

    this.mediaService.createMedia(this.media).subscribe(media => {
      console.log(media);
      this.selectMediaEvent.emit(media);
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { AgendaService } from '../../_services/agenda.service';
import { Agenda } from '../../_models/agenda';
import { Map } from '../../_models/map';
import { MapService } from '../../_services/map.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-agenda-create',
  templateUrl: './agenda-create.component.html',
  styleUrls: ['./agenda-create.component.css']
})
export class AgendaCreateComponent implements OnInit {

  agenda: Agenda = new Agenda();
  maps: Map[];

  constructor(
    private agendaService: AgendaService,
    private mapService: MapService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.agenda.location = new Map();

    this.getMaps();
  }

  getMaps(): void {
    this.mapService.getMaps().subscribe(maps =>
      this.maps = maps
    );
  }

  save(): void {
    this.agendaService.createAgenda(this.agenda).subscribe(() =>
      this.router.navigate(['/admin/agenda'])
    );
  }

}

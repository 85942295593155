<div class="container">
    <div class="alert alert-warning alert-dismissible fade show alert-on-top" [ngClass]="alertclass" *ngIf="alertshow">
        <strong>Das ist ein Test!{{alertmessage}}</strong>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>

<div class="row">
    <div class="col-lg-3">
        <app-map-detail-edit [map]="map" (saveMap)="updateMapDetails($event)" (resetMap)="reset($event)"></app-map-detail-edit>
    </div>
    <div class="col-lg-9">
        <div class="alert alert-warning alert-dismissible fade show my-3" id="mediaPositionHint" role="alert">
            Klicken Sie auf die Karte, um Medien zu positionieren.<br>
            Klicken Sie auf bestehende Positionen, um diese zu bearbeiten.
            <button type="button" class="close" data-dismiss="alert" aria-label="Schließen">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="map-container">
            <app-map-interactive-edit [mapInput]="map" (newMediaPositionEvent)="openMediaPositionCreateDialog($event)" (updateMediaPositionEvent)="openMediaPositionEditDialog($event)"></app-map-interactive-edit>
        </div>
    </div>
</div>

<app-map-position-create [mediaPosition]="newMediaPosition" (saveMediaPosition)="saveMediaPosition($event)" (resetMap)="reset($event)"></app-map-position-create>
<app-map-position-edit [mediaPosition]="selectedMediaPosition" (saveMediaPosition)="updateMediaPosition($event)" (deleteMediaPosition)="removeMediaPosition($event)" (resetMap)="reset($event)"></app-map-position-edit>
import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-userueberwachung',
  templateUrl: './userueberwachung.component.html',
  styleUrls: ['./userueberwachung.component.css']
})
export class UserueberwachungComponent implements OnInit {
  users: any;
  constructor(
    private usersService: UsersService
  ) { }

  ngOnInit(): void {
    
    setInterval(()=> { this.renewOnlineUsers(); }, 1000);
    this.renewOnlineUsers();
  }

  renewOnlineUsers(){
    this.usersService.getUsers()
    .subscribe(users => this.users = users);
    let namen = new Array();

      let element = document.getElementById("online");
      let counter = 0; 
      
      for (let index = 0; index < this.users.length; index++) {
       try{
          namen.push('<br><strong>'+this.users[index].firstName + ' ' + this.users[index].lastName + '</strong> ist in: <strong>' + this.users[index].currentLocation.name +'</strong> seit: '+this.users[index].updated);
          //element.innerHTML = this.users[index].firstName + ' ' + this.users[index].lastName + ' ist in: ' + this.users[index].currentLocation.name + '<br>';
       }catch{}
    }
    element.innerHTML = namen.toString();
  }
    


  

}

<ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/maps">Karten</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/companies">Firmen</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLinkActive="active" routerLink="/admin/branches">Branchen</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/admin/media">Medien</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/admin/mediatypes">Medientypen</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/admin/agenda">Agenda</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/admin/users">Nutzer</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/admin/groups">Gruppen</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/admin/roles">Rollen</a>
    </li>
    <li class="nav-item">
        <a class="nav-link" routerLinkActive="active" routerLink="/admin/votings">Abstimmungen</a>
    </li>
</ul>

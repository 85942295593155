import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './auth.service';
import {FormControl, FormsModule} from '@angular/forms';
import {FormGroup} from '@angular/forms';
import { LoginComponent } from './login/login.component';
import {ReactiveFormsModule} from '@angular/forms';



@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ]
})
export class AuthModule {

}

import { Component, OnInit } from '@angular/core';
import { GatewayService } from '../services/gateway.service';
import { VotingsService } from '../services/votings.service';
import { Voting } from '../admin/_models/voting';
import { DecodeTokenService } from '../services/decode-token.service';

@Component({
  selector: 'app-voting-frontend',
  templateUrl: './voting-frontend.component.html',
  styleUrls: ['./voting-frontend.component.css']
})
export class VotingFrontendComponent implements OnInit {

  public voting: Voting;
  public showVoting: boolean = true;
  public votingGets: any;

  constructor(
    private gatewayService: GatewayService,
    private votingsService: VotingsService,
    private decodeTokenService: DecodeTokenService
    ) { }

  ngOnInit(): void {
    this.gatewayService.joinRoom(5);
    this.gatewayService.receiveVoting().subscribe((voting: Voting) => {
      document.getElementById('newVote').style.display = "block";

    });
/*
    this.gatewayService.receiveVotingEnd().subscribe((voting: Voting) => {
      console.log(voting);
      this.showVoting = false;

    });  */
    this.getVotings();
  }

  getVotings(){
    this.votingsService.getVotings().subscribe((data:any[]) => {
      let dataUnSorted = data;
      let dataSorted = new Array();
      console.log(dataUnSorted);
      for (let index = 0; index < dataUnSorted.length; index++) {
        try {
          let now = new Date().getTime();
          let startdate = new Date(dataUnSorted[index].startdate).getTime();
          let expiredate = new Date(dataUnSorted[index].expiredate).getTime();
         // console.log(expiredate);
         // console.log(startdate);
         // console.log(now);
          if (expiredate == 0 || expiredate > now) {
            if(startdate < now){
              if(startdate != 0){
                this.votingsService.getVoting(dataUnSorted[index].id).subscribe((data)=>{dataSorted.push(data)}); 
                document.getElementById('newVote').style.display = "block";
              }
            }
            
          }
        } catch (error) {}
        this.votingGets = dataSorted;
        //console.log(this.votingGets);
      }
    })

    
  }

  abstimmen(id, votingId){
    console.log(this.decodeTokenService.decodeUserId());
    console.log(id);
    const userId = this.decodeTokenService.decodeUserIdInt();
    this.votingsService.vote(id, userId, votingId);
    this.showVotings(false);
    window.alert('Vielen Dank für Ihre Stimme!');
  }

  showVotings(boolean: boolean){
    const panel = document.getElementById('panel');
    if (boolean) {
      panel.style.display = 'block';
      this.getVotings();
    }else{
      panel.style.display = "none";
    }
  }

}


<app-navigation></app-navigation>

<body>
  <div id="startupMedia"></div>
<div id="map"></div>

<a href="{{firma?.link}}" target="blank" class="companyLogoTop" *ngIf="firma?.icon?.url" title="{{firma?.name}}"><img src="{{firma?.icon?.url}}" /></a>
<img src="assets/movecursor.png" class="movehint" (click)="meldung('Sie können sich im Center bewegen, um alle Inhalte zu sehen.')" />


<div class="row" id="filter" style="z-index: 10000;">
    
</div>



<div *ngFor="let mediaposition of firma?.mediapositions">
  
  
  
  <div class="w3-modal w3-animate-opacity" id="folder{{mediaposition.id}}">
    <div class="w3-modal-content">
      <div class="w3-container">
        <span (click)="closeWindow(mediaposition.id)"
        class="w3-button w3-xlarge w3-hover-red w3-display-topright">&times;</span>
        <h3 style="color: var(--main-color);">{{mediaposition.title}}</h3>
        <h5 style="color: var(--main-color);">{{mediaposition.description}}</h5>

        <div *ngIf="isGalleryPos(mediaposition.id)">
          <div *ngFor="let gallery of mediaposition?.media; let i=index">
            <!-- The Gallery -->
            <div *ngIf="i<1">

                  <div class="imgContainer galleryWrapper">
                    <div *ngFor="let image of mediaposition?.media">
                        <img *ngIf="image?.mediatype.id === 1" class="mySlides{{mediaposition?.id}} w3-animate-opacity slideImg" src="{{image?.url}}" style="max-width: 100%;" />
                    </div>
                  
                  <button class="w3-button w3-light-grey w3-display-left" (click)="plusDivs(-1)">&#10094;</button>
                  <button class="w3-button w3-light-grey w3-display-right" (click)="plusDivs(+1)">&#10095;</button>
                </div>
            </div>
          </div>
      </div>
      
        <div class="w3-row" *ngFor="let media of mediaposition?.media">
          <div *ngIf="media.mediatype.id === 4" title="{{media.name}}" (click)="openModal(media.id)" style="cursor: pointer;">
            <br>
            <div class="w3-container w3-third"><video src="{{media.url}}" style="width: 100%; height: 100%;" preload="metadata" muted></video></div>
            <div class="w3-container w3-twothird"><h4 class="maincol">{{media.name}}</h4><h6>{{media.description}}</h6></div>
          </div>
          <div *ngIf="media.mediatype.id === 6" title="{{media.name}}" (click)="openModalYt(media.id, media.url)" style="cursor: pointer;">
            <br>
            <div class="w3-container w3-third"><img src="https://img.youtube.com/vi/{{media.url}}/maxresdefault.jpg" style="max-width: 100%;" /></div>
            <div class="w3-container w3-twothird"><h4 class="maincol">{{media.name}}</h4><h6>{{media.description}}</h6></div>
          </div>
          <div *ngIf="media.mediatype.id === 10" title="{{media.name}}" (click)="openModalVi(media.id, media.url)" style="cursor: pointer;">
            <br>
            <div class="w3-container w3-third"><img src="assets/images/video.png" style="max-width: 100%;" /></div>
            <div class="w3-container w3-twothird"><h4 class="maincol">{{media.name}}</h4><h6>{{media.description}}</h6></div>
          </div>
         
        </div>


        <div class="previewContainer w3-row">
        <!--  <div class="previewElement preview w3-col" title="Galerie" (click)="openModal(mediaposition?.media[0].id)"><img src="assets/images/image-black.png" class="previewImage" /></div>-->
        <div *ngFor="let media of mediaposition?.media">
          <a class="previewElement preview w3-col" (click)="openModalDoc(media.id, media.url)" target="blank" *ngIf="media.mediatype.id === 2" title="{{media.name}}"><img src="assets/images/document-black.png" class="previewImage" /></a>
         <!-- <div class="previewElement preview w3-col" (click)="openModal(media.id)" *ngIf="media.mediatype.id === 4" title="{{media.name}}"><img src="assets/images/video.png" class="previewImage" /></div> 
          <div class="previewElement preview w3-col" (click)="openModalYt(media.id, media.url)" *ngIf="media.mediatype.id === 6" title="{{media.name}}"><img src="assets/images/video.png" class="previewImage" /></div> -->
          <div class="previewElement preview w3-col" (click)="openModal(media.id);" *ngIf="media.mediatype.id === 8" title="{{media.name}}"><img src="assets/images/contactcard-black.png" class="previewImage" /></div>
         <!-- <div class="previewElement preview w3-col" (click)="openModalVi(media.id, media.url)" *ngIf="media.mediatype.id === 10" title="{{media.name}}"><img src="assets/images/video.png" class="previewImage" /></div> -->
          <div class="previewElement preview w3-col" (click)="openModal(media.id);" title="Firmenprofil" *ngIf="media.mediatype.id === 11"><img src="{{media.url}}" class="previewImage" /></div>
          <a class="previewElement preview w3-col" href="{{media?.url}}" target="blank" *ngIf="media.mediatype.id === 12" title="{{media.name}}"><img src="assets/images/link.png" class="previewImage" /></a>
        </div>
        <div *ngFor="let videochatroom of mediaposition?.videochatrooms">
          <div class="previewElement preview w3-col" title="{{videochatroom?.name}}" (click)="openVideochat(videochatroom.id)"><img src="assets/images/videochat.png" class="previewImage" /></div>
        </div> 
      </div>
      </div>
    </div>
  </div>




    <div *ngFor="let media of mediaposition?.media">
      <!-- The Videomodal -->
        <div *ngIf="media.mediatype.id === 4" id="modal{{media.id}}" class="w3-modal w3-animate-opacity">
          <div class="w3-modal-content">
            <div class="w3-container">
              <span (click)="closeModal(media.id)"
              class="w3-button w3-xlarge w3-hover-red w3-display-topright">&times;</span>
              <h3 class="maincol">{{media.name}}</h3>
              <h5 class="maincol">{{media.description}}</h5>
              <video id="video{{media.id}}" src="{{media.url}}" style="width: 100%; height: 100%;" controls></video>
            </div>
          </div>
        </div>

        <!-- The Textmodal -->
        <div *ngIf="media.mediatype.id === 8" id="modal{{media.id}}" class="w3-modal w3-animate-opacity">
          <div class="w3-modal-content">
            <div class="w3-container">
              <span (click)="closeModal(media.id)"
              class="w3-button w3-xlarge w3-hover-red w3-display-topright">&times;</span>
              <h3 class="maincol">{{media.name}}</h3>
              <h5 class="maincol">{{media.description}}</h5>
              <div class="textModal" [innerHTML]="media.url">{{media.url}}</div>
            </div>
          </div>
        </div>

        <!-- The PDF Modal -->
        <div *ngIf="media.mediatype.id === 2" id="modal{{media.id}}" class="w3-modal w3-animate-opacity docModal">
          <div class="w3-modal-content">
            <div class="w3-container">
              <span (click)="closeModal(media.id)"
              class="w3-button w3-xlarge w3-hover-red w3-display-topright">&times;</span>
              <h3 class="maincol">{{media.name}}</h3>
              <h5 class="maincol">{{media.description}}</h5>
              <iframe id="iframe{{media.id}}" width="420" height="315" class="document e2e-iframe-trusted-src"
                [src]="videoUrl">
              </iframe>
              <a href="{{media.url}}" target="blank" class="btn btn-sm btn-outline-secondary">extern öffnen</a><br>
            </div>
          </div>
        </div>

        <!-- The Youtube Modal -->
        <div *ngIf="media.mediatype.id === 6" id="modal{{media.id}}" class="w3-modal w3-animate-opacity">
          <div class="w3-modal-content">
            <div class="w3-container">
              <span (click)="closeModalYt(media.id)"
              class="w3-button w3-xlarge w3-hover-red w3-display-topright">&times;</span>
              <h3 class="maincol">{{media.name}}</h3>
              <h5 class="maincol">{{media.description}}</h5>
              <br *ngIf="media.name === ''"><br *ngIf="media.name === ''">
              <iframe id="iframe{{media.id}}" width="420" height="315" class="youtube e2e-iframe-trusted-src"
                [src]="videoUrl">
              </iframe>
            </div>
          </div>
        </div>

        <!-- The Vimeo Modal -->
        <div *ngIf="media.mediatype.id === 10" id="modal{{media.id}}" class="w3-modal w3-animate-opacity">
          <div class="w3-modal-content">
            <div class="w3-container">
              <span (click)="closeModalYt(media.id)"
              class="w3-button w3-xlarge w3-hover-red w3-display-topright">&times;</span>
              <h3 class="maincol">{{media.name}}</h3>
              <h5 class="maincol">{{media.description}}</h5>
              <iframe id="iframe{{media.id}}" width="420" height="315" class="youtube e2e-iframe-trusted-src"
                [src]="videoUrl">
              </iframe>
            </div>
          </div>
        </div>

        <!-- Lö Firmeninfo Modal -->
        <div *ngIf="media.mediatype.id === 11" id="modal{{media.id}}" class="w3-modal w3-animate-opacity">
          <div class="w3-modal-content">
            <div class="w3-container firmeninfoCont">
              <span (click)="closeModalYt(media.id)"
              class="w3-button w3-xlarge w3-hover-red w3-display-topright">&times;</span>
              <h3 style="text-align: left;" class="maincol">Firmenprofil</h3>
              <img src="{{media.url}}" class="firmeninfo" />
            </div>
          </div>
        </div>

        
    </div>

    <div *ngFor="let gallery of mediaposition?.media; let i=index">
      <!-- The Gallery -->
      <div id="modal{{gallery.id}}" class="w3-modal w3-animate-opacity" *ngIf="i<1">
        <div class="w3-modal-content">
          <div class="w3-container">
            <span (click)="closeModal(gallery.id)"
            class="w3-button w3-xlarge w3-hover-red w3-display-topright">&times;</span>
            <h3 class="maincol">Galerie</h3>
            <h5 class="maincol">{{gallery.description}}</h5>
            <div class="imgContainer galleryWrapper">
              <div *ngFor="let image of mediaposition?.media">
                  <img *ngIf="image?.mediatype.id === 1" class="mySlides{{gallery.id}} w3-animate-opacity slideImg" src="{{image?.url}}" style="max-width: 100%;" />
              </div>
            
            <button class="w3-button w3-light-grey w3-display-left" (click)="plusDivs(-1)">&#10094;</button>
            <button class="w3-button w3-light-grey w3-display-right" (click)="plusDivs(+1)">&#10095;</button>
          </div>
          </div>
        </div>
      </div>
    </div>
  <!--
    <div class="blur" id="folder{{mediaposition.id}}" *ngIf="isGalleryPos(mediaposition.id) === false">
      <div class="folderClose unselectable" (click)="closeWindow(mediaposition.id)">X</div>
      <h2>{{mediaposition.description}}</h2>
      <div class="previewContainer">
          
        <div *ngFor="let media of mediaposition?.media">
          <div class="previewElement preview" *ngIf="media.mediatype.id === 1" title="{{media.name}}"><img src="{{media.url}}" class="previewImage" /></div>
          <div class="previewElement preview" *ngIf="media.mediatype.id === 2" title="{{media.name}}"><img src="assets/images/document-black.png" class="previewImage" /></div>
          <div class="previewElement preview" *ngIf="media.mediatype.id === 4" data-toggle="modal" data-target=".{{media.id}}" title="{{media.name}}"><img src="assets/images/video.png" class="previewImage" /></div>
          <div class="previewElement preview" *ngIf="media.mediatype.id === 8" title="{{media.name}}"><img src="assets/images/contactcard-black.png" class="previewImage" /></div>
        </div>

        

      </div>
    </div>-->
</div>






<!-- Hinweis Karte -->
<div class="modal fade" id="hinweisKarte" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Willkommen, {{nameDesUsers}}!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h2 id="namensFeld">Hinweise zur Karte</h2>
        <ul>
          <li>Sie können sich auf der Karte bewegen, indem Sie auf eine leere Fläche klicken und gleichzeitig Ihre Maus bewegen.</li>
          <li>Zoomen ist entweder mit dem Mausrad oder mit den Symbolen in der linken oberen Ecke möglich.</li>
          <li>Sie können nur Gebäude einer bestimmten Branche einblenden. Klicken Sie dazu auf "Ansicht verfeinern" in der linken oberen Ecke.</li>
          <li>Auch Suchen ist im Menü "Ansicht verfeinern" möglich.</li>
          <li>Auch in den Partnergebäuden können Sie sich bewegen.</li>
          <li>Um in ein Gebäude zu gelangen müssen Sie nur auf das jeweilige Logo klicken.
          </li>
        </ul>
        <div style="text-align: center;"><img src="assets/images/demomaus.gif" style="max-width: 300px;" /></div>
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal">Verstanden!</button>
      </div>
    </div>
  </div>
</div>
    
  </body>

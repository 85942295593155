<button class="toprightBtn" id="profileButton" data-toggle="modal" data-target="#myProfile" (mouseover)="fadeuserAnzeige()" (mouseleave)="fadeOut()"><img src="assets/images/profile.png" /></button>
<div class="userAnzeige unselectable" data-toggle="modal" data-target="#myProfile" id="userAnzeige"></div>



<div class="benachrichtigungen" id="benachrichtigungen">
    
      
</div>

<!-- Profil -->
<div class="modal fade" id="myProfile" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Mein Profil</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <app-update-user></app-update-user>
        <div class="modal-body">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button class="btn btn-light" (click)="logout()">Ausloggen</button>
            <button class="btn btn-light" (click)="clearLocalStorage()">Speicher leeren</button>
            <a href="https://webeventstudios.com/page-3/" target="blank" type="button" class="btn btn-light">Impressum</a>
            <a href="https://webeventstudios.com/page-4/" target="blank" type="button" class="btn btn-light">Datenschutz</a>
          </div>
        </div>
        
      </div>
    </div>
  </div>



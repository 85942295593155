import { Component, OnInit, AfterViewInit, OnChanges, Output, EventEmitter } from '@angular/core';
import * as Leaflet from 'leaflet';
import { antPath } from 'leaflet-ant-path';
import { CheckboxRequiredValidator } from '@angular/forms';
import { __values } from 'tslib';
import { visitValue } from '@angular/compiler/src/util';
import { MapService } from '../services/map.service';
import { Map } from '../models/map';
import { ActivatedRoute, Router } from '@angular/router';
import { Branches } from '../models/branches';
import { BranchesService } from '../services/branches.service';
import { forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DecodeTokenService} from '../services/decode-token.service';
import { UsersService } from '../services/users.service';
import { TeleporterService } from '../services/teleporter.service';
declare var $;

@Pipe({ name: 'safeUrl'})
export class SafeUrlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, AfterViewInit, OnChanges  {
  firma: any;
  antwort: Map;
  branches: any;
  laenge: any;
  title = 'leafletApps';
  map: Leaflet.Map;
  marks: any[];
  term2;
  filterLocal: string[];
  nameDesUsers: any;
  users:any;
  mArray: any [] = [];
  pArray: any [] = [];
  markersArray: any [] = [];
  activateCount: number = 0;
  interval: any;


  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private mapService: MapService, 
    private branchesService: BranchesService,
    private decodeTokenService: DecodeTokenService,
    private usersService: UsersService
  ){}


  //Legacy, muss erneuert werden.
  einblenden(id): void {
    console.log(id);
  }

  

  //Map erstellen
  generateMap(mapJson, filter){

    //StartupMedia
    try{
      document.getElementById("startupMedia").innerHTML = '<audio id="audio_with_controls" controls autoplay><source src="'+mapJson.startupMedia.url+'" type="audio/mp3"  /></audio>';
      //window.alert(mapJson.startupMedia.url);   
    }catch{}


  
    
    //Bei Partnergebäude keinen Filter anzeigen
    if (mapJson.type == "partner") {
      document.getElementById("filter").style.display = "none";
    }

    //Branchenfilter; Legacy, muss erneuert werden.
    if (filter === 'alle'){
      console.log(this.laenge);
      for (let i = 0; i < this.mArray.length; i++) {
        console.log('alleee');
        try {
          this.mArray[i].addTo(this.map);
          console.log(this.markersArray[i].id);
        } catch (error) {}
      }
    }else{

      for (let index = 0; index < this.mArray.length; index++) {

        try {
          console.log(this.mArray[index]);
          if (this.markersArray[index].id === filter) {
            this.map.removeLayer(this.mArray[index]);
          }
        } catch (error) {}
      }
    }

    if(filter == ''){
      this.laenge = 0;


    //eigenen Marker für Standardgebäude definieren
    var mediaMarker = Leaflet.icon({
      iconUrl: 'assets/images/transparent.png',
      iconSize: [1, 1],
      iconAnchor: [1, 1],
      });

      var defaultMarker = Leaflet.icon({
        iconUrl: 'assets/images/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [13, 10],
        });




    //Unterscheidung zwischen Partner- und Hauptkarte,
    //dementsprechende Zuordnung der Marker in das Array;
    //bei Hauptkarte: Verlinkungen auf Partner
    //bei Partner: Modalöffner (Bootstrap) für Medien
    if (mapJson.type === 'default'){
      for (let i = 0; i < mapJson.mapChilds.length; i++) {
        if (mapJson.mapChilds[i].icon.id === 10) {
          this.markersArray.push({pos: [mapJson.mapChilds[i].positionX, mapJson.mapChilds[i].positionY],
            popup: '<a routerLink="/redirect/'+ mapJson.mapChilds[i].childMap.id +'" (click)="redirect(' + mapJson.mapChilds[i].childMap.id + ')" href="center/'+ mapJson.mapChilds[i].childMap.id +'" id="lpopup' + i + '" data-toggle="popover" data-html="true" title="' + mapJson.mapChilds[i].childMap.name + '" data-content="<p>' + mapJson.mapChilds[i].childMap.description + '</p>">' + mapJson.mapChilds[i].childMap.name + '</a>',
            tooltip: mapJson.mapChilds[i].childMap.name,
            type: mapJson.mapChilds[i].childMap.type,
            id: mapJson.mapChilds[i].childMap.id});
        }else{
          this.markersArray.push({pos: [mapJson.mapChilds[i].positionX, mapJson.mapChilds[i].positionY],
            popup: `<a routerLink="/redirect/`+ mapJson.mapChilds[i].childMap.id +`" (click)="redirect(` + mapJson.mapChilds[i].childMap.id + `)" href="center/`+ mapJson.mapChilds[i].childMap.id +`" routerLinkActive="active" id="lpopup` + i + `" ng-mouseover="lpopoverOpen(` + i + `)" data-toggle="popover" data-html="true" title="` + mapJson.mapChilds[i].childMap.name + `" data-content="<p>` + mapJson.mapChilds[i].childMap.description + `</p>"><img src="` + mapJson.mapChilds[i].icon.url + `" /></a>`,
            tooltip: mapJson.mapChilds[i].childMap.name,
            type: mapJson.mapChilds[i].childMap.type,
            id: mapJson.mapChilds[i].childMap.id,
            show: this.getCompanyActive(mapJson, mapJson.mapChilds[i].childMap.id)});
        }
      }
    }


    var h = mapJson.backgroundHeight;
    var w = mapJson.backgroundWidth;
    this.map = Leaflet.map('map', {crs: Leaflet.CRS.Simple, center: [mapJson.midX, mapJson.midY], minZoom: mapJson.zoomMin, maxZoom: mapJson.zoomMax, zoomSnap: 0.25}).setView([mapJson.midX, mapJson.midY], mapJson.zoomDefault);
    /*var southWest = this.map.unproject([0, h], this.map.getMaxZoom() - 1);
    var northEast = this.map.unproject([w, 0], this.map.getMaxZoom() - 1);
    var bounds = new Leaflet.LatLngBounds(southWest, northEast);*/
    var bounds = [[0,0], [h,w]]; 
    var image = Leaflet.imageOverlay(mapJson.background.url, bounds).addTo(this.map);
    this.map.setMaxBounds(bounds);



  //Marker zur Karte hinzufügen
  this.markersArray.forEach((obj) => {
    if (obj.type === 'default') {
      this.mArray[obj.id] = Leaflet.marker(obj.pos, {icon: mediaMarker});
      this.pArray[obj.id] = new Leaflet.Popup({
        autoClose: false,
        closeOnClick: false,
        autoPan: false,
        className: 'popp'
      })
        .setContent(obj.popup)
        .setLatLng(obj.pos);
      this.mArray[obj.id].bindPopup(this.pArray[obj.id]);
      this.mArray[obj.id].bindTooltip(obj.tooltip);
    } else if (obj.type === 'partner') {
      if (obj.show === 'true') {
        this.mArray[obj.id] = Leaflet.marker(obj.pos, {icon: mediaMarker});
        this.pArray[obj.id] = new Leaflet.Popup({
          autoClose: false,
          closeOnClick: false,
          autoPan: false,
        })
          .setContent(obj.popup)
          .setLatLng(obj.pos);
        this.mArray[obj.id].bindPopup(this.pArray[obj.id]);
        this.mArray[obj.id].bindTooltip(obj.tooltip);
      }


    } 


  }
  );


  for (let index = 0; index < this.mArray.length; index++) {
    try{
      this.mArray[index].addTo(this.map);

      this.mArray[index].openPopup();
      // m[index].on('click', this.fensterLaden(index));
    } catch {}
    this.laenge = this.laenge + 1;
  }

  this.map.on('click', (e) => {
    console.log("Lat, Lon : " + e.latlng.lat + ", " + e.latlng.lng)
});

// Zoom auslesen; WORKING!
  this.map.on('zoomend', () => {
    console.log(this.map.getZoom());
    if (this.map.getZoom() > -1.5) {
      console.log('Popups auf');
      for (let index = 1; index <= 100; index++) {
        try {
          this.mouseoverEntfernen(mapJson.mapChilds.length);
          if(this.mArray[index]._popup.options.className !== 'popp'){
            this.mArray[index].openPopup();
            
          }
          this.mouseoverTest(mapJson.mapChilds.length);
        } catch (error) {}
      }
    } else {
      console.log('Popups zu');
      for (let index = 1; index <= 500; index++) {
        try {
          this.mouseoverEntfernen(mapJson.mapChilds.length);
          if(this.mArray[index]._popup.options.className !== 'popp'){
            this.mArray[index].closePopup();
            //console.log(this.mArray[index]);
            this.mouseoverTest(mapJson.mapChilds.length);
          }
        } catch (error) {}
      }
      try {
        this.mouseoverEntfernen(mapJson.mapChilds.length);
          if(this.mArray[6]._popup.options.className !== 'popp'){
            this.mArray[6].openPopup();}
            if(this.mArray[9]._popup.options.className !== 'popp'){
              this.mArray[9].openPopup();}
              this.mouseoverTest(mapJson.mapChilds.length);
      } catch (error) {
        
      }
    }
  });

this.mouseoverTest(mapJson.mapChilds.length);
this.navigierenEvents(mapJson.mapChilds.length, mapJson);
//Zoom auslesen und Popups ein/ausblenden
//this.map.on('zoom', console.log("Zoom"));

}
  }

  

  navigate(id){
    console.log('navigate '+id);
    this.router.navigate(['redirect/'+id]);
  }

fensterLaden(id){
  window.location.href = "map/" + id;
}

  //Überprüfung anhand der Branche, ob eine Company angezeigt werden soll oder nicht.
  getCompanyActive(mapJson, eingabeId){
    for (let index = 0; index < mapJson.mapChilds.length; index++) {
      try {
        if (mapJson.mapChilds[index].childMap.id == eingabeId) {
          var companyId = index;
          //console.log(companyId);
        }
      } catch (error) {} 
    }
       for (let i = 0; i < mapJson.mapChilds[companyId].childMap.companies.length; i++) {
        for (let k = 0; k <  mapJson.mapChilds[companyId].childMap.companies[i].branches.length; k++) {
          let aktiv = localStorage.getItem(mapJson.mapChilds[companyId].childMap.companies[i].branches[k].id);
          //console.log(aktiv);
          return aktiv;
        }   
      } 
  }


  //Legacy, nur zu Testzwecken gebaut
  getCompanyBranches(mapJson){
    for (let index = 0; index < mapJson.mapChilds.length; index++) {
      for (let i = 0; i < mapJson.mapChilds[index].childMap.companies.length; i++) {
        for (let k = 0; k <  mapJson.mapChilds[index].childMap.companies[i].branches.length; k++) {
          let und = mapJson.mapChilds[index].childMap.companies[i].branches[k].name.includes('Handwerk');
          console.log(und);
          let aktiv = localStorage.getItem(mapJson.mapChilds[index].childMap.companies[i].branches[k].id);
          console.log(mapJson.mapChilds[index].childMap.companies[i].description +': '+ mapJson.mapChilds[index].childMap.companies[i].branches[k].name + ': ' + aktiv);
        }   
      } 
    } 
  }

  //Checkboxes
  activateCheckboxes(branches){
    
    for (let l = 0; l < branches.length; l++) {
      try{
      if (localStorage.getItem(branches[l].id) == 'true') {
        try {
          var element3 = <HTMLInputElement> document.getElementById(branches[l].id.toString());
          element3.checked = true;
        } catch (error) {}
        
      }else{
        try {
          var element3 = <HTMLInputElement> document.getElementById(branches[l].id.toString());
          element3.checked = false;
        } catch (error) {}
      }
    }catch{}} 
    console.log('activated');
    this.activateCount = this.activateCount + 1;
    if(this.activateCount > 3){
      clearInterval(this.interval);
      console.log('aus');
    }
  }


  //Datenbankanbindung
  getBranches(){
    this.branchesService.getBranches()
    .subscribe(branches => this.branches = branches);
    this.branchesService.getBranches()
    .subscribe(branches2 => this.activateCheckboxes(branches2));
  }
  ngAfterViewInit() {
    this.branchesService.getBranches()
    .subscribe(branches2 => this.activateCheckboxes(branches2));
 }

 ngOnChanges(){
  try {
    localStorage.removeItem('isWindowOpen');
  } catch (error) {}
  const id = this.route.snapshot.paramMap.get('id');
  localStorage.setItem('mapId', id);
//  this.updatePosition(id);   
  this.interval = setInterval(() => this.activateCheckboxes(this.branches), 2000);
  // evtl Weiterleiten auf Standardgebäude
  if (id == '4') {
    this.router.navigate(['welcomecenter']);
  }else if (id == '5') {
    window.location.href = "cityhall";
  }else if (id == '8') {
    this.router.navigate(['mediacenter']);
  }
  //Datenbankverbindung
  this.mapService.getMap(id)
    .subscribe(firma => this.firma = firma);
  this.mapService.getMap(id)
    .subscribe(antwort => this.generateMap(antwort, ''));
   
  this.getBranches();
  //this.decodeTokenService.decodeUserId(); //UserID erhalten
  this.hinweisOeffnen();
  this.nameDesUsers = this.decodeTokenService.decodeFirstName() + ' ' + this.decodeTokenService.decodeLastName();
 }

  ngOnInit() {
    try {
      localStorage.removeItem('isWindowOpen');
    } catch (error) {}
    const id = this.route.snapshot.paramMap.get('id');
    localStorage.setItem('mapId', id);
    this.updatePosition(id);   
    this.interval = setInterval(() => this.activateCheckboxes(this.branches), 2000);
    // evtl Weiterleiten auf Standardgebäude
    if (id == '4') {
      this.router.navigate(['welcomecenter']);
    }else if (id == '5') {
      window.location.href = "cityhall";
    }else if (id == '8') {
      this.router.navigate(['mediacenter']);
    }
    //Datenbankverbindung
    this.mapService.getMap(id)
      .subscribe((firma) => {
        this.firma = firma;
      });
    this.mapService.getMap(id)
      .subscribe(antwort => this.generateMap(antwort, ''));
     
    this.getBranches();
    //this.decodeTokenService.decodeUserId(); //UserID erhalten
    this.hinweisOeffnen();
    this.nameDesUsers = this.decodeTokenService.decodeFirstName() + ' ' + this.decodeTokenService.decodeLastName();    
}



updatePosition(posId){
  //this.usersService.update(this.decodeTokenService.decodeUserId(), {"currentLocation": posId.toString()});
}

  //Legacy: Branchenfilter; muss überarbeitet werden
  filterAnwenden(name): void{
    if (name == 'alle') {
      //this.generateMap('', 'alle');
      for (let index = 0; index < 100; index++) {
        try {
          var element2 = <HTMLInputElement> document.getElementById(index.toString());
          element2.checked = true;
          localStorage.setItem(index.toString(), element2.checked.toString());
        } catch (error) {}
        
      }  
      this.refresh();
    }else{
      //this.generateMap('', name);
    var element = <HTMLInputElement> document.getElementById(name);
    var isChecked = element.checked;
    localStorage.setItem(name.toString(), element.checked.toString());
    } 
    //console.log(name + '. Checkbox: ' + isChecked)
  }

  //Seite neu laden
  refresh(){
    window.location.reload();
  }

  //Suchergebnisse im Filter anzeigen/ausblenden
  ergebnisseAnzeigen(term2){
    if(term2 == ''){
      document.getElementById("suchErgebnisse").style.display = "none";
    }else{
      document.getElementById("suchErgebnisse").style.display = "block";
    }
  }

  

  openNav(){
    $('#filtermap').dropdown('toggle');
    $('#filtermap').tooltip('hide');
  }


  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }


    
  hinweisOeffnen(){
    if (localStorage.getItem('hinweisKarte')) { 
    }else{
      $('#hinweisKarte').modal('show');
      localStorage.setItem('hinweisKarte', 'true');
    }
    
  }

  lpopoverOpen(id){
    console.log(id);
    $('#lpopup'+id).popover('show');
  }

  navigierenEvents(length, mapJson){
    for (let index = 0; index < length; index++) {
      try {
        let test = document.getElementById("lpopup"+index);
        test.addEventListener('click', (event)=>{
          this.navigate(mapJson.mapChilds[index].childMap.id);
          $('#lpopup'+index).popover('hide');
        })
      } catch (error) {}  
    }  
  }

  mouseoverEntfernen(length){
    for (let index = 0; index < length; index++) {
      try {
        let test = document.getElementById("lpopup"+index);
        test.removeEventListener("mouseenter", function(event){
          $('#lpopup'+index).popover('show');
        });
        test.removeEventListener("mouseleave", function(event){
          $('#lpopup'+index).popover('hide');
        });
      } catch (error) {}  
    }  
  }

  mouseoverTest(length){
    for (let index = 0; index < length; index++) {
      try {
        let test = document.getElementById("lpopup"+index);
        test.removeEventListener("mouseenter", function(event){
          $('#lpopup'+index).popover('show');
        });
        test.removeEventListener("mouseleave", function(event){
          $('#lpopup'+index).popover('hide');
        });
        test.addEventListener("mouseenter", function(event){
          $('#lpopup'+index).popover('show');
        })
        test.addEventListener("mouseleave", function(event){
          $('#lpopup'+index).popover('hide');
        });
      } catch (error) {}  
    }  
  }


  
    detectmob() {
      if(window.innerWidth <= 752) {
        $('.leaflet-popup-content-wrapper a').popover("disable");
      } else {
        //$('a').popover("enable");
        return false;
      }
    }
  

}
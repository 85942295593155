<h3>Abstimmung erstellen</h3>

<form #createVotingForm="ngForm">
    <div class="form-group">
        <label for="inputName">Bezeichnung</label>
        <input type="text" class="form-control" id="inputName" [(ngModel)]="voting.name" name="name" placeholder="Bezeichnung" required>
    </div>
    
    <div class="form-group">
        <label for="inputDescription">Frage</label>
        <input type="text" class="form-control" id="inputDescription" [(ngModel)]="voting.description" name="description" placeholder="Frage" required>
    </div>

    <!--
    <div class="form-group">
        <label for="inputStartdate">Startzeit</label>
        <input type="datetime-local" class="form-control" id="inputStartdate" [(ngModel)]="voting.startdate" name="startdate">
    </div>

    <div class="form-group">
        <label for="inputExpiredate">Endzeit</label>
        <input type="datetime-local" class="form-control" id="inputExpiredate" [(ngModel)]="voting.expiredate" name="expiredate">
    </div>
    -->

    <button type="submit" class="btn btn-primary" [disabled]="!createVotingForm.form.valid" (click)="save()">Speichern</button>
</form>
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Videochatroom } from '../../_models/videochatroom';
import { VideochatroomService } from '../../_services/videochatroom.service';

@Component({
  selector: 'app-videochat-create',
  templateUrl: './videochat-create.component.html',
  styleUrls: ['./videochat-create.component.css']
})
export class VideochatCreateComponent implements OnInit {

  videochat: Videochatroom = new Videochatroom();

  @Output() selectVideochatEvent = new EventEmitter<Videochatroom>();

  constructor(private videochatroomService: VideochatroomService) { }

  ngOnInit(): void {
  }

  addVideochatroom(): void {
    this.videochatroomService.createVideochatroom(this.videochat).subscribe(videochatroom => {
      this.selectVideochatEvent.emit(videochatroom);
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { UsersService } from '../services/users.service';
import { DecodeTokenService } from '../services/decode-token.service';

@Component({
  selector: 'app-technikcheck',
  templateUrl: './technikcheck.component.html',
  styleUrls: ['./technikcheck.component.css']
})
export class TechnikcheckComponent implements OnInit {

  constructor(
    private usersService: UsersService,
    private decodeTokenService: DecodeTokenService  
  ) { }

  ngOnInit(): void {
    navigator.getUserMedia = navigator.getUserMedia;

if (navigator.getUserMedia) {
   navigator.getUserMedia({ audio: true, video: { width: 1280, height: 720 } },
      function(stream) {
         console.log("Accessed the Microphone");
         document.getElementById("mikrofon").innerHTML = "Ihr Mikrofon ist aktiviert!";
      },
      function(err) {
         console.log("The following error occured: " + err.name);
         document.getElementById("mikrofon").innerHTML = "Zugriff auf Ihr Mikrofon verweigert. Bitte erlauben Sie den Zugriff auf Ihr Mikrofon.";
      }
    );
} else {
   console.log("getUserMedia not supported");
}
  }

  speakerTest(){
    this.testton('assets/sounds/confirm.wav');
  }

  testton(url):void{
    new Audio(url).play();
  }

  zurueck(){
    var userData;
    var userId = this.decodeTokenService.decodeUserId();
    this.usersService.getUser(userId)
    .subscribe(data => this.navigieren(data));
  }

  navigieren(id){
    window.location.href = "map/"+id.currentLocation.id
  }
}

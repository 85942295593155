import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Map } from '../../_models/map';
import { User } from '../../_models/user';
import { MediaService } from '../../_services/media.service';
import { UserService } from '../../_services/user.service';
declare var $;

@Component({
  selector: 'app-map-detail-edit',
  templateUrl: './map-detail-edit.component.html',
  styleUrls: ['./map-detail-edit.component.css']
})
export class MapDetailEditComponent implements OnInit {

  @Input() map: Map;
  @Output() saveMap = new EventEmitter<Map>();
  @Output() resetMap = new EventEmitter<boolean>();

  users: User[];

  constructor(
    private mediaService: MediaService,
    private userService: UserService
    ) { }

  ngOnInit(): void {
    this.getUsers();
  }

  getUsers(): void {
    this.userService.getUsers().subscribe(users =>
      this.users = users
    );
  }

  openUploadBackgroundModal(): void {
    $('#uploadBackgroundModal').modal('show');
  }

  openUploadLogoModal(): void {
    $('#uploadLogoModal').modal('show');
  }

  openUploadStartupMediaModal(): void {
    $('#uploadStartupMediaModal').modal('show');
  }

  resetBackground(): void {
    this.mediaService.getMedia(21).subscribe(background =>
      this.map.background = background
    );
  }

  addBackground(newBackground): void {
    this.map.background = newBackground;
    $('#uploadBackgroundModal').modal('hide');
  }

  addLogo(newLogo): void {
    this.map.icon = newLogo;
    $('#uploadLogoModal').modal('hide');
  }

  addStartupMedia(newStartupMedia): void {
    this.map.startupMedia = newStartupMedia;
    $('#uploadStartupMediaModal').modal('hide');
  }

  removeBackground(): void {
    this.map.background = null;
  }

  removeLogo(): void {
    this.map.icon = null;
  }

  removeStartupMedia(): void {
    this.map.startupMedia = null;
  }

  save(): void {
    this.saveMap.emit(this.map);
  }

  reset(): void {
    console.log('reset');
    this.resetMap.emit(true);
  }

}

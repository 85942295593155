<div class="w3-bar w3-light-grey">
    <button id="NameTrigger" class="w3-bar-item w3-button trigger w3-dark-grey" (click)="openCity('Name')">Benutzerkonto</button>
    <button id="VolumeTrigger" class="w3-bar-item trigger w3-button" (click)="openCity('Volume')">Lautstärkemixer</button>
  </div>

<div id="Name" class="city">
    <div class="loginForm">
        <h4>Benutzerkonto bearbeiten</h4>
        <form (submit)="onSubmit()" id="formular">
            <label for="firstName" id="firstNamel">Vorname</label>
            <input type="text" class="form-control" title="Vorname" id="firstName" placeholder="Vorname" autocomplete="new-password" value="{{userData?.firstName}}" #firstName (focusout)="updateFn(firstName.value)" required>
            <br>
            <label for="lastName" id="lastNamel">Nachname</label>
            <input type="text" class="form-control" title="Nachname" id="lastName" placeholder="Nachname" autocomplete="new-password" value="{{userData?.lastName}}" #lastName (focusout)="updateLn(lastName.value)" required>
            
            <div class="alert alert-danger" role="alert" id="alert" style="display: none;"></div>
            <br>
            <button type="submit" class="btn btn-success">Speichern</button>
        </form>
        <div class="alert alert-success" role="success" id="success" style="display: none;">Ihre Daten wurden erfolgreich geändert!</div>
    </div>
  </div>
  
  <div id="Volume" class="city" style="display:none">
    <app-volume-control></app-volume-control>
  </div>

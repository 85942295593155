import { Component, OnInit } from '@angular/core';
import { DecodeTokenService } from '../services/decode-token.service';
import { UsersService } from '../services/users.service';

@Component({
  selector: 'app-videochat-nav',
  templateUrl: './videochat-nav.component.html',
  styleUrls: ['./videochat-nav.component.css']
})
export class VideochatNavComponent implements OnInit {

  constructor(private usersService: UsersService, private decodeTokenService: DecodeTokenService) { }

  ngOnInit(): void {
  }


  back(){
    var userData;
    var userId = this.decodeTokenService.decodeUserId();
    this.usersService.getUser(userId)
    .subscribe(data => this.navigieren(data));
  }

  navigieren(id){
    window.location.href = "map/"+id.currentLocation.id
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../_models/user';
import {environment} from '../../../environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  url = `${environment.apiUrl}/users`;

  constructor(
    private http: HttpClient
  ) { }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.url)
      .pipe(
        tap(_ => console.log('fetched users')),
        catchError(this.handleError<User[]>('getUsers, []'))
      );
  }

  getUser(id: number): Observable<User>{
    return this.http.get<User>(`${this.url}/${id}`)
      .pipe(
        tap(_ => console.log(`fetched user id=${id}`)),
        catchError(this.handleError<User>('getUser, []'))
      );
  }

  updateUser(user: User): Observable<any>{
    return this.http.put(`${this.url}/${user.id}`, user)
      .pipe(
        tap(_ => console.log('updated user id=${user.id}')),
        catchError(this.handleError<any>('updateUser'))
      );
  }

  createUser(user: User): Observable<User>{
    return this.http.post(this.url, user)
      .pipe(
        tap(_ => console.log('created user id=${user.id}')),
        catchError(this.handleError<any>('createUser'))
      );
  }

  deleteUser(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`)
    .pipe(
      tap(_ => console.log('deleted user id=${user.id}')),
      catchError(this.handleError<any>('deleteUser'))
    )
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

<h3>Benutzer erstellen</h3>

<div class="form-group mb-3">    
    <label for="file">CSV auswählen</label>
    <input type="file" class="form-control" id="file" (change)="handleFileInput($event.target.files)">
</div>

<div *ngIf="users">
    <table class="table table-hover">
        <thead>
            <tr>
                <th scope="col">Vorname</th>
                <th scope="col">Nachname</th>
                <th scope="col">E-Mail</th>
                <th scope="col">Firma</th>
                <th scope="col">Rolle</th>
            </tr>
        </thead>
    
        <tbody>
            <tr *ngFor="let user of users" class="mouse-pointer" routerLink="/admin/user/edit/{{user.id}}">
                <td>{{user.firstName}}</td>
                <td>{{user.lastName}}</td>
                <td>{{user.eMail}}</td>
                <td>{{user.company}}</td>
                <td>{{user.role}}</td>
            </tr>
        </tbody>
    </table>
    
    <button type="button" class="btn btn-primary" (click)="createUsers(users)">Nutzer erstellen</button>
</div>

import { Component, OnInit } from '@angular/core';
import { VotingService } from '../../_services/voting.service';
import { Voting } from '../../_models/voting';

@Component({
  selector: 'app-voting-list',
  templateUrl: './voting-list.component.html',
  styleUrls: ['./voting-list.component.css']
})
export class VotingListComponent implements OnInit {

  votings: Voting[];

  constructor(
    private votingService: VotingService
  ) { }

  ngOnInit(): void {
    this.getVotings();
  }

  getVotings(): void {
    this.votingService.getVotings().subscribe(votings =>
      this.votings = votings
    );
  }

}

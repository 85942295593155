import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { Videochatroom } from '../_models/videochatroom';

@Injectable({
  providedIn: 'root'
})
export class VideochatroomService {

  url = `${environment.apiUrl}/videochatrooms`;

  constructor(
    private http: HttpClient
  ) { }

  createVideochatroom(videochatroom: Videochatroom): Observable<Videochatroom>{
    return this.http.post(this.url, videochatroom)
      .pipe(
        tap(_ => console.log('created videochatroom id=${media.id}')),
        catchError(this.handleError<any>('createMedia'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

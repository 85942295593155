<h3>Abstimmung</h3>

<button class="btn btn-primary" routerLink="/admin/voting/edit/{{voting.id}}">Bearbeiten</button>

<div class="card my-3">
    <h4 class="card-header text-light bg-dark">Vorschau</h4>

    <div class="card-body">
        <h5 class="card-title">{{voting.name}}</h5>
        <p class="card-text">{{voting.description}}</p>

        <div class="list-group mt-1">
            <div *ngFor="let option of voting.options" class="list-group-item">
                <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{option.content}}</h5>
                    <span *ngIf="showResults" class="badge badge-success">&nbsp;{{option.results.length}}&nbsp;</span>
                </div>
                <p class="mb-1">{{option.description}}</p>
    
            </div>
        </div>
    </div>
</div>

<div *ngIf="!isVotingStarted" class="card">
    <h4 class="card-header text-white bg-success">Abstimmung starten</h4>

    <div class="card-body row">
        <div class="col">
            <label for="selectMap">In welchem Center soll die Abstimmung erscheinen?</label>
        </div>
        <div class="col form-group">       
            <select class="form-control" id="selectMap" [(ngModel)]="selectedMap.id" name="map">
                <option *ngFor="let map of maps" [ngValue]="map.id">{{map.name}}</option>
            </select>
        </div>
        <div class="col">
            <button type="button" class="btn btn-success" (click)="startVoting()">Starten</button>
        </div>
    </div>
</div>

<div *ngIf="isVotingStarted && !isVotingEnded" class="card">
    <h4 class="card-header text-white bg-danger">Abstimmung beenden</h4>

    <div class="card-body row">
        <div class="col">
            <label for="selectMap">In welchem Center soll die Abstimmung beendet werden?</label>
        </div>
        <div class="col form-group">       
            <select class="form-control" id="selectMap" [(ngModel)]="selectedMap.id" name="map">
                <option *ngFor="let map of maps" [ngValue]="map.id">{{map.name}}</option>
            </select>
        </div>
        <div class="col">
            <button type="button" class="btn btn-danger" (click)="endVoting()">Beenden</button>
        </div>
    </div>
</div>


import { Component, EventEmitter, OnChanges, OnInit, Output } from '@angular/core';
import { MapService } from '../services/map.service';

@Component({
  selector: 'app-start-company-chat',
  templateUrl: './start-company-chat.component.html',
  styleUrls: ['./start-company-chat.component.css']
})
export class StartCompanyChatComponent implements OnInit, OnChanges {
  @Output() createAnsprech = new EventEmitter<any>();
  constructor(
    private mapSercvice: MapService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(){
  }

  startChat(){
    this.mapSercvice.getMap(parseInt(localStorage.getItem('mapId'))).subscribe((data)=>{
      if(data.chatContact === null){
        window.alert('kein Ansprechpartner hinterlegt!');
      }else{
        const id = data.chatContact.id;
        console.log(id);
        this.createAnsprech.emit(id);
        
      }
      
    })
    
  }

}

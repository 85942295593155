<div>
    <div class="form-group mb-3">
        <label for="inputUrl">Vimeo-Adresse</label>
        <input type="text" class="form-control" id="inputUrl" [(ngModel)]="media.url" name="url" placeholder="z.B: https://www.vimeo.com/123456789" required>
    </div>
    <p class="small">Bitte benutzen Sie den Link aus der Adresszeile (Beispiel: https://www.vimeo.com/123456789)</p>
    <div class="form-group mb-3">
        <label for="inputName">Bezeichnung</label>
        <input type="text" class="form-control" id="inputName" [(ngModel)]="media.name" name="name" placeholder="Geben Sie hier die Bezeichnung des Mediums an" required>
    </div>
    <div class="form-group mb-3">
        <label for="inputDescription">Beschreibung</label>
        <textarea class="form-control" id="inputDescription" [(ngModel)]="media.description" name="description" placeholder="Hier können Sie optional eine Beschreibung hinzufügen" rows="4" maxlength="255"></textarea>
    </div>
</div>

<button type="button" class="btn btn-primary" (click)="saveMedia()">Hinzufügen</button>

import { User } from './user';
import { Chatmessage } from './chatmessage';

export class Chatroom {
    id: number;
    name: string;
    description: string;
    users: User[];
    messages: Chatmessage[];
    private: boolean;
}

<button class="toprightBtn" id="bellButton" data-toggle="modal" data-target="#notifications" (click)="resetNot()" (mouseover)="fadebenAnzeige()" (mouseleave)="fadeOut()"><span class="badge badge-pill badge-light" (click)="resetNot()" id="benAnzahl" >{{benAnzahl}}</span><img src="assets/images/glocke-weiss.svg" /></button>
<div class="userAnzeige unselectable" id="benAnzeige" data-toggle="modal" data-target="#notifications" (click)="resetNot()">{{benAnzahl}} neue Nachrichten</div>
<div id="openChat" class="unselectable" (mouseover)="showChat()">
    CHAT<br><<<span *ngIf="benAnzahl > 0" class="badge badge-pill badge-light" (click)="resetNot()" id="benAnzahl" >!</span>
</div>

<div id="chatfenster">
    <nav class="navbar sticky-top navbar-light bg-light justify-content-between">
        <a class="navbar-brand">  CHAT</a>

        <div class="form-inline">
          <div class="btn-group" role="group" aria-label="Basic example">
          <a id="privateBtn" class="btn btn-outline-secondary" (click)="toggle('private')">Privat <span *ngIf="privBen === true" class="badge badge-pill badge-light" id="benAnzahl" >!</span><span class="sr-only"></span></a>
          <a id="ansprechBtn" class="btn btn-outline-secondary" (click)="toggle('ansprechpartner')">Ansprechpartner <span class="sr-only"></span></a>
        </div>
            <button id="hideChat" (click)="hideChat()"><img src="assets/images/close.png" style="max-width: 3em; max-height: 3em;" data-toggle="tooltip" data-placement="top" data-html="true" title="Schließen" /></button>
            
            
        </div>
      </nav>
      <div id="privateChatDiv" style="display: none;"><app-private-chat [events]="eventsSubject" (privateChatEmit)="getMessagePrivate($event)"></app-private-chat></div>
      <div id="companyChatDiv" style="display: none;"><app-start-company-chat (createAnsprech)="createAnsprech($event)"></app-start-company-chat></div>
            

</div>

<div id="ausgrauen" (click)="hideChat()"></div>
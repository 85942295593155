import { Component, OnInit } from '@angular/core';
import './external_api.js';
import { ActivatedRoute } from '@angular/router';
import { DecodeTokenService } from '../services/decode-token.service.js';
declare var JitsiMeetExternalAPI: any;
@Component({
  selector: 'app-videochat',
  templateUrl: './videochat.component.html',
  styleUrls: ['./videochat.component.css']
})
export class VideochatComponent {
  title = 'app';
  	domain:string = "meet.webeventstudios.com";
  	options: any;
  	api: any;

	constructor(private route: ActivatedRoute, private decodeTokenService: DecodeTokenService)
	{
	}

	ngAfterViewInit(): void {
		const name= this.decodeTokenService.decodeFirstName() + ' '+ this.decodeTokenService.decodeLastName(); 
		const email= this.decodeTokenService.decodeEmail();
		const id = this.route.snapshot.paramMap.get('id');
		this.options = {
			roomName: "webeventstudiosPartner"+id,
      parentNode: document.querySelector('#meet'),
      userInfo:{email: email, displayName: name}
		}

		this.api = new JitsiMeetExternalAPI(this.domain, this.options);
    }
}

import { Component, OnInit } from '@angular/core';
import { Chatmessage } from '../../_models/chatmessage';
import { User } from '../../_models/user';
import { AuthService } from '../../../auth/auth.service';
import { ChatroomService } from '../../_services/chatroom.service';
import { ChatService } from 'src/app/services/chat.service';
import { Chatroom } from '../../_models/chatroom';

@Component({
  selector: 'app-chat-view-cumulated',
  templateUrl: './chat-view-cumulated.component.html',
  styleUrls: ['./chat-view-cumulated.component.css']
})
export class ChatViewCumulatedComponent implements OnInit {

  userId: number;
  user: User;
  chatmessages: Chatmessage[] = new Array();

  selectedModeratorChatroom: number;

  constructor(
    private chatService: ChatService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.currentUserValue;
    this.getChatmessages();
  }

  getChatmessages(): void {
    this.chatService.receiveChatmessage().subscribe((chatmessage: Chatmessage) => {
      this.chatmessages.push(chatmessage);
      localStorage.setItem('nachricht'+chatmessage.chatroom.id, 'ungelesen');
    });
  }

  sendToModerator(index: number): void {
    this.chatmessages[index].sent = true;

    const id = 181; // this.selectedModeratorChatroom;
    const chatroom = {id} as Chatroom;

    const chatmessage = this.chatmessages[index];
    chatmessage.chatroom = chatroom;
    chatmessage.content += ` (${chatmessage.sender.firstName} ${chatmessage.sender.lastName})`;

    this.chatService.sendChatmessage(chatmessage);
  }
}

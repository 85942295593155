import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MapComponent } from './map/map.component';
import { WelcomecenterComponent } from './welcomecenter/welcomecenter.component';
import { VideochatComponent } from './videochat/videochat.component';
import { CityhallComponent } from './cityhall/cityhall.component';
import { MediacenterComponent } from './mediacenter/mediacenter.component';
import { LoginComponent } from './auth/login/login.component';
import {AuthGuardGuard} from './auth-guard.guard';
import { Map2Component } from './map2/map2.component';
import { TechnikcheckComponent } from './technikcheck/technikcheck.component';
import { InteressenComponent } from './interessen/interessen.component';
import { RegistrierungComponent } from './registrierung/registrierung.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { UserueberwachungComponent } from './userueberwachung/userueberwachung.component';
import { InteractionsComponent } from './interactions/interactions.component';
import { ToastsComponent } from './toasts/toasts.component';
import { PrivateChatComponent } from './private-chat/private-chat.component';
import { ChatComponent } from './chat/chat.component';
import {CityhallVotingComponent} from './cityhall-voting/cityhall-voting.component';
import { LivestreamComponent } from './livestream/livestream.component';
import { MapRedirectComponent } from './map-redirect/map-redirect.component';
import { TestComponent } from './test/test.component';

// ADMIN COMPONENTS
import { AdminComponent } from './admin/admin.component';
import { MapListComponent } from './admin/map/map-list/map-list.component';
import { MapDetailComponent } from './admin/map/map-detail/map-detail.component';
import { MapPreviewComponent } from './admin/map/map-preview/map-preview.component';
import { Cityhall2Component } from './cityhall2/cityhall2.component';
import { AgendaListComponent } from './admin/agenda/agenda-list/agenda-list.component';
import { AgendaEditComponent } from './admin/agenda/agenda-edit/agenda-edit.component';
import { AgendaCreateComponent } from './admin/agenda/agenda-create/agenda-create.component';
import { AgendaCreateBatchComponent } from './admin/agenda/agenda-create-batch/agenda-create-batch.component';
import { MediaListComponent } from './admin/media/media-list/media-list.component';
import { MediaEditComponent } from './admin/media/media-edit/media-edit.component';
import { MediaCreateComponent } from './admin/media/media-create/media-create.component';
import { UserListComponent } from './admin/user/user-list/user-list.component';
import { UserCreateComponent } from './admin/user/user-create/user-create.component';
import { UserCreateBatchComponent } from './admin/user/user-create-batch/user-create-batch.component';
import { UserEditComponent } from './admin/user/user-edit/user-edit.component';
import { VotingListComponent } from './admin/voting/voting-list/voting-list.component';
import { VotingEditComponent } from './admin/voting/voting-edit/voting-edit.component';
import { VotingViewComponent } from './admin/voting/voting-view/voting-view.component';
import { VotingCreateComponent } from './admin/voting/voting-create/voting-create.component';
import { ChatListComponent } from './admin/chat/chat-list/chat-list.component';
import { ChatViewComponent } from './admin/chat/chat-view/chat-view.component';
import { ChatCreateComponent } from './admin/chat/chat-create/chat-create.component';
import { ChatCreateBatchComponent } from './admin/chat/chat-create-batch/chat-create-batch.component';
import { ChatEditComponent } from './admin/chat/chat-edit/chat-edit.component';
import { NutzungsbedingungenComponent } from './nutzungsbedingungen/nutzungsbedingungen.component';
import { ChatViewCumulatedComponent } from './admin/chat/chat-view-cumulated/chat-view-cumulated.component';
import { ChatViewModComponent } from './admin/chat/chat-view-mod/chat-view-mod.component';
import { VideochatRawComponent } from './videochat-raw/videochat-raw.component';
import { CenterComponent } from './center/center.component';
import { MapCreateComponent } from './admin/map/map-create/map-create.component';


const routes: Routes = [
  { path: 'map/:id', component: MapComponent },
  { path: 'center/:id', component: CenterComponent },
  // { path: 'map2/:id', component: Map2Component, canActivate: [AuthGuardGuard] },
  { path: 'redirect/:id', component: MapRedirectComponent },
  { path: 'videochat/:id', component: VideochatComponent },
  { path: 'welcomecenter', component: WelcomecenterComponent },
  { path: 'videochat', component: VideochatComponent },
  { path: 'cityhall', component: CityhallComponent },
  { path: 'cityhall2', component: Cityhall2Component },
  { path: 'mediacenter', component: MediacenterComponent },
  { path: '', component: WelcomecenterComponent },
  { path: 'login', component: LoginComponent },
  { path: 'nutzungsbedingungen', component: NutzungsbedingungenComponent },
  { path: 'registrieren', component: RegistrierungComponent },
  { path: 'updateUser', component: UpdateUserComponent },
  { path: 'interactions', component: InteractionsComponent },
  { path: 'technik', component: TechnikcheckComponent },
  { path: 'userueberwachung', component: UserueberwachungComponent, canActivate: [AuthGuardGuard] },
  { path: 'interessen', component: InteressenComponent },
  { path: 'toasts', component: ToastsComponent, canActivate: [AuthGuardGuard] },
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuardGuard] },
  { path: 'ch-vote', component: CityhallVotingComponent, canActivate: [AuthGuardGuard] },
  { path: 'livestream', component: LivestreamComponent, canActivate: [AuthGuardGuard] },
  { path: 'upload-image', component: UploadImageComponent, canActivate: [AuthGuardGuard] },
  { path: 'test', component: TestComponent, canActivate: [AuthGuardGuard] },
  { path: 'vcraw', component: VideochatRawComponent, canActivate: [AuthGuardGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }, children: [
    { path: 'center', component: MapListComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'center/view/:id', component: MapPreviewComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'center/create', component: MapCreateComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'center/edit/:id', component: MapDetailComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'agenda', component: AgendaListComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'agenda/edit/:id', component: AgendaEditComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'agenda/create', component: AgendaCreateComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'agenda/createbatch', component: AgendaCreateBatchComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'agenda/create', component: AgendaCreateComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'media', component: MediaListComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'media/create', component: MediaCreateComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'media/edit/:id', component: MediaEditComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'user', component: UserListComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'user/edit/:id', component: UserEditComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'user/create', component: UserCreateComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'user/createbatch', component: UserCreateBatchComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'voting', component: VotingListComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'voting/edit/:id', component: VotingEditComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'voting/view/:id', component: VotingViewComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'voting/create', component: VotingCreateComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'chat', component: ChatListComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'chat/edit/:id', component: ChatEditComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'chat/view/:id', component: ChatViewComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'chat/create', component: ChatCreateComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'chat/createbatch', component: ChatCreateBatchComponent, canActivate: [AuthGuardGuard], data: { roles: [3] }},
    { path: 'questions', component: ChatViewCumulatedComponent, canActivate: [AuthGuardGuard], data: { roles: [2, 3] }},
    { path: 'moderator', component: ChatViewModComponent, canActivate: [AuthGuardGuard], data: { roles: [2, 3] }},
  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

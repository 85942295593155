import { Injectable, OnInit } from '@angular/core';
import { Data } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import {DatabaseService} from '../services/database.service';
import { GatewayService } from './gateway.service';
@Injectable({
  providedIn: 'root'
})
export class InteractionsService implements OnInit{
  constructor(private gatewayService: GatewayService, private socket: Socket) { }

  ngOnInit(){
  }
  sendInteraction(reaction: string){
    this.gatewayService.sendInteraction(reaction);
  }
  receiveUsers() {
    return this.socket.fromEvent('users');
  }
  receiveInteraction(){
    return this.socket.fromEvent('interaction');
  }
}
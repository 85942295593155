import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Media } from '../../_models/media';
import { MediaService } from '../../_services/media.service';

@Component({
  selector: 'app-media-list-compact',
  templateUrl: './media-list-compact.component.html',
  styleUrls: ['./media-list-compact.component.css']
})
export class MediaListCompactComponent implements OnInit {

  @Input() options: string;
  @Output() selectMediaEvent = new EventEmitter<Media>();

  medias: Media[];

  constructor(private mediaService: MediaService) { }

  ngOnInit(): void {
    this.getMedias();
  }

  getMedias(): void {
    this.mediaService.getMedias().subscribe(media => {
      this.medias = media;
    });
  }

  selectMedia(media: Media): void {
    this.selectMediaEvent.emit(media);
  }
}

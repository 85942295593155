import { Component, OnChanges, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ChatService } from '../services/chat.service';
import { Chatroom } from '../models/chatroom';
import { Chatmessage } from '../models/chatmessage';
import {BenachrichtigungService} from '../services/benachrichtigung.service'
import { DecodeTokenService } from '../services/decode-token.service';
import { SharedService } from '../services/shared.service';
import { Observable, Subscription } from 'rxjs';
declare var $;

@Component({
  selector: 'app-public-chat',
  templateUrl: './public-chat.component.html',
  styleUrls: ['./public-chat.component.css']
})
export class PublicChatComponent implements OnInit, OnChanges {

  @Output() messageToEmit = new EventEmitter<string>();
  @Input() chatroom: number;
  userId: any;
  public chatmessages = new Array();
  sendNotifications: boolean = false;

  constructor( 
    private chatService: ChatService, 
    private benachrichtigungService: BenachrichtigungService, 
    private decodeTokenService: DecodeTokenService
    ) { }

  ngOnInit(): void {
    this.userId = this.decodeTokenService.decodeUserId().toString();
    console.log(this.userId);
    const eingabe = document.getElementById("eingabefeld") as HTMLInputElement;
    eingabe.addEventListener('keydown', (e)=>{if (13 == e.keyCode) {
        this.sendMessage(eingabe.value);
        eingabe.value = '';
     }});

    this.chatService.receivePublicmessage().subscribe((chatmessage: Chatmessage) => {
      this.chatmessages.push(chatmessage);
      setTimeout(()=>{this.sendNotifications = true}, 1000);
      if(this.sendNotifications === true){
        this.sendMessageToParent('Neue öffentliche Nachricht!');
      }
      
      this.scroll();
      this.scroll();

    });


    }

  sendMessageToParent(message: string) {
    this.messageToEmit.emit(message)
}


  ngOnChanges(): void {
    this.chatmessages = [];
    this.joinRoom();
    this.sendNotifications = false;
    setTimeout(()=>{this.sendNotifications = true}, 1000);
  }

  joinRoom() {
    this.chatService.joinRoom(this.chatroom);
  }

  

  scroll(){
    try {
      $('#myList').animate({scrollTop: $('#myList').prop("scrollHeight")}, 1000);
    } catch (error) {
      
    }  
  }

  sendMessage(content) {
    if(content != ''){
      //Chatroom Id
      const id = 5;
      const chatroom = {id} as Chatroom;

      const chatmessage = {content, chatroom} as Chatmessage;
      
      this.chatService.sendPublicmessage(chatmessage);
    }
  }

  

  testton(url):void{
    new Audio(url).play();
  }

  



}
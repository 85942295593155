import { Component, OnInit } from '@angular/core';
import { Agenda } from '../../_models/agenda';
import { AgendaService } from '../../_services/agenda.service';

@Component({
  selector: 'app-agenda-list',
  templateUrl: './agenda-list.component.html',
  styleUrls: ['./agenda-list.component.css']
})
export class AgendaListComponent implements OnInit {
  
  agendaEntries: Agenda[];

  constructor(
    private agendaService: AgendaService
  ) { }

  ngOnInit(): void {
    this.getAgendaEntries();
  }

  getAgendaEntries() {
    this.agendaService.getAgendas().subscribe(agendas =>
      this.agendaEntries = agendas
    );
  }
}

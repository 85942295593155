import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from '../../_models/company';
import { Role } from '../../_models/role';
import { User } from '../../_models/user';
import { UserService } from '../../_services/user.service';
import { CompanyService } from '../../_services/company.service';
import { RoleService } from '../../_services/role.service';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  userId: number;
  user: User;
  roles: Role[]
  companies: Company[];

  constructor (
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private roleService: RoleService,
    private companyService: CompanyService
  ) { }

  async ngOnInit(): Promise<void> {
    this.userId = +this.route.snapshot.paramMap.get('id');

    await this.getRoles();
    await this.getCompanies();
    await this.getUser();
    
  }

  async getUser(): Promise<void> {
    this.userService.getUser(this.userId).subscribe(user => {
      this.user = user;
      if (!this.user.company) {
        this.user.company = new Company();
      }
    });
  }

  async getRoles(): Promise<void> {
    this.roleService.getRoles().subscribe(roles =>
      this.roles = roles
    );
  }

  async getCompanies(): Promise<void> {
    this.companyService.getCompanies().subscribe(companies =>
      this.companies = companies
    );
  }

  save(): void {
    this.userService.updateUser(this.user).subscribe(() =>
      this.router.navigate(['/admin/user'])
    );
  }
}

import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import { AdminUser } from '../_models/admin-user';

@Injectable({
  providedIn: 'root'
})
export class JwtDecodeService {

  constructor() { }

  getUser(token): AdminUser {
      try {
        const decodedToken = jwt_decode(token);

        const user = new AdminUser();
        user.id = decodedToken.id;
        user.firstName = decodedToken.firstName;
        user.lastName = decodedToken.lastName;
        user.eMail = decodedToken.email;
        user.maps = decodedToken.maps;

        return user;
      }
      catch {
        return null;
      }
  }

  getExp(token): number {
    try {
      const decodedToken = jwt_decode(token);

      return decodedToken.exp;
    }
    catch {
      return null;
    }
  }
}

<body>

    <div id="menu2">
        <nav id="nav2">
            <!--
            <div id="chatopener" (mouseover)="openNav()">
                CHAT <br> <<
                    
            </div>
    -->
        
            <ul id="nav2ul">
                <li><button type="button" id="navbtn3" onclick="programmtoggle()" onmouseover="popoverzu2()" data-container="body" dimiss="popover" data-placement="left" data-content="Sehen Sie sich das aktuelle Programm an"><img src="assets/images/agenda-white.png" /></button></li>
                <li><a href="/map/1" id="mapButtonPopover" data-toggle="tooltip" data-placement="top" data-html="true" ><button type="button" id="navbtn"><img src="assets/images/Map.png" /></button></a></li>
                <!-- <li><a href="/cityhall.php"><button type="button" id="navbtn2"><img src="images/conference.png" /></button></a></li> -->
                <li><button type="button" id="navbtn2" onclick="getloudtoggle()" onmouseover="popoverzu()" data-container="body" dimiss="popover" data-placement="right" data-content="Spenden Sie Applaus!"><img src="assets/images/getloud.png" /></button></li>
        
            </ul>
        </nav>
    
    </div>
    
    
    
    
    <div id="mySidenav" class="sidenav">
        <h2 id="agendaheadline">CHAT</h2>
        <a>
           
    
                <a href="javascript:void(0)" class="closebtn" (mouseover)="closeNav()">>></a>
                <p id="chattext">Clemens@Ansprechpartner:<br>Ich würde mit Ihnen gerne einen Termin vereinbaren. Ich hätte einige Fragen. Wäre es sofort möglich?</p>
                <div id="dateisenden">Datei senden<input type="file" id="myfile" name="myfile"></div>
           
        </a>
    </div>
    
    <div id="telefon2">
        <p>Haben Sie Fragen zum Berufswahltag? Rufen Sie uns einfach unter 09921/9605 4127 an.</p>
    </div>
    <button type="button" id="infobutton" (mouseover)="helper()"><img src="assets/images/info.png" /></button>
    <a><button type="button" class="contact" id="tel" (mouseout)="telefonaus()"><img src="assets/images/phone.png" data-toggle="tooltip" data-placement="top" data-html="true" title="Telefon"/></button></a>
    <a href="mailto:berufswahltag@webeventstudios.com"><button type="button" class="contact" id="mail" (mouseover)="helper()"><img src="assets/images/welcome.png" data-toggle="tooltip" data-placement="top" data-html="true" title="E-Mail schreiben" /></button></a>
    <a data-toggle="modal" data-target="#faq"><button type="button" class="contact" id="chat" (mouseover)="helper()"><img src="assets/images/question.png" data-toggle="tooltip" data-placement="top" data-html="true" title="FAQ (Häufig gestellte Fragen)" /></button></a>
    <div id="helperaus" (mouseout)="helper_off()"></div>




     <!-- FAQ -->
    <div class="modal fade" id="faq" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Häufig gestellte Fragen</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    </div>
                    <div class="modal-body">
                        <div id="accordion">
                            <div class="card">
                              <div class="card-header" id="headingOne">
                                <h5 class="mb-0">
                                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                    Mein Mikrofon / meine Kamera funktioniert nicht
                                  </button>
                                </h5>
                              </div>
                          
                              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body">
                                  Machen Sie unseren <a href="technik">Technikcheck</a>.
                                </div>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-header" id="headingTwo">
                                <h5 class="mb-0">
                                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Ich sehe keine Karte
                                  </button>
                                </h5>
                              </div>
                              <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                <div class="card-body">
                                  Sind Sie eingeloggt? Loggen Sie sich <a href="login">hier</a> ein.
                                </div>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-header" id="headingThree">
                                <h5 class="mb-0">
                                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Die Filter auf der Karte funktionieren nicht
                                  </button>
                                </h5>
                              </div>
                              <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                <div class="card-body">
                                  Klicken Sie auf "Filter anwenden", um Ihre Auswahl anzuwenden. Anschließend wird die Karte mit Ihrer Auswahl neu geladen. Um alle Gebäude anzuzeigen, klicken Sie auf "Alle anzeigen" ganz oben in der Auswahl.
                                </div>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-header" id="heading4">
                                <h5 class="mb-0">
                                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                                    Ich habe eine andere Frage
                                  </button>
                                </h5>
                              </div>
                              <div id="collapse4" class="collapse" aria-labelledby="heading4" data-parent="#accordion">
                                <div class="card-body">
                                  Kontaktieren Sie unseren technischen Support:
                                  berufswahltag@webeventstudios.com
                                </div>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-header" id="heading5">
                                <h5 class="mb-0">
                                  <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                                    Ich komme in kein Gebäude
                                  </button>
                                </h5>
                              </div>
                              <div id="collapse5" class="collapse" aria-labelledby="heading5" data-parent="#accordion">
                                <div class="card-body">
                                  Klicken Sie einfach auf die Schrift oder auf das Logo über der Markierung auf der Karte:
                                  <div style="text-align: center;"><img src="assets/images/demomaus.gif" style="max-width: 300px;" /></div>
                                </div>
                              </div>
                            </div>
                          </div>
                </div>
            </div>
        </div>        
    </div>


    </body>
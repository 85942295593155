import { Component, OnInit, AfterViewInit, OnChanges, Output, EventEmitter } from '@angular/core';
import * as Leaflet from 'leaflet';
import { antPath } from 'leaflet-ant-path';
import { CheckboxRequiredValidator } from '@angular/forms';
import { __values } from 'tslib';
import { visitValue } from '@angular/compiler/src/util';
import { MapService } from '../services/map.service';
import { Map } from '../models/map';
import { ActivatedRoute, Router } from '@angular/router';
import { Branches } from '../models/branches';
import { BranchesService } from '../services/branches.service';
import { forwardRef, HostBinding, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {DecodeTokenService} from '../services/decode-token.service';
import { UsersService } from '../services/users.service';
import { TeleporterService } from '../services/teleporter.service';
declare var $;

@Pipe({ name: 'safeUrl'})
export class SafeUrlPipe implements PipeTransform  {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustResourceUrl(value);
  }
}

@Component({
  selector: 'app-center',
  templateUrl: './center.component.html',
  styleUrls: ['./center.component.css']
})
export class CenterComponent implements OnInit, AfterViewInit, OnChanges  {
  firma: any;
  antwort: Map;
  laenge: any;
  title = 'leafletApps';
  map: Leaflet.Map;
  marks: any[];
  term2;
  filterLocal: string[];
  documents : any;
  videochats: any;
  nameDesUsers: any;
  users:any;
  mArray: any [] = [];
  pArray: any [] = [];
  markersArray: any [] = [];
  activateCount: number = 0;
  interval: any;
  isGallery: any[];
  pdfUrl: any;


  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private mapService: MapService, 
    private branchesService: BranchesService,
    private domSanitizer: DomSanitizer,
    private decodeTokenService: DecodeTokenService,
    private usersService: UsersService
  ){}

  getPdfUrl(url){
    this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  //Legacy, muss erneuert werden.
  einblenden(id): void {
    console.log(id);
  }

  meldung(eingabe){
    window.alert(eingabe);
  }

  //Map erstellen
  generateMap(mapJson, filter){

    //StartupMedia
    try{
      document.getElementById("startupMedia").innerHTML = '<audio id="audio_with_controls" controls autoplay><source src="'+mapJson.startupMedia.url+'" type="audio/mp3"  /></audio>';
      //window.alert(mapJson.startupMedia.url);   
    }catch{}


  
    
    //Bei Partnergebäude keinen Filter anzeigen
    if (mapJson.type == "partner") {
      document.getElementById("filter").style.display = "none";
    }

    //Branchenfilter; Legacy, muss erneuert werden.
    if (filter === 'alle'){
      console.log(this.laenge);
      for (let i = 0; i < this.mArray.length; i++) {
        console.log('alleee');
        try {
          this.mArray[i].addTo(this.map);
          console.log(this.markersArray[i].id);
        } catch (error) {}
      }
    }else{

      for (let index = 0; index < this.mArray.length; index++) {

        try {
          console.log(this.mArray[index]);
          if (this.markersArray[index].id === filter) {
            this.map.removeLayer(this.mArray[index]);
          }
        } catch (error) {}
      }
    }

    if(filter == ''){
      this.laenge = 0;


    //eigenen Marker für Standardgebäude definieren
    var mediaMarker = Leaflet.icon({
      iconUrl: 'assets/images/transparent.png',
      iconSize: [1, 1],
      iconAnchor: [1, 1],
      });

      var defaultMarker = Leaflet.icon({
        iconUrl: 'assets/images/marker-icon.png',
        iconSize: [25, 41],
        iconAnchor: [13, 10],
        });

    var doc = new Array(); 
    for (let d = 0; d < mapJson.media.length; d++) {
      if(mapJson.media[d].media.mediatype.id == 2){
        doc.push({
          id: mapJson.media[d].media.id,
          url: mapJson.media[d].media.url,
          name: mapJson.media[d].media.name
        })
      } 
    }
    this.documents = doc;

    var vc = new Array(); 
    for (let d = 0; d < mapJson.media.length; d++) {
      if(mapJson.media[d].media.mediatype.id == 3){
        vc.push({
          id: mapJson.media[d].media.id,
          url: mapJson.media[d].media.url,
          name: mapJson.media[d].media.name
        })
      } 
    }
    this.videochats = vc;


      console.log(mapJson.type);
      for (let i = 0; i < mapJson.mediapositions.length; i++) {
        this.markersArray.push({pos: [mapJson.mediapositions[i].positionX, mapJson.mediapositions[i].positionY],
          popup: '<a id="popupNr' + mapJson.mediapositions[i].id + '" data-toggle="modal" data-target=".' + mapJson.mediapositions[i].id + '" data-html="true" title="Medium öffnen"><button class="bild" (click)="openWindow(1)"><img src="' + mapJson.mediapositions[i].icon.url + '" style="max-width: 3em; max-height: 3em;" /></button><br><button class="partnerMediaBeschreibung" (click)="openWindow(1)">' + mapJson.mediapositions[i].title + '</button>' ,
          tooltip: mapJson.mediapositions[i].title,
          type: 'media',
          id: mapJson.mediapositions[i].id});

      }



    var h = mapJson.backgroundHeight;
    var w = mapJson.backgroundWidth;
    this.map = Leaflet.map('map', {crs: Leaflet.CRS.Simple, center: [mapJson.midX, mapJson.midY], minZoom: mapJson.zoomMin, maxZoom: mapJson.zoomMax, zoomSnap: 0.25}).setView([mapJson.midX, mapJson.midY], mapJson.zoomDefault);
    /*var southWest = this.map.unproject([0, h], this.map.getMaxZoom() - 1);
    var northEast = this.map.unproject([w, 0], this.map.getMaxZoom() - 1);
    var bounds = new Leaflet.LatLngBounds(southWest, northEast);*/
    var bounds = [[0,0], [h,w]]; 
    var image = Leaflet.imageOverlay(mapJson.background.url, bounds).addTo(this.map);
    this.map.setMaxBounds(bounds);



  //Marker zur Karte hinzufügen
  this.markersArray.forEach((obj) => {
    if (obj.type === 'media'){
      this.mArray[obj.id] = Leaflet.marker(obj.pos, {icon: mediaMarker});
      this.pArray[obj.id] = new Leaflet.Popup({
        autoClose: false,
        closeOnClick: false,
        autoPan: false,
        className: 'media'
      })
        .setContent(obj.popup)
        .setLatLng(obj.pos);
      this.mArray[obj.id].bindPopup(this.pArray[obj.id]);
    }

  }
  );


  for (let index = 0; index < this.mArray.length; index++) {
    try{
      this.mArray[index].addTo(this.map);

      this.mArray[index].openPopup();
      // m[index].on('click', this.fensterLaden(index));
    } catch {}
    this.laenge = this.laenge + 1;
  }

  this.map.on('click', (e) => {
    console.log("Lat, Lon : " + e.latlng.lat + ", " + e.latlng.lng)
});


this.addStarters(mapJson.media.length);
//Zoom auslesen und Popups ein/ausblenden
//this.map.on('zoom', console.log("Zoom"));





}
  }


  modalId: number;
  sliderId: any;
isWindowOpen: boolean = false;

addFolderListeners(){
  for (let index = 0; index < 1000; index++) {
    try {
      let test = document.getElementById("popupNr"+index);
      test.addEventListener("click", (e) =>{
        if(localStorage.getItem('isWindowOpen')){window.alert('Bitte schließen Sie zuerst alle anderen Medienordner.')}else{
          const folder = document.getElementById('folder'+index);
          console.log(index);
          this.sliderId = index;
          console.log(this.sliderId);
          folder.style.display = "block";
          localStorage.setItem('popupNbr', index.toString());
          localStorage.setItem('isWindowOpen', 'true');
        }
      });
    } catch (error) {}  
  }  
  $('.previewElement').tooltip('enable');
}



  // Galerie für Modals!!!
 slideIndex: number = 1;
 
  plusDivs(n) {
    let nbr = localStorage.getItem('popupNbr');
    console.log(nbr);
    this.showDivs(this.slideIndex += n, nbr);
  }
  
  showDivs(n, modalId) {
    var i;
    let x = $('.mySlides'+modalId);
    if (n > x.length) {this.slideIndex = 1}
    if (n < 1) {this.slideIndex = x.length};
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    x[this.slideIndex-1].style.display = "block";
    console.log('Slide Nr. '+this.slideIndex +' von '+x.length);
  }

  openModal(id){
    $('.previewElement').tooltip('hide');
    console.log(id);
    this.modalId = id;
    let element = document.getElementById('modal'+id);
    console.log(element);
    element.classList.add('showModal');
    try {
      this.showDivs(this.slideIndex, id);
    } catch (error) {
      
    }
    $('#video'+id).trigger('play');
  }

videoUrl: SafeResourceUrl;

  openModalYt(id, url){
    $('.previewElement').tooltip('hide');
    let iframeElement = $('iframe'+id);
    let videoUrlUnsafe = 'https://www.youtube.com/embed/'+url /*+ '?autoplay=1'*/ ;
    
    let videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(videoUrlUnsafe);
    console.log(videoUrl);
    iframeElement.src = videoUrl;
    this.videoUrl = videoUrl;
    console.log(id);
    this.modalId = id;
    let element = document.getElementById('modal'+id);
    console.log(element);
    element.classList.add('showModal');
  }

  openModalVi(id, url){
    $('.previewElement').tooltip('hide');
    let iframeElement = $('iframe'+id);
    let videoUrlUnsafe = 'https://player.vimeo.com/video/' + url /* + '?autoplay=1'*/;
    
    let videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(videoUrlUnsafe);
    console.log(videoUrl);
    this.videoUrl = videoUrl;
    iframeElement.src = videoUrl;
    
    console.log(id);
    this.modalId = id;
    let element = document.getElementById('modal'+id);
    console.log(element);
    element.classList.add('showModal');
  }



  openModalDoc(id, url){
    $('.previewElement').tooltip('hide');
    let iframeElement = $('iframe'+id);
    let videoUrlUnsafe = url;
    
    let videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(videoUrlUnsafe);
    console.log(videoUrl);
    this.videoUrl = videoUrl;
    iframeElement.src = videoUrl;
    
    console.log(id);
    this.modalId = id;
    let element = document.getElementById('modal'+id);
    console.log(element);
    element.classList.add('showModal');
  }

  closeModalYt(id){
    let element = document.getElementById('modal'+id);
    element.classList.remove('showModal');
    $('.previewElement').tooltip('hide');
    let iframeElement = $('iframe'+id);
    let videoUrlUnsafe = 'https://www.youtube.com/embed/';
    
    let videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(videoUrlUnsafe);
    console.log(videoUrl);
    iframeElement.src = videoUrl;
    this.videoUrl = videoUrl;
  }

  closeModal(id){
    let element = document.getElementById('modal'+id);
    element.classList.remove('showModal');
    $('video').trigger('pause');
  }


  navigate(id){
    console.log('navigate '+id);
    this.router.navigate(['redirect/'+id]);
  }



  //Datenbankanbindung

  ngAfterViewInit() {
   
 }

 ngOnChanges(){
  try {
    localStorage.removeItem('isWindowOpen');
  } catch (error) {}
  this.openWindow(1);
  const id = this.route.snapshot.paramMap.get('id');
  localStorage.setItem('mapId', id);  

  // evtl Weiterleiten auf Standardgebäude
  if (id == '4') {
    this.router.navigate(['welcomecenter']);
  }else if (id == '5') {
    window.location.href = "cityhall";
  }else if (id == '8') {
    this.router.navigate(['mediacenter']);
  }
  //Datenbankverbindung
  this.mapService.getMap(id)
    .subscribe(firma => this.firma = firma);
  this.mapService.getMap(id)
    .subscribe(antwort => this.generateMap(antwort, ''));
   
  //this.decodeTokenService.decodeUserId(); //UserID erhalten

  this.nameDesUsers = this.decodeTokenService.decodeFirstName() + ' ' + this.decodeTokenService.decodeLastName();
 }

  ngOnInit() {
    try {
      localStorage.removeItem('isWindowOpen');
    } catch (error) {}
    const id = this.route.snapshot.paramMap.get('id');
    localStorage.setItem('mapId', id);
    this.updatePosition(id);   
    // evtl Weiterleiten auf Standardgebäude
    if (id == '4') {
      this.router.navigate(['welcomecenter']);
    }else if (id == '5') {
      window.location.href = "cityhall";
    }else if (id == '8') {
      this.router.navigate(['mediacenter']);
    }
    //Datenbankverbindung
    this.mapService.getMap(id)
      .subscribe((firma) => {
        this.firma = firma;
        this.getMediaAufbereitung(firma);
      });
    this.mapService.getMap(id)
      .subscribe(antwort => this.generateMap(antwort, ''));
     
    //this.decodeTokenService.decodeUserId(); //UserID erhalten

    //this.nameDesUsers = this.decodeTokenService.decodeFirstName() + ' ' + this.decodeTokenService.decodeLastName();    
    setTimeout(this.addFolderListeners, 2000);
}

getMediaAufbereitung(json){
  let test = new Array();
  let isGal = new Array();
  for (let index = 0; index < json.mediapositions.length; index++) {
    try {
      test[json.mediapositions[index].id] =  json.mediapositions[index];
    } catch (error) {}
  }

  for (let k = 0; k < test.length; k++) {
    let count = 0;
    try {
      for (let l = 0; l < test[k].media.length; l++) {
        if(test[k].media[l].mediatype.id === 1){
          count ++;
        }          
      }
    } catch (error) {
      
    }
    if(count>=1){
      isGal[k] = true;
    }else{isGal[k] = false;}
    
  }
    
    this.isGallery = isGal;
    console.log(this.isGallery);
  }

  isGalleryPos(id){
    return this.isGallery[id];
  }

  openGalerie(id){
    window.alert(id);
  }
  


openVideochat(id){
  $('.previewElement').tooltip('hide');
  this.router.navigate(['videochat/'+id]);
}



closeWindow(id){
  localStorage.removeItem('isWindowOpen');
  console.log(this.isWindowOpen);
  const folder = document.getElementById('folder'+id);
  folder.style.display = "none";
  
}

openWindow(i){
  window.alert(i);
}


updatePosition(posId){
  //this.usersService.update(this.decodeTokenService.decodeUserId(), {"currentLocation": posId.toString()});
}


  //Dokumenteinbindung: Quelle als sicher deklarieren
  getSource(url){
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  videoStarten(id){
    let videoPlayer: HTMLMediaElement = document.querySelector("[id='" + id.toString() + "']");
    videoPlayer.setAttribute('src', id);
    console.log("Video gestartet");
    videoPlayer.play();
  }

  videoStoppen(id){
    $('video').trigger('pause');
  }

  ytStop(){
    var frame = document.getElementById("iframeid");
    //frame.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
  }

  openNav(){
    $('#filtermap').dropdown('toggle');
    $('#filtermap').tooltip('hide');
  }


  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }



  lpopoverOpen(id){
    console.log(id);
    $('#lpopup'+id).popover('show');
  }



  addStarters(length){
    for (let index = 0; index < 1000; index++) {
      try {
        let test = document.getElementById("popupNr"+index);
        test.addEventListener("click", (event) => {

        //window.alert(index);
        //console.log(index);
        //let videoPlayer: HTMLMediaElement = document.querySelector("[id='" + index.toString() + "']");
        //videoPlayer.play();
        $('#video'+index).trigger('play');
        //console.log($('#video'+index));
      })
      } catch (error) {}
    }
  }

}
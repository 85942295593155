<p *ngIf="chatmessages.length === 0">Aktuell sind keine Fragen vorhanden!</p>

<div id="privateNachrichten">
    <ul id="myList2">
        <li *ngFor="let chatmessage of chatmessages; index as index" >
            <div *ngIf="chatmessage?.sender.id == userId" class="dummy speech-bubble-self">
                <italic>{{chatmessage?.created | date:'shortTime'}}: </italic>
                <bold class="fett">{{chatmessage?.sender.firstName}} {{chatmessage?.sender.lastName}}: </bold>
                {{chatmessage?.content}}
            </div>
            <div *ngIf="chatmessage?.sender.id != userId" class="dummy speech-bubble" [ngClass]="{sent: chatmessage.sent}">
                <italic>{{chatmessage?.created | date:'shortTime'}}: </italic>
                {{chatmessage?.content}}
            </div>
        </li>
    </ul>
</div>
<h4>Lautstärke regeln</h4>
  <div class="slidecontainer">
    Interactions: {{generalVolume}}%
    <input type="range" min="0" max="100" value="{{generalVolume}}" class="slider" id="myRange">
  </div>

  <div class="slidecontainer">
    Agenda: {{agendaVolume}}%
    <input type="range" min="0" max="100" value="{{agendaVolume}}" class="slider" id="myRangeAgenda">
  </div>

  <div class="slidecontainer">
    Chat: {{chatVolume}}%
    <input type="range" min="0" max="100" value="{{chatVolume}}" class="slider" id="myRangeChat">
  </div>
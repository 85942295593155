import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Media } from '../../_models/media';
import { Mediatype } from '../../_models/mediatype';
import { MediaService } from '../../_services/media.service';
import { MediatypeService } from '../../_services/mediatype.service';

@Component({
  selector: 'app-media-edit',
  templateUrl: './media-edit.component.html',
  styleUrls: ['./media-edit.component.css']
})
export class MediaEditComponent implements OnInit {

  mediaId: number;
  media: Media;
  mediatypes: Mediatype[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mediaService: MediaService,
    private mediatypeService: MediatypeService
  ) { }

  ngOnInit(): void {
    this.mediaId = +this.route.snapshot.paramMap.get('id');

    this.getMediatypes();
    this.getMedia();
  }

  getMedia(): void {
    this.mediaService.getMedia(this.mediaId).subscribe(media =>
      this.media = media
    );
  }

  getMediatypes(): void {
    this.mediatypeService.getMediatypes().subscribe(mediatypes =>
      this.mediatypes = mediatypes
    );
  }

  save(): void {
    this.mediaService.updateMedia(this.media).subscribe(() =>
      this.router.navigate(['/admin/media'])
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { BranchesService } from '../services/branches.service';

@Component({
  selector: 'app-interessen',
  templateUrl: './interessen.component.html',
  styleUrls: ['./interessen.component.css']
})
export class InteressenComponent implements OnInit {
  branches: any;
  auswahl: any;
  constructor(private branchesService: BranchesService) { }

  ngOnInit(): void {
    this.getBranches();
    localStorage.setItem('interessen', 'true');
  }

  getBranches(){
    this.branchesService.getBranches()
    .subscribe(branches => this.branches = branches);
    this.branchesService.getBranches()
    .subscribe(branches2 => {
      this.activateCheckboxes(branches2);
      this.filterAnwenden('alle');
    });
  }

  filterAnwenden(name): void{
    if (name == 'alle') {
      //this.generateMap('', 'alle');
      for (let index = 0; index < 100; index++) {
        try {
          var element2 = <HTMLInputElement> document.getElementById(index.toString());
          element2.checked = true;
          localStorage.setItem(index.toString(), element2.checked.toString());
        } catch (error) {}
        
      }  
      //this.refresh();
    }else{
      //this.generateMap('', name);
    var element = <HTMLInputElement> document.getElementById(name);
    var isChecked = element.checked;
    localStorage.setItem(name.toString(), element.checked.toString());
    } 
    //console.log(name + '. Checkbox: ' + isChecked)
  }

   //Seite neu laden
   refresh(){
    window.location.reload();
  }

  //Checkboxes
  activateCheckboxes(branches){
    for (let l = 0; l < branches.length; l++) {
      try{
      if (localStorage.getItem(branches[l].id) == 'true') {
        try {
          var element3 = <HTMLInputElement> document.getElementById(branches[l].id.toString());
          element3.checked = true;
        } catch (error) {}
        
      }else{
        try {
          var element3 = <HTMLInputElement> document.getElementById(branches[l].id.toString());
          element3.checked = false;
          localStorage.setItem(branches[l].id, 'false');
        } catch (error) {}
      }
    }catch{}} 
  }

}


<body>


    <nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-primary" id="filterBar">
        <a class="navbar-brand">MEDIACENTER</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
            <input class="form-control" type="search" placeholder="Suchen..." aria-label="Search" [(ngModel)]="mediaSuche">
          <div class="navbar-nav">
            <a class="nav-item nav-link active unselectable" (click)="filtern('')">Alle <span class="sr-only">(current)</span></a>
            <a class="nav-item nav-link unselectable" (click)="filtern('mp4')">Videos</a>
            <a class="nav-item nav-link unselectable" (click)="filtern('image')">Bilder</a>
            <a class="nav-item nav-link unselectable" (click)="filtern('pdf')">Dokumente</a>
          </div>
            
        </div>
      </nav>
    

      <div class="featuredContainer" *ngFor="let feature of featured |filter:mediaSuche">
        <div *ngIf="feature?.mediatype?.id ===1 && feature?.featured"><!--Image-->
            <div class="container unselectable" id="{{feature?.url}}" (click)="open(feature?.url, feature?.id)">
                <div class="media">
                    <img src="{{feature?.url}}" style="height: 8em; width: 20%; object-fit: cover;" class="mr-3" id="{{feature?.id}}" />
                    <div class="media-body">
                        <h5 class="mt-0">{{feature?.name}}</h5>
                        <p>{{feature?.description}}</p>
                        <!--<button class="btn btn-outline-dark">Bild vergrößern</button>-->
                        <p class="createdAt">FEATURED</p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="feature?.mediatype?.id ===4 && feature?.featured"><!--MP4-->
            <div class="container unselectable" (click)="open(feature?.url, feature?.id)">
                <div class="media">
                    <video src="{{feature?.url}}" style="height: 8em; width: 20%; object-fit: contain;" class="mr-3 {{feature?.id}}" id="{{feature?.id}}" preload="auto"></video>
                    <div class="media-body">
                        <h5 class="mt-0">{{feature?.name}}</h5>
                        <p>{{feature?.description}}</p>
                        <!--<button class="btn btn-outline-dark">Video abspielen</button>-->
                        <p class="createdAt">FEATURED</p>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="feature?.mediatype?.id ===2 && feature?.featured"><!--PDF-->
            <a class="dokumentLink" href="{{feature?.url}}" target="blank">
                <div class="container unselectable">
                    <div class="media">
                        <img src="assets/images/document-black.png" style="max-height: 8em; min-width: 20%; object-fit: contain;" class="mr-3" id="{{feature?.id}}" />
                        <div class="media-body">
                            <h5 class="mt-0">{{feature?.name}}</h5>
                            <p>{{feature?.description}}</p>
                            <button class="btn btn-outline-dark">Dokument öffnen</button>
                            <p class="createdAt">FEATURED</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div *ngIf="feature?.mediatype?.id ===12 && feature?.featured"><!--PDF-->
            <a class="dokumentLink" href="{{feature?.url}}" target="blank">
                <div class="container unselectable">
                    <div class="media">
                        <img src="assets/images/document-black.png" style="max-height: 8em; min-width: 20%; object-fit: contain;" class="mr-3" id="{{feature?.id}}" />
                        <div class="media-body">
                            <h5 class="mt-0">{{feature?.name}}</h5>
                            <p>{{feature?.description}}</p>
                            <button class="btn btn-outline-dark">Link öffnen</button>
                            <p class="createdAt">FEATURED</p>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    </div>


<div class="mediencenterContainer" *ngFor="let media of medien |filter:mediaSuche">
    <div *ngIf="media?.mediatype?.id ===1 && media?.mediacenter"><!--Image-->
        <div class="container unselectable" id="{{media?.url}}" (click)="open(media?.url, media?.id)">
            <div class="media">
                <img src="{{media?.url}}" style="height: 8em; width: 20%; object-fit: cover;" class="mr-3" id="{{media?.id}}" />
                <div class="media-body">
                    <h5 class="mt-0">{{media?.name}}</h5>
                    <p>{{media?.description}}</p>
                    <!--<button class="btn btn-outline-dark">Bild vergrößern</button>-->
                    <p *ngIf="media?.created" class="createdAt">Hochgeladen: {{media?.created | date: 'd.M.y' }}</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="media?.mediatype?.id ===4 && media?.mediacenter"><!--MP4-->
        <div class="container unselectable" (click)="open(media?.url, media?.id)">
            <div class="media">
                <video src="{{media?.url}}" style="height: 8em; width: 20%; object-fit: contain;" class="mr-3 {{media?.id}}" id="{{media?.id}}" controls preload="metadata"></video>
                <div class="media-body">
                    <h5 class="mt-0">{{media?.name}}</h5>
                    <p>{{media?.description}}</p>
                    <!--<button class="btn btn-outline-dark">Video abspielen</button>-->
                    <p *ngIf="media?.created" class="createdAt">Hochgeladen: {{media?.created | date: 'd.M.y' }}</p>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="media?.mediatype?.id ===2 && media?.mediacenter"><!--PDF-->
        <a class="dokumentLink" href="{{media?.url}}" target="blank">
            <div class="container unselectable">
                <div class="media">
                    <img src="assets/images/document-black.png" style="max-height: 8em; min-width: 20%; object-fit: contain;" class="mr-3" id="{{media?.id}}" />
                    <div class="media-body">
                        <h5 class="mt-0">{{media?.name}}</h5>
                        <p>{{media?.description}}</p>
                        <button class="btn btn-outline-dark">Dokument öffnen</button>
                        <p *ngIf="media?.created" class="createdAt">Hochgeladen: {{media?.created | date: 'd.M.y' }}</p>
                    </div>
                </div>
            </div>
        </a>
    </div>
    <div *ngIf="media?.mediatype?.id ===12 && media?.mediacenter"><!--PDF-->
        <a class="dokumentLink" href="{{media?.url}}" target="blank">
            <div class="container unselectable">
                <div class="media">
                    <img src="assets/images/document-black.png" style="max-height: 8em; min-width: 20%; object-fit: contain;" class="mr-3" id="{{media?.id}}" />
                    <div class="media-body">
                        <h5 class="mt-0">{{media?.name}}</h5>
                        <p>{{media?.description}}</p>
                        <button class="btn btn-outline-dark">Link öffnen</button>
                        <p *ngIf="media?.created" class="createdAt">Hochgeladen: {{media?.created | date: 'd.M.y' }}</p>
                    </div>
                </div>
            </div>
        </a>
    </div>
</div>
  
<!--Platzhalter unter Medien-->
<div id="containerunten">
</div>



</body>

<!--Navigation und Agenda einbinden-->
<app-navigation></app-navigation>
<app-agenda></app-agenda>
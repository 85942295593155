import { Mediatype } from './mediatype';

export class Media {
    id: number;
    name: string;
    description: string;
    url: string;
    mediacenter: boolean;
    featured: boolean;
    mediatype: Mediatype;
}

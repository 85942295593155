<div aria-live="polite" aria-atomic="true"  style="position: relative; min-height: 200px;">
    <!-- Position it -->
    <div id="toasts" style="position: fixed; top: 1em; margin: 0 auto; left: 40%; z-index: 800000;">
  
      <!-- Then put toasts within -->
      <div *ngFor="let termin of agenda" class="toast" id="toast{{termin.id}}" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <img src="assets/images/agenda.png" class="rounded mr-2" style="max-width: 1em;" />
          <strong class="mr-auto">Erinnerung</strong>
          <small class="text-muted">jetzt</small>
          <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">
          {{termin.name}}
        </div>
      </div>

    </div>
  </div>
import { Component, OnInit } from '@angular/core';
import { DecodeTokenService } from '../services/decode-token.service';
import { UsersService } from '../services/users.service';
declare var $;

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.css']
})
export class UpdateUserComponent implements OnInit {

  userId: any;
  userData: any;
  fN: any;
  lN: any;
  constructor(
    private decodeTokenService: DecodeTokenService,
    private usersService: UsersService  
  ) { }

  ngOnInit(): void {
    this.userId = this.decodeTokenService.decodeUserId();
    //console.log(this.userId);
    this.usersService.getUser(this.userId)
    .subscribe(userData => this.userData = userData);
  }

  updateFn(fN){
    this.fN = fN;
    console.log(this.fN);
    document.getElementById("alert").style.display = "none";
  }

  updateLn(lN){
    this.lN = lN;
    console.log(this.lN);
    document.getElementById("alert").style.display = "none";
  }

  onSubmit(){
    this.usersService.update(this.userId, {"firstName": this.fN, "lastName": this.lN});
    document.getElementById("success").style.display = "block";
    //document.getElementById("formular").style.display = "none";
  }

  openCity(cityName) {
    var i;
    var k;
    var x = $('.city');
    var y = $('.trigger');
    for (i = 0; i < x.length; i++) {
      x[i].style.display = "none";
    }
    for (k = 0; k < y.length; k++) {
      try {
        y[k].classList.remove('w3-dark-grey');
      } catch (error) {}
    }
    document.getElementById(cityName).style.display = "block";
    document.getElementById(cityName+'Trigger').classList.add('w3-dark-grey');
  }
}

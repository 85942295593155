import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Voting } from '../_models/voting';

@Injectable({
  providedIn: 'root'
})
export class VotingService {

  url = `${environment.apiUrl}/votings`;

  constructor(
    private http: HttpClient
  ) { }
  
  getVotings(): Observable<Voting[]> {
    return this.http.get<Voting[]>(this.url)
      .pipe(
        tap(_ => console.log('fetched Votings')),
        catchError(this.handleError<Voting[]>('getVotings, []'))
      );
  }

  getVoting(id: number): Observable<Voting>{
    return this.http.get<Voting>(`${this.url}/${id}`)
      .pipe(
        tap(_ => console.log(`fetched Voting id=${id}`)),
        catchError(this.handleError<Voting>('getVoting, []'))
      );
  }

  getVotingWithResults(id: number): Observable<Voting>{
    return this.http.get<Voting>(`${this.url}/${id}/results`)
      .pipe(
        tap(_ => console.log(`fetched Voting with results id=${id}`)),
        catchError(this.handleError<Voting>('getVotingWithResults, []'))
      );
  }

  updateVoting(voting: Voting): Observable<any>{
    return this.http.put(`${this.url}/${voting.id}`, voting)
      .pipe(
        tap(_ => console.log(`updated voting id=${voting.id}`)),
        catchError(this.handleError<any>('updateVoting'))
      );
  }

  createVoting(voting: Voting): Observable<Voting>{
    return this.http.post(this.url, voting)
      .pipe(
        tap(_ => console.log('created voting id=${voting.id}')),
        catchError(this.handleError<any>('createVoting'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}

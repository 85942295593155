import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Voting } from '../../_models/voting';
import { VotingOption } from '../../_models/voting-option';
import { VotingService } from '../../_services/voting.service';

@Component({
  selector: 'app-voting-edit',
  templateUrl: './voting-edit.component.html',
  styleUrls: ['./voting-edit.component.css']
})
export class VotingEditComponent implements OnInit {

  votingId: number;
  voting: Voting;

  newOption: VotingOption = new VotingOption();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private votingService: VotingService,
    // private VotingOptionService: VotingOptionService
  ) { }

  async ngOnInit(): Promise<void> {
    this.votingId = +this.route.snapshot.paramMap.get('id');

    this.getVoting();
  }

  getVoting(): void {
    this.votingService.getVoting(this.votingId).subscribe(voting => {
      this.voting = voting;
    });
  }

  async saveAndClose(): Promise<void> {
    await this.save();
    this.router.navigate([`/admin/voting/view/${this.voting.id}`]);

  }

  async save(): Promise<void> {
    this.votingService.updateVoting(this.voting).subscribe();
  }

  addVotingOption(): void {
    this.voting.options.push(this.newOption);
    this.newOption = new VotingOption();
    this.save();
  }

  removeVotingOption(id: number): void {
    this.voting.options = this.voting.options.filter(option => option.id !== id);
    this.save();
  }

}

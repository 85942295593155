<nav class="navbar sticky-top navbar-expand-lg navbar-dark bg-dark justify-content-between">
    <a class="navbar-brand" routerLink="/admin/center">Web Event Studios | Centerpanel</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Menü ausklappen">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbar">
        <ul class="navbar-nav">
            <li *ngIf="currentUser.role.id === 3" class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/admin/center">Center</a>
            </li>
            <li *ngIf="currentUser.role.id === 3" class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/admin/voting">Abstimmungen</a>
            </li>
            <li *ngIf="currentUser.role.id === 3" class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/admin/chat">Chat</a>
            </li>
            <li *ngIf="currentUser.role.id === 3" class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/admin/media">Medien</a>
            </li>
            <li *ngIf="currentUser.role.id === 3" class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/admin/agenda">Agenda</a>
            </li>
            <li *ngIf="currentUser.role.id === 3" class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/admin/user">Benutzer</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/admin/questions">Fragen</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/admin/moderator">Moderator</a>
            </li>
        </ul>
    </div>
    <ul class="navbar-nav">
        <button class="btn btn-sm btn-light" routerLink="/admin/login" *ngIf="!currentUser">Login</button>
        <button class="btn btn-sm btn-danger" (click)="logout()" *ngIf="currentUser">Logout</button>
    </ul>
</nav>

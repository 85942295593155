import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slido',
  templateUrl: './slido.component.html',
  styleUrls: ['./slido.component.css']
})
export class SlidoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  showSlido(){
    document.getElementById("slidoFrame").style.right= "0px";
    this.testton('assets/sounds/slide.mp3');
    const grau = document.getElementById("ausgrauen2");
    grau.style.display = "block";
    setTimeout(()=>{grau.style.opacity = "100%"}, 100);
  }

  hideSlido(){
    document.getElementById("slidoFrame").style.right= "-2000px";
    this.testton('assets/sounds/blop.wav');
    const grau = document.getElementById("ausgrauen2");
    grau.style.opacity = "0%"
    setTimeout(()=>{grau.style.display = "none";}, 500);
  }

  testton(url):void{
    var audio = new Audio(url);
    audio.volume = Number(localStorage.getItem('chatVolume'))/100;
    audio.play();
  }
}

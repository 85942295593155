<body style="overflow-y: scroll;">
    

<p><strong><span style='font-size:16px;line-height:107%;font-family:"Arial",sans-serif;'>Nutzungsbedingungen&nbsp;</span></strong></p>
<p><strong><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>1. Geltungsbereich&nbsp;</span></strong></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>1.1 Die nachfolgenden Nutzungsbedingungen gelten zwischen ARBERLAND REGio GmbH, Amtsgerichtstr. 6 - 8, D-94209 Regen &nbsp;(nachfolgend &bdquo;Veranstalter&ldquo;) und den Nutzern der Online-Plattform (nachfolgend &bdquo;Nutzer&ldquo;) f&uuml;r die Nutzung der Online-Plattform Berufswahltag-digital.de.</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>1.2 Die Nutzungsbedingungen k&ouml;nnen <u><span style="color:#2E74B5;"><a href="nutzungsbedingungen">hier</a></span></u></span><span style="font-size:11px;line-height:107%;"><a href="#_msocom_1" id="_anchor_1" language="JavaScript" name="_msoanchor_1">[CJ1]</a>&nbsp;</span><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;heruntergeladen und gespeichert werden.&nbsp;</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>1.3 Entgegenstehende oder abweichende Gesch&auml;ftsbedingungen werden nicht anerkannt, es sei denn der Veranstalter stimmt diesen ausdr&uuml;cklich schriftlich zu.&nbsp;</span></p>
<p><strong><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>2. Registrierung und Account</span></strong></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>2.1 Sofern die Online-Plattform einer unbestimmten Anzahl von Personen zur Verf&uuml;gung gestellt wird (<u>&ouml;ffentlicher Zugang</u>) muss im&nbsp;</span><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>Registrierungsprozess eine E-Mail-Adresse und ein Passwort hinterlegt werden. In der Regel ist auch der b&uuml;rgerliche Name des Nutzers zu hinterlegen.</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>2.2 Sofern der Zugang auf einen vom Veranstalter vordefinierten Nutzerkreis beschr&auml;nkt ist (<u>beschr&auml;nkter Zugang</u>), erh&auml;lt der Nutzer Zugang zur Online-Plattform mit den vorher an ihn ausgeh&auml;ndigten Login-Daten. Sofern nicht vorher vom Veranstalter hinterlegt, muss im&nbsp;</span><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>Registrierungsprozess eine E-Mail-Adresse und ein Passwort hinterlegt werden. In der Regel ist auch der b&uuml;rgerliche Name des Nutzers zu hinterlegen.</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>2.3 Um die Registrierung abzuschlie&szlig;en, muss der Nutzer einen Haken neben dem Feld &bdquo;Ich bin mit den Nutzungsbedingungen einverstanden</span><span style="font-size:11px;line-height:107%;"><a href="#_msocom_2" id="_anchor_2" language="JavaScript" name="_msoanchor_2">[SKW2]</a>&nbsp;</span><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>&ldquo; setzen. Je nach Nutzungsumfang ist dieser Prozess ggf. bereits im Vorfeld, z.B. auf einer vorgeschalteten Landing-Page des Veranstalters, zu durchlaufen. Mit einem Klick auf dem Button &bdquo;Registrieren</span><span style="font-size:11px;line-height:107%;"><a href="#_msocom_3" id="_anchor_3" language="JavaScript" name="_msoanchor_3">[SKW3]</a>&nbsp;</span><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>&ldquo; wird die Registrierung durch den Nutzer best&auml;tigt und der Nutzer erkl&auml;rt sich damit einverstanden, eine rechtlich bindende Vereinbarung mit dem Veranstalter abzuschlie&szlig;en und diese Nutzungsbedingungen in rechtlich verbindlicher Weise anzunehmen. Dies gilt auch, wenn der Nutzer die Online-Plattform im Namen eines Unternehmens oder einer anderen Person verwendet.</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>2.4 Der Nutzer best&auml;tigt mit der Registrierung, dass er rechtlich erm&auml;chtigt ist, die entsprechenden Handlungen, insbesondere wenn er f&uuml;r ein Unternehmen handelt, rechtlich wirksam vorzunehmen. Mit der Registrierung best&auml;tigt der Nutzer, dass er mindestens 18 Jahre alt ist oder die Zustimmung zur Nutzung durch einen gesetzlichen Vertreter erteilt bekommen hat. Sollte dies nicht der Fall sein, haftet derjenige, der die Registrierung vornimmt, dem Veranstalter auf etwaige entstehende Sch&auml;den und Aufw&auml;nde. Der Veranstalter beh&auml;lt sich in diesem Fall das Recht vor, den Nutzer von der Plattform auszuschlie&szlig;en.&nbsp;</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>2.5 Dem Nutzer wird in der Regel an die bei der Registrierung hinterlegte E-Mail-Adresse eine Best&auml;tigungs-E-Mail versandt. Zur Einrichtung des Accounts muss in der Best&auml;tigungs-E-Mail auf den Link &bdquo;Account aktivieren&ldquo; geklickt werden.</span></p>
<p><strong><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>3. Funktionen und Aufbau der Online-Plattform</span></strong></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>3.1 Die Funktionen und der Aufbau der Online-Plattform k&ouml;nnen variieren und werden st&auml;ndig weiterentwickelt und verbessert. Der Nutzer hat keinen Anspruch auf die genannten Funktionen oder die beschriebenen Eigenschaften. Zum <u>Funktionsumfang</u> geh&ouml;ren insbesondere:</span></p>
<ul style="list-style-type: disc;">
    <li><strong><span style='line-height:107%;font-family:"Arial",sans-serif;font-size:13px;'>Chat</span></strong><span style='line-height:107%;font-family:"Arial",sans-serif;font-size:13px;'>&nbsp;&ndash; &Ouml;ffentlicher Chat, Pers&ouml;nlicher Chat&nbsp;</span></li>
</ul>
<p style="margin-bottom:.0001pt;"><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<ul style="list-style-type: disc;">
    <li><strong><span style='line-height:107%;font-family:"Arial",sans-serif;font-size:13px;'>Videochat</span></strong><span style='line-height:107%;font-family:"Arial",sans-serif;font-size:13px;'>&nbsp;</span></li>
</ul>
<p style="margin-bottom:.0001pt;"><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<ul style="list-style-type: disc;">
    <li><strong><span style='line-height:107%;font-family:"Arial",sans-serif;font-size:13px;'>Live-Streaming</span></strong><span style='line-height:107%;font-family:"Arial",sans-serif;font-size:13px;'>&nbsp;&ndash; Erfolgt &uuml;ber einen deutschen Streamingserver</span></li>
</ul>
<p style="margin-bottom:.0001pt;"><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<ul style="list-style-type: disc;">
    <li><strong><span style='line-height:107%;font-family:"Arial",sans-serif;font-size:13px;'>Nutzerprofile&nbsp;</span></strong></li>
</ul>
<p style="margin-bottom:.0001pt;"><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<ul style="list-style-type: disc;">
    <li><strong><span style='line-height:107%;font-family:"Arial",sans-serif;font-size:13px;'>Agenda&nbsp;</span></strong></li>
</ul>
<p style="margin-bottom:.0001pt;"><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<ul style="list-style-type: disc;">
    <li><strong><span style='line-height:107%;font-family:"Arial",sans-serif;font-size:13px;'>Karte&nbsp;</span></strong></li>
</ul>
<p style="margin-bottom:.0001pt;"><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>3.2 Der Veranstalter ist berechtigt, im Rahmen der Weiterentwicklung und Verwaltung des Angebots einzelne Funktionen und den Aufbau zu ver&auml;ndern, zu erweitern, oder wegzulassen, solange der Zweck und die Ziele nicht wesentlich beeintr&auml;chtigt werden.</span></p>
<p><strong><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>4. Datenschutz und Privatsph&auml;re&nbsp;</span></strong></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>4.1 In der <a href="https://webeventstudios.com/page-4/"> Datenschutzerklärung</a>&nbsp;</span><span style="font-size:11px;line-height:107%;"><a href="#_msocom_4" id="_anchor_4" language="JavaScript" name="_msoanchor_4">[SKW4]</a>&nbsp;</span><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>finden sich ausf&uuml;hrliche Hinweise dazu, wie der Veranstalter die personenbezogenen Daten sch&uuml;tzt und verarbeitet.&nbsp;</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>4.2 In der Regel ist f&uuml;r alle Nutzer, z.B. wenn sie sich in einem Chat befinden oder einen Raum betreten der hinterlegte Nutzername f&uuml;r alle Nutzer der Plattform sichtbar. Die Daten sind nicht &ouml;ffentlich einsehbar.&nbsp;</span></p>
<p><strong><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>5. Pflichten der Nutzer</span></strong></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>5.1. Der Nutzer sichert zu, dass die im Account hinterlegten Angaben richtig, vollst&auml;ndig und nicht irref&uuml;hrend sind. Der Nutzer ist verpflichtet die Angaben aktuell zu halten und ggf. Anpassungen vorzunehmen. Der Anbieter &uuml;bernimmt keine Gew&auml;hr f&uuml;r die Richtigkeit oder Vollst&auml;ndigkeit der Angaben.</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>5.2. Eine E-Mail-Adresse darf auf der Plattform nur einmal verwendet werden.</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>5.3 Die Nutzung des Accounts darf nur von der registrierten Person oder von ihm ausdr&uuml;cklich hierzu rechtlich erm&auml;chtigten Personen, z.B. von anderen Mitarbeitern des Unternehmens, genutzt werden.&nbsp;</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>5.4. Die Zugangsdaten und etwaige &uuml;berlassene Login-Daten d&uuml;rfen nicht an unbefugte Dritte weitergegeben werden und sind vor unbefugtem Zugriff angemessen zu sch&uuml;tzen. Es wird empfohlen, das Passwort in regelm&auml;&szlig;igen Abst&auml;nden zu &auml;ndern. F&uuml;r eine unbefugte Nutzung des Accounts haftet der Nutzer. Der Veranstalter &uuml;bernimmt keine Verantwortung f&uuml;r den Verlust oder Missbrauch von Zugangsdaten. Der Nutzer ist verpflichtet, den Veranstalter unverz&uuml;glich &uuml;ber eine missbr&auml;uchliche Verwendung zu informieren.</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>5.5 Inhalte, Angaben oder &Auml;u&szlig;erungen die der Nutzer auf der Plattform hinterlegt oder t&auml;tig (z.B. im Account, Chats, Videos, Whiteboards, etc.) d&uuml;rfen nicht gegen Gesetze, Schutzrechte von Dritten, beh&ouml;rdliche Auflagen, die guten Sitten, die &ouml;ffentliche Ordnung oder spezielle Vorschriften f&uuml;r bestimmte Berufe oder Produktgruppen versto&szlig;en und d&uuml;rfen insbesondere keine falschen oder irref&uuml;hrenden Informationen enthalten. Von Nutzern verlinkte Inhalte m&uuml;ssen insbesondere den in dieser Ziffer aufgef&uuml;hrten Anforderungen entsprechen.&nbsp;</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>5.6 Es ist untersagt, Hilfsmittel zum Data Mining, Robots oder &auml;hnliche Methoden zur Datensammlung oder -auswertung auf der Online-Plattform zu verwenden oder Inhalte zu aggregieren, zu kopieren oder zu vervielf&auml;ltigen.&nbsp;</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>5.7 Nutzer d&uuml;rfen auf der Plattform keine eigenen Tracking-Tools oder interaktive Elemente verwenden oder Daten von anderen Nutzern aggregieren, sammeln oder sonst wie verarbeiten.&nbsp;</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>5.8 Es d&uuml;rfen keine Inhalte ver&ouml;ffentlicht oder hinterlegt werden die zugangsbeschr&auml;nkt oder passwortgesch&uuml;tzt sind oder die versteckte Webseiten oder Bilder enthalten.</span></p>
<p><strong><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>6. Haftung und Nutzungsrechte an Inhalten; Freistellung</span></strong></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>6.1 Der Nutzer garantiert, dass von ihm eingestellte Inhalte nicht gegen Ziffer 5.5 dieser Nutzungsbedingungen versto&szlig;en und dass ihm alle erforderlichen Nutzungsrechte zur Verwendung der Inhalte in der jeweiligen Nutzungsart und zu dem vorgesehenen Zweck auf der Online-Plattform zustehen und dass er berechtigt ist, dem Veranstalter die Nutzung der Inhalte, soweit dies zum Zweck des Betriebs der Plattform erforderlich ist, einzur&auml;umen (vgl. insbesondere Ziffer 7).</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>6.3 Der Nutzer stellt den Veranstalter auf erstes Anfordern von jeglichen Anspr&uuml;chen frei, die Dritte aufgrund einer Verletzung des vorhergehenden Absatzes oder Ziffer 5.5 geltend machen und wird den Veranstalter angemessenen Kosten der Rechtsverteidigung erstatten.</span></p>
<p><strong><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>7. Rechte an Inhalten auf der Plattform</span></strong></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>An etwaigen von Nutzern auf der Plattform erstellten und bereitgestellten Inhalten r&auml;umt der Nutzer dem Veranstalter nur die zur Bereitstellung der Plattform erforderlichen Nutzungsrechte ein. Der Nutzer ist daf&uuml;r verantwortlich, dass er die erforderlichen Rechte an von ihm eingestellten und erstellten Inhalten hat. Ansonsten verbleiben die Rechte an Inhalten die der Nutzer auf der Plattform bereitstellt oder erstellt hat bei ihm. &nbsp;</span></p>
<p><strong><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>8. Pflichtverst&ouml;&szlig;e von Nutzern; Nutzungsbeschr&auml;nkungen</span></strong></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>Der Veranstalter beh&auml;lt sich das Recht vor, bei Verletzung der unter Ziffer 5, 6, 7 genannten oder sonstiger Pflichten aus dem Nutzungsverh&auml;ltnis angemessene Ma&szlig;nahmen gegen&uuml;ber dem Nutzer zu ergreifen, wie insbesondere Nutzer von der Nutzung der Plattform auszuschlie&szlig;en, den Nutzungsvertrag zu k&uuml;ndigen, die Nutzung zu beschr&auml;nken oder auszusetzen oder Inhalte zu sperren oder zu l&ouml;schen. Das Recht zur Geltendmachung weiterer Anspr&uuml;che bleibt unber&uuml;hrt.</span></p>
<p><strong><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>9. Haftungsausschluss; Gew&auml;hrleistung</span></strong></p>
<p><u><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>Haftung</span></u></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>9.1 Der Veranstalter haftet unbeschr&auml;nkt</span></p>
<ul style="list-style-type: undefined;">
    <li><span style='line-height:107%;font-family:"Arial",sans-serif;font-size:13px;'>bei Vorsatz oder grober Fahrl&auml;ssigkeit,</span></li>
    <li><span style='line-height:107%;font-family:"Arial",sans-serif;font-size:13px;'>f&uuml;r die Verletzung von Leben, Leib oder Gesundheit,</span></li>
    <li><span style='line-height:107%;font-family:"Arial",sans-serif;font-size:13px;'>nach den Vorschriften des Produkthaftungsgesetzes sowie</span></li>
    <li><span style='line-height:107%;font-family:"Arial",sans-serif;font-size:13px;'>im Umfang einer vom Anbieter &uuml;bernommenen Garantie.</span></li>
</ul>
<p style="margin-bottom:.0001pt;"><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>9.2 Bei leicht fahrl&auml;ssiger Verletzung einer Pflicht, die wesentlich f&uuml;r die Erreichung des Vertragszwecks ist (Kardinalpflicht), ist die Haftung des Lizenzgebers der H&ouml;he nach begrenzt auf den Schaden, der nach der Art des fraglichen Gesch&auml;fts vorhersehbar und typisch ist.</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>9.3 Die Haftung f&uuml;r anf&auml;ngliche M&auml;ngel, soweit nicht die Voraussetzungen der Abs. 1, 2 vorliegen, wir auch ausgeschlossen.&nbsp;</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>9.4 Die vorstehende Haftungsbeschr&auml;nkung gilt auch f&uuml;r die pers&ouml;nliche Haftung der Mitarbeiter, Vertreter und Organe des Lizenzgebers.</span></p>
<p><u><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>Verf&uuml;gbarkeit der Dienste</span></u></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>9.5 Der Veranstalter gew&auml;hrleistet grunds&auml;tzlich nicht die Verf&uuml;gbarkeit der Dienste, es sei denn es ist etwas anderes vereinbart</span><span style="font-size:11px;line-height:107%;"><a href="#_msocom_5" id="_anchor_5" language="JavaScript" name="_msoanchor_5">[SKW5]</a>&nbsp;</span><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>. F&uuml;r Ausf&auml;lle der Plattform oder der angebotenen Dienste wird die Haftung ausgeschlossen.&nbsp;</span></p>
<p><u><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>Haftung und Gew&auml;hrleistung f&uuml;r Inhalte&nbsp;</span></u></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>9.6 Der Veranstalter &uuml;berpr&uuml;ft oder kontrolliert keine Angaben oder Inhalte die auf der Online-Plattform von Nutzern bereitgestellt oder gemacht werden. Der Veranstalter schlie&szlig;t jegliche Gew&auml;hr f&uuml;r die Richtigkeit, Vollst&auml;ndigkeit und Aktualit&auml;t der Inhalte und Angaben sowie eine etwaige Funktionsf&auml;higkeit der Produkte und Dienstleistungen die auf der Plattform beworben oder angeboten werden aus.&nbsp;</span></p>
<p><strong><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>10. Beendigung der Nutzung; L&ouml;schung des Accounts</span></strong></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>10.1 Der Nutzer kann die Nutzung jederzeit einstellen und den Account jederzeit l&ouml;schen indem er eine E-Mail mit der Bitte zur L&ouml;schung schickt an: Support@WebEventStudios.com. Im Falle der L&ouml;schung des Accounts werden s&auml;mtliche Angaben und Inhalte vom Nutzer und die im Zusammenhang mit dem Account stehen, unwiderruflich und in angemessener Frist gel&ouml;scht.&nbsp;</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>10.2 Der Veranstalter wird die L&ouml;schung auch vornehmen, sobald der Zweck der &nbsp;Registrierung des Nutzers auf der Online-Plattform weggefallen ist, z.B. mit Ablauf einer angemessenen Frist nach einer einmaligen oder zeitlich begrenzten Veranstaltung.&nbsp;</span></p>
<p><strong><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>11. Anwendbares Recht; Gerichtsstand; Sonstiges</span></strong></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>11.1 Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung f&uuml;r Verbraucher bereit, die Sie unter diesem&nbsp;</span><a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE"><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>Link</span></a><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>&nbsp;oder hier:&nbsp;</span><a href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&lng=DE">https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE</a> <span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>finden. Der Veranstalter zieht es vor, Anliegen im direkten Austausch zu kl&auml;ren und nimmt daher nicht an Verbraucherschlichtungsverfahren teil.&nbsp;</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>11.2 Sofern der Nutzer Kaufmann i.S.d. Handelsgesetzbuchs ist, gilt als ausschlie&szlig;licher Gerichtsstand f&uuml;r alle Streitigkeiten zwischen den Parteien aus oder in Verbindung mit diesem Vertrag M&uuml;nchen.&nbsp;</span></p>
<p><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>11.3 Sofern eine Bestimmung dieser Nutzungsbedingungen unwirksam oder undurchf&uuml;hrbar ist, bleiben die &uuml;brigen Bestimmungen davon unber&uuml;hrt.</span></p>
<p><strong><span style='font-size:13px;line-height:107%;font-family:"Arial",sans-serif;'>Stand: Oktober 2020</span></strong></p>

</body>
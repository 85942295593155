import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GatewayService } from 'src/app/services/gateway.service';
import { Map } from '../../_models/map';
import { Voting } from '../../_models/voting';
import { MapService } from '../../_services/map.service';
import { VotingService } from '../../_services/voting.service';

@Component({
  selector: 'app-voting-view',
  templateUrl: './voting-view.component.html',
  styleUrls: ['./voting-view.component.css']
})
export class VotingViewComponent implements OnInit {

  votingId: number;
  voting: Voting;
  resultCount: number[];

  maps: Map[];
  selectedMap: Map = new Map();

  showResults: boolean = false;
  isVotingStarted: boolean  = false;
  isVotingEnded: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private votingService: VotingService,
    private gatewayService: GatewayService,
    private mapService: MapService
  ) { }

  ngOnInit(): void {
    this.votingId = +this.route.snapshot.paramMap.get('id');

    this.getVoting();
    this.getMaps();

    this.gatewayService.receiveVoting().subscribe((res: Voting) => {
      console.log(res);
    });
  }

  getVoting(): void {
    this.votingService.getVotingWithResults(this.votingId).subscribe(voting => {
      this.voting = voting;

      if (this.voting.startdate) {
        this.isVotingStarted = true;
        this.showResults = false;
      }

      if (this.voting.expiredate) {
        this.isVotingEnded = true;
        this.showResults = true;
      }
    });
  }

  getMaps(): void {
    this.mapService.getMaps().subscribe(maps =>
      this.maps = maps
    );
  }

  toggleResults(): void {
    this.showResults = !this.showResults;
  }

  reload(): void {
    this.getVoting();
    
  }

  async startVoting(): Promise<void> {
    const startMessage = {
      id: this.voting.id,
      startdate: new Date(),
      map: this.selectedMap
    };

    this.gatewayService.joinRoom(`map_${this.selectedMap.id}`);

    this.gatewayService.sendStartVoting(startMessage);
    this.isVotingStarted = true;
  }

  async endVoting(): Promise<void> {
    const endMessage = {
      id: this.voting.id,
      expiredate: new Date(),
      map: this.selectedMap
    };

    this.gatewayService.sendEndVoting(endMessage);
    this.isVotingEnded = true;
    this.reload();
    this.showResults = true;
  }
}

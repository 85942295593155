<app-cityhall-nav></app-cityhall-nav>
<!-- <app-cityhall-voting></app-cityhall-voting> -->
<app-agenda></app-agenda>
<app-profile-nav></app-profile-nav>
<app-interactions></app-interactions>
<app-chat></app-chat>
<body>
 <!-- <iframe id="livestream" src="https://www.welocal.world/cmms-embed/1989/" frameborder="0" allowfullscreen></iframe> -->
  <video id="video" width="100%" height="auto" controls>
    <source src="downloads/artofwebmeetings.m4v" type="video/mp4" />
  </video>


          


      



 


   


    <!--<button type="button" class="WhiteboardBtn" id="whiteboardToggle" data-toggle="modal" data-target=".bd-example-modal-lg">Whiteboard</button>-->

</body>

<div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">


        <div id="whiteboard">
          <iframe src="https://wbo.ophir.dev/boards/wesdemowhiteboardcityhalltestumgebungnichtfinal#0,0,1.0" style="border:0px #ffffff none;" name="whiteboardFrame" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" height="100%" width="100%" allowfullscreen>
          </iframe>
         </div>


    </div>
  </div>
</div>

<div id="applausAnimation">
  <img src="assets/images/minion.gif" />
</div>

<button class="toprightBtn" id="cityhallAgenda" (click)="timetableEinblenden()"><img src="assets/images/agenda-white.png" title="Programm Cityhall" /></button>

<div class="countdownAgenda" id="timetableNeu">
  <p id="closeCityhallAgenda" (click)="timetableEinblenden()">X</p>
  <h2>Nächste Tagesordnungspunkte:</h2>
  <ul>
    <li *ngFor="let termin of naechsteTermine">{{termin.startTime | date:'HH:mm'}} Uhr: {{termin.name}}</li>
  </ul>
  <div id="nextAgenda"></div>
</div>

<app-voting-frontend></app-voting-frontend>

<div class="videochatOverlay">
  <app-videochat-raw [roomId]="'roomtest'" class="videochatOverlay"></app-videochat-raw>
</div>

<app-slido></app-slido>
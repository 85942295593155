<!--<h2>Public Chat</h2>-->



<div id="nachrichten">
        <ul id="myList">
            <li *ngFor="let chatmessage of chatmessages" >
                <div *ngIf="chatmessage?.sender.id == userId" class="dummy speech-bubble-self"><italic>{{chatmessage?.created | date:'shortTime'}}: </italic><bold class="fett">{{chatmessage?.sender.firstName}} {{chatmessage?.sender.lastName}}: </bold>{{chatmessage?.content}}</div>
                <div *ngIf="chatmessage?.sender.id != userId" class="dummy speech-bubble"><italic>{{chatmessage?.created | date:'shortTime'}}: </italic><bold class="fett">{{chatmessage?.sender.firstName}} {{chatmessage?.sender.lastName}}: </bold>{{chatmessage?.content}}</div>
            </li>
        </ul>
        <div id="inputs">
            <div class="input-group mb-3">
                <input type="text" class="form-control" id="eingabefeld" placeholder="Nachricht..." aria-label="Nachricht..." aria-describedby="basic-addon2" #chatmessageContent>
                <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="button" (click)="sendMessage(chatmessageContent.value); chatmessageContent.value=''">Senden</button>
                </div>
            </div>
        </div>

        
    </div>




  <!-- Nachrichten -->
  <div class="modal fade" id="notifications" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Benachrichtigungen</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div id="inhaltDiv"></div>
          <ul id="myList">
            <li *ngFor="let chatmessage of chatmessages" >
                <div class="dummy"><italic>{{chatmessage?.created | date:'shortTime'}}: </italic>Sie haben eine neue Chatnachricht erhalten.</div>
            </li>
        </ul>
        </div>
      </div>
    </div>
  </div>


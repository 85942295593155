
<app-navigation></app-navigation>

<body>
  <div id="startupMedia"></div>
<div id="map"></div>

<a href="{{firma?.link}}" target="blank" class="companyLogoTop" *ngIf="firma?.icon?.url" title="{{firma?.name}}"><img src="{{firma?.icon?.url}}" /></a>


<div class="row" id="filter" style="z-index: 10000;">
    <div class="col">
      <div class="dropdown keep-open" id="myDropdown">
          <a href="#" class="dropdown-toggle btn btn-primary" id="filtermap" data-toggle="dropdown" data-container="body" data-placement="bottom" title="Filtern und suchen" (click)="openNav()">Ansicht verfeinern + Suchen</a>
          <div class="dropdown-menu keep-open" id="myDropdown">
              <a class="dropdown-item unselectable" (click)="filterAnwenden('alle')" title="Alle Gebäude anzeigen">
               Alle anzeigen</a>
              <div class="dropdown-divider"></div>
              <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Suche..." aria-label="Suche..." [(ngModel)]="term2" (input)="ergebnisseAnzeigen(term2)">
                
              </div>
              
              <ul id="suchErgebnisse">
                <li *ngFor="let firmen of firma?.mapChilds |filter : term2"><a href="center/{{firmen?.childMap.id}}"> {{firmen.childMap.name}}</a></li>
              </ul>
              <div class="dropdown-divider"></div><form>
              <a class="dropdown-item" *ngFor ="let filter of branches">
                

                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id={{filter.id}} (click)="filterAnwenden(filter.id)">
                  <label class="custom-control-label unselectable" for={{filter.id}}>{{filter.name}}</label>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <button class="btn btn-secondary btn-sm" role="button" id="anwenden" style="margin-left: 1.5em;" (click)="refresh()" title="Hier klicken, um die ausgewählten Filter anzuwenden. Nur so werden die Filter angewendet.">Fiter anwenden</button>
              </form>
            </div>
            <!-- Basic dropdown -->
      </div>  
    </div>
</div>







<!-- Hinweis Karte -->
<div class="modal fade" id="hinweisKarte" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Willkommen, {{nameDesUsers}}!</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h2 id="namensFeld">Hinweise zur Karte</h2>
        <ul>
          <li>Sie können sich auf der Karte bewegen, indem Sie auf eine leere Fläche klicken und gleichzeitig Ihre Maus bewegen.</li>
          <li>Zoomen ist entweder mit dem Mausrad oder mit den Symbolen in der linken oberen Ecke möglich.</li>
          <li>Sie können nur Gebäude einer bestimmten Branche einblenden. Klicken Sie dazu auf "Ansicht verfeinern" in der linken oberen Ecke.</li>
          <li>Auch Suchen ist im Menü "Ansicht verfeinern" möglich.</li>
          <li>Auch in den Partnergebäuden können Sie sich bewegen.</li>
          <li>Um in ein Gebäude zu gelangen müssen Sie nur auf das jeweilige Logo klicken.
          </li>
        </ul>
        <div style="text-align: center;"><img src="assets/images/demomaus.gif" style="max-width: 300px;" /></div>
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" data-dismiss="modal">Verstanden!</button>
      </div>
    </div>
  </div>
</div>
    
  </body>

import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/services/chat.service';
import { Chatmessage } from '../../_models/chatmessage';
import { User } from '../../_models/user';

@Component({
  selector: 'app-chat-view-mod',
  templateUrl: './chat-view-mod.component.html',
  styleUrls: ['./chat-view-mod.component.css']
})
export class ChatViewModComponent implements OnInit {

  userId: number;
  user: User;
  chatmessages: Chatmessage[] = new Array();

  selectedModeratorChatroom: number;

  constructor(
    private chatService: ChatService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.currentUserValue;
    this.getChatmessages();
  }

  getChatmessages(): void {
    this.chatService.receiveChatmessage().subscribe((chatmessage: Chatmessage) => {
      this.chatmessages.push(chatmessage);
      localStorage.setItem('nachricht'+chatmessage.chatroom.id, 'ungelesen');
    });
  }

}

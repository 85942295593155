import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';

import { Map } from '../_models/map';
import { MapToMap } from '../_models/map-to-map';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  url = `${environment.apiUrl}/maps`;

  constructor(
    private http: HttpClient
  ) { }

  getMaps(): Observable<Map[]> {
    return this.http.get<Map[]>(this.url)
      .pipe(
        tap(_ => console.log('fetched maps')),
        catchError(this.handleError<Map[]>('getMaps, []'))
      );
  }

  getMapsByAdminUser(): Observable<Map[]> {
    return this.http.get<Map[]>(`${this.url}/admin`)
      .pipe(
        tap(_ => console.log('fetched maps from admin user')),
        catchError(this.handleError<Map[]>('getMapsByAdminUser, []'))
      );
  }

  getMap(id: number): Observable<Map>{
    return this.http.get<Map>(`${this.url}/${id}`)
      .pipe(
        tap(_ => console.log(`fetched map id=${id}`)),
        catchError(this.handleError<Map>('getMap, []'))
      );
  }

  updateMap(map: Map): Observable<any>{
    return this.http.put(`${this.url}/${map.id}`, map)
      .pipe(
        tap(_ => console.log('updated map id=${map.id}')),
        catchError(this.handleError<any>('updateMap'))
      );
  }

  createMap(map: Map): Observable<Map>{
    return this.http.post(this.url, map)
      .pipe(
        tap(_ => console.log('created map id=${map.id}')),
        catchError(this.handleError<any>('createMap'))
      );
  }

  // MAP TO MAP
  createMapToMap(mapToMap: MapToMap): Observable<MapToMap>{
    return this.http.post(`${this.url}/mapToMap`, mapToMap)
      .pipe(
        tap(_ => console.log(`created mapToMap id=${mapToMap.id}`)),
        catchError(this.handleError<any>('createMapToMap'))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MapService } from '../../_services/map.service';
import { Map } from '../../_models/map';
import {MapType} from '../../_models/map';
import { MediaPosition } from '../../_models/media-position';
declare var $;

@Component({
  selector: 'app-map-create',
  templateUrl: './map-create.component.html',
  styleUrls: ['./map-create.component.css']
})
export class MapCreateComponent implements OnInit {

  map: Map;
  id: number;
  types = MapType;
  keys = Object.keys;
  alertclass = 'alert-danger';
  alertmessage = 'Test';
  alertshow = false;

  newMediaPosition: MediaPosition;
  selectedMediaPosition: MediaPosition;

  constructor(
    private route: ActivatedRoute,
    private mapService: MapService
  ) { }

  ngOnInit(): void {
    this.id = +this.route.snapshot.paramMap.get('id');

    this.map = new Map();
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforClose($event): void {
    $event.returnValue = 'Haben Sie gespeichert?';
  }

  reset($event): void {
    this.map = new Map();
  }

  openMediaPositionCreateDialog($event): void {
    this.newMediaPosition = $event;
    $('#mapPositionCreateModal').modal('show');
  }

  openMediaPositionEditDialog($event): void {
    this.selectedMediaPosition = $event;
    $('#mapPositionEditModal').modal('show');
  }

  saveMediaPosition($event): void {
    this.map.mediapositions.push($event);
    this.save();
    $('#mapPositionCreateModal').modal('hide');
  }

  updateMediaPosition($event): void {
    const positionId = this.map.mediapositions.indexOf($event.id);
    this.map.mediapositions[positionId] = $event;
    this.save();
    $('#mapPositionEditModal').modal('hide');
  }

  removeMediaPosition($event): void {
    this.map.mediapositions = this.map.mediapositions.filter(mediaposition => mediaposition.id !== $event.id);
    this.save();
    $('#mapPositionEditModal').modal('hide');
  }

  updateMapDetails($event): void {
    this.map.background = $event.background;
    this.map.name = $event.name;
    this.map.description = $event.description;

    this.save();
    $('#mapDetailEditModal').modal('hide');
  }

  save(): void {
    this.mapService.createMap(this.map)
      .subscribe();
  }
}

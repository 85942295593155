
<app-navigation></app-navigation>
<app-agenda></app-agenda>
<app-profile-nav></app-profile-nav>
<body>
<div id="map"></div>


<div class="row" id="filter">
    <div class="col">
      <div class="dropdown keep-open" id="myDropdown">
          <a href="#" class="dropdown-toggle btn btn-primary" id="filtermap" data-toggle="dropdown" data-container="body" data-placement="bottom" data-content="Filtern Sie Ihre Ergebnisse">Ansicht verfeinern</a>
          <div class="dropdown-menu keep-open" id="myDropdown">
              <a class="dropdown-item unselectable" (click)="filterAnwenden('alle')">
               Alle anzeigen</a>
              <div class="dropdown-divider"></div>
              <div class="input-group mb-3">
                <input type="text" class="form-control" placeholder="Suche..." aria-label="Suche..." [(ngModel)]="term2" (input)="ergebnisseAnzeigen(term2)">
                
              </div>
              
              <ul id="suchErgebnisse">
                <li *ngFor="let firmen of firma.mapChilds |filter:term2"><a href="map/{{firmen.childMap.id}}"> {{firmen.childMap.name}}</a></li>
              </ul>
              <div class="dropdown-divider"></div><form>
              <a class="dropdown-item" *ngFor ="let filter of branches">
                

                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id={{filter.id}} (click)="filterAnwenden(filter.id)" checked>
                  <label class="custom-control-label unselectable" for={{filter.id}}>{{filter.name}}</label>
                </div>
              </a>
              <div class="dropdown-divider"></div>
              <button class="btn btn-secondary btn-sm" role="button" id="anwenden" style="margin-left: 1.5em;" (click)="refresh()">Fiter anwenden</button>
              </form>
            </div>
            <!-- Basic dropdown -->
      </div>  
    </div>
</div>



<!-- Modals für Mediendarstellung erstellen -->
<div class="modals" *ngFor ="let media of firma.media">
  <div *ngIf="media.media.mediatype.id ===1">
    <div class="modal fade {{media.media.id}}" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">IMAGE</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <img class="modalImage" src="{{media.media.url}}" style="max-width: 100%;"/>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Schließen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="media.media.mediatype.id ===2">
    <div class="modal fade {{media.media.id}}" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">DOCUMENT</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          
          <iframe [src]="getSource(media.media.url)" style="height: 80vh; width: 100%;"></iframe>
          <div class="modal-footer">
            <a href="{{media.media.url}}" target="blank"><button type="button" class="btn btn-secondary">Dokument extern öffnen</button></a>
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Schließen</button>
          </div>
        </div>
      </div>
    </div>
  </div>

<div *ngIf="media.media.mediatype.id ===3">
  <div class="modal fade {{media.media.id}}" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">VIDEOCHAT</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <h2><a href="videochat/{{media.media.id}}">Videochat beitreten</a></h2>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Schließen</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="media.media.mediatype.id ===4">
  <div class="modal fade {{media.media.id}}" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">MOVIE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <video id="{{media.media.id}}" src="{{media.media.url}}" style="max-width: 100%; max-height: 100%;" controls></video>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="videoStoppen(media.media.id)">Schließen</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="media.media.mediatype.id ===5">
  <div class="modal fade {{media.media.id}}" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">MOVIE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <slideshow [imageUrls]="[assets/images/conference.jpg, assets/images/demo.jpg, assets/images/eauto.png]" style="height: 800px;"></slideshow>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Schließen</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="media.media.mediatype.id ===6">
  <div class="modal fade {{media.media.id}}" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">MOVIE</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <iframe src="https://www.youtube.com/embed/wjk7eLoIC-Y?version=3&enablejsapi=1" id="iframeid" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen style="min-width: 100%; min-height: 60vh;"></iframe>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="ytStop">Schließen</button>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
    
  </body>
import { Map } from './map';
import { Profile } from './profile';
import { Group } from './group';
import { Chatroom } from './chatroom';
import { Role } from './role';
import { Company } from './company';

export class User {
    id: number;
    firstName: string;
    lastName: string;
    eMail: string;
    passwordHash: string;
    profile: Profile;
    currentLocation: Map;
    groups: Group[];
    chatrooms: Chatroom[];
    company: Company;
    role: Role;
    token: string;
}

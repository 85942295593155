import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { User } from '../../_models/user';
import { Voting } from '../../_models/voting';
import { VotingService } from '../../_services/voting.service';

@Component({
  selector: 'app-voting-create',
  templateUrl: './voting-create.component.html',
  styleUrls: ['./voting-create.component.css']
})
export class VotingCreateComponent implements OnInit {

  voting: Voting = new Voting();

  constructor(
    private votingService: VotingService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  save(): void {
    this.voting.creator = new User();
    this.voting.creator.id = this.authService.currentUserValue.id;

    this.votingService.createVoting(this.voting).subscribe(voting =>
      this.router.navigate([`/admin/voting/edit/${voting.id}`])
    );
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Company } from '../_models/company';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  url = `${environment.apiUrl}/companies`;

  constructor(
    private http: HttpClient
  ) { }

  getCompanies(): Observable<Company[]> {
    return this.http.get<Company[]>(this.url)
      .pipe(
        tap(_ => console.log('fetched companies')),
        catchError(this.handleError<Company[]>('getCompanies, []'))
      );
  }

  getCopmany(id: number): Observable<Company>{
    return this.http.get<Company>(`${this.url}/${id}`)
      .pipe(
        tap(_ => console.log(`fetched company id=${id}`)),
        catchError(this.handleError<Company>('getCompany, []'))
      );
  }

  updateCompany(company: Company): Observable<any>{
    return this.http.put(`${this.url}/${company.id}`, company)
      .pipe(
        tap(_ => console.log('updated company id=${company.id}')),
        catchError(this.handleError<any>('updateCompany'))
      );
  }

  createCompany(company: Company): Observable<Company>{
    return this.http.post(this.url, company)
      .pipe(
        tap(_ => console.log('created company id=${company.id}')),
        catchError(this.handleError<any>('createCompany'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

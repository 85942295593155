import { Component, OnInit, AfterViewInit, OnChanges } from '@angular/core';

@Component({
  selector: 'app-map-preview',
  templateUrl: './map-preview.component.html',
  styleUrls: ['./map-preview.component.sass']
})
export class MapPreviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

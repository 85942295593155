import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Chatmessage } from '../models/chatmessage';
import { Chatroom } from '../models/chatroom';
import {DatabaseService} from './database.service';
import { GatewayService } from './gateway.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private socket: Socket, private databaseService: DatabaseService, private gatewayService: GatewayService, private http: HttpClient, private dbUrl: DatabaseService) { }
  private mapUrl = this.dbUrl.returnDatabaseUrl()+'chatrooms/personal';  // URL to web api
  private mapUrl2 = this.dbUrl.returnDatabaseUrl()+'chatmessages/byChatroom/';
  receiveUserid(){
    return this.socket.fromEvent('user_id');
  }

  receiveChatmessage(){
    return this.socket.fromEvent('chatmessage');
    
  }

  sendChatmessage(chatmessage: Chatmessage) {
    this.gatewayService.sendChatmessage(chatmessage);
  }

  connect() {
    this.gatewayService.connect();
  }


  onRoomJoin(){
    return this.socket.fromEvent('join');
  }

  getChatMessages(id){
    return this.http.get(this.mapUrl2 + id.toString())
    .pipe(
        catchError(this.handleError)
      );
  }
  
  

  receivePublicmessage() {
    return this.socket.fromEvent('publicmessage');
  }

  sendPublicmessage(content) {
    this.gatewayService.sendPublicmessage(content);
  }

  joinRoom(roomId){
    this.gatewayService.joinRoom(roomId);
  }

  testton(url):void{
    new Audio(url).play();
  }

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  createChatroom(userIds: any): Observable<Chatroom> {
    console.log(userIds);
    const headers = { "accept": "application/json", "Content-Type": "application/json" }; 
    const body = {"users": userIds} ;
    console.log(body);
    return this.http.post<any>(this.dbUrl.returnDatabaseUrl()+'chatrooms', body, { headers })
    .pipe(
      tap(_ => console.log('created chatroom id=${chatroom.id}'))
    );
  }

  

  /** GET maps from the server */
  getChatrooms() {
    return this.http.get(this.mapUrl)
    .pipe(
        catchError(this.handleError)
      );
     /* .pipe(
        tap(_ => this.log('fetched maps')),
        catchError(this.handleError<Map[]>('getMaps', []))
      );*/
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}

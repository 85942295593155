import { Component, OnInit } from '@angular/core';
import { InteractionsService } from '../services/interactions.service';
@Component({
  selector: 'app-interactions',
  templateUrl: './interactions.component.html',
  styleUrls: ['./interactions.component.css']
})
export class InteractionsComponent implements OnInit {
  public interactions = new Array();
  public users: number = 0;
  constructor(private interactionService: InteractionsService) { }
  ngOnInit(): void {
    this.interactionService.receiveUsers().subscribe((users: number) => {
      this.users = users;
    });
    this.interactionService.receiveInteraction().subscribe((reaction: string) => {
      this.showInteraction(reaction);
    })
  }
  addInteraction(reaction: string) {
    this.interactionService.sendInteraction(reaction);
  }
  showInteraction(reaction: string){
    //do something with the interaction events here
    
    if (reaction == 'applause') {
      this.testton('assets/sounds/applaus1.wav');
      this.interactions.push('assets/images/minion.gif');
    }else if (reaction == 'cheer') {
      this.testton('assets/sounds/thumbsup.mp3');
      this.interactions.push('assets/images/cheer.gif');
    }else if (reaction == 'like') {
      this.testton('assets/sounds/meldung.mp3');
      this.interactions.push('assets/images/like.gif');
    }
  }

  testton(url):void{
    var audio = new Audio(url);
    audio.volume = Number(localStorage.getItem('generalVolume'))/100;
    audio.play();
  }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Agenda } from '../_models/agenda';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

  url = `${environment.apiUrl}/agenda`;

  constructor(
    private http: HttpClient
  ) { }

  getAgendas(): Observable<Agenda[]> {
    return this.http.get<Agenda[]>(this.url)
      .pipe(
        tap(_ => console.log('fetched agendas')),
        catchError(this.handleError<Agenda[]>('getAgendas, []'))
      );
  }
  
  getAgenda(id: number): Observable<Agenda>{
    return this.http.get<Agenda>(`${this.url}/${id}`)
      .pipe(
        tap(_ => console.log(`fetched agenda id=${id}`)),
        catchError(this.handleError<Agenda>('getAgenda, []'))
      );
  }

  updateAgenda(agenda: Agenda): Observable<any>{
    return this.http.put(`${this.url}/${agenda.id}`, agenda)
      .pipe(
        tap(_ => console.log('updated agenda id=${agenda.id}')),
        catchError(this.handleError<any>('updateAgenda'))
      );
  }

  createAgenda(agenda: Agenda): Observable<Agenda>{
    return this.http.post(this.url, agenda)
      .pipe(
        tap(_ => console.log('created agenda id=${agenda.id}')),
        catchError(this.handleError<any>('createAgenda'))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

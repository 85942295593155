import { Component, OnInit } from '@angular/core';
import {TeleporterService} from '../services/teleporter.service';
declare var $;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  

  constructor(
    private teleporterService: TeleporterService
  ) { }

  testton(url):void{
    new Audio(url).play();
  }

 helper(): void{
  document.getElementById("mail").style.transform = "scale(1)";
  document.getElementById("tel").style.transform = "scale(1)";
  document.getElementById("chat").style.transform = "scale(1)";
  document.getElementById("helperaus").style.display = "block";
 }

 helper_off():void{
  document.getElementById("mail").style.transform = "scale(0)";
  document.getElementById("tel").style.transform = "scale(0)";
  document.getElementById("chat").style.transform = "scale(0)";
  document.getElementById("helperaus").style.display = "none";
  this.telefonaus();
 }

 telefon():void{
  document.getElementById("telefon2").style.bottom = "0em";
  this.helper();
 }

 telefonaus():void{
  document.getElementById("telefon2").style.bottom = "-20em";
 }

 openNav(): void{
  document.getElementById("mySidenav").style.width = "320px";
  document.getElementById("mySidenav").style.right = "0px";
  this.testton('assets/sounds/slide.mp3');
 }

 closeNav(): void{
  document.getElementById("mySidenav").style.right = "-500px";
}


  

  ngOnInit(): void {
    if(localStorage.getItem('mapTooltip') == '1'){
      setTimeout(function(){
        //$('#mapButtonPopover').addEventListener("mouseover", $('#mapButtonPopover').tooltip('toggle'));
        $('#mapButtonPopover').tooltip('enable');
      }, 1000);
      
    }else{
      $('#mapButtonPopover').tooltip('show');
      localStorage.setItem('mapTooltip', '1');
    }
    $('tr').tooltip('enable');
    $('button').tooltip('enable');
    $('img').tooltip('enable');
    $('input').tooltip('enable');
    $('a').tooltip('enable');
  }


}

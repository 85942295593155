import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MapService } from '../../_services/map.service';
import { Agenda } from '../../_models/agenda';
import { Map } from '../../_models/map';
import { AgendaService } from '../../_services/agenda.service';

@Component({
  selector: 'app-agenda-edit',
  templateUrl: './agenda-edit.component.html',
  styleUrls: ['./agenda-edit.component.css']
})
export class AgendaEditComponent implements OnInit {

  agendaId: number;
  agenda: Agenda;
  maps: Map[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private agendaService: AgendaService,
    private mapService: MapService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.agendaId = +this.route.snapshot.paramMap.get('id');
    console.log(this.agendaId);

    await this.getMaps();
    await this.getAgenda();
  }

  async getAgenda(): Promise<void> {
    this.agendaService.getAgenda(this.agendaId).subscribe(agenda => {
      this.agenda = agenda;
      if (!this.agenda.location) {
        this.agenda.location = new Map();
      }
    });
  }

  async getMaps(): Promise<void> {
    this.mapService.getMaps().subscribe(maps =>
      this.maps = maps
    );
  }

  save(): void {
    this.agendaService.updateAgenda(this.agenda).subscribe(() =>
      this.router.navigate(['/admin/agenda'])
    );
  }
}

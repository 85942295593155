import { Component, OnInit } from '@angular/core';
import { NgxCsvParser } from 'ngx-csv-parser';
import { Agenda } from '../../_models/agenda';
import { Map } from '../../_models/map';
import { AgendaService } from '../../_services/agenda.service';

@Component({
  selector: 'app-agenda-create-batch',
  templateUrl: './agenda-create-batch.component.html',
  styleUrls: ['./agenda-create-batch.component.css']
})
export class AgendaCreateBatchComponent implements OnInit {

  agendaEntries: Agenda[];

  constructor(
    private agendaService: AgendaService,
    private ngxCsvParser: NgxCsvParser 
  ) { }

  ngOnInit(): void {
  }

  handleFileInput(files: FileList): void {
    const file = files.item(0);

    this.ngxCsvParser.parse(file, { header: true, delimiter: ';'}).pipe().subscribe( (result: Array<Agenda>) => {
      this.agendaEntries = result;
      console.log(result);
    });
  }

  createAgendaEntries(agendaEntries: Agenda[]) {
    agendaEntries.forEach((agenda: any) => {
      const location = new Map();
      location.id = agenda.location
      agenda.location = location;

      this.agendaService.createAgenda(agenda).subscribe( agenda =>
        console.log(agenda)
      );
    });
  }

}

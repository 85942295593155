import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Role } from '../_models/role';

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  url = `${environment.apiUrl}/roles`;

  constructor(
    private http: HttpClient
  ) { }

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.url)
      .pipe(
        tap(_ => console.log('fetched Roles')),
        catchError(this.handleError<Role[]>('getRoles, []'))
      );
  }

  getCopmany(id: number): Observable<Role>{
    return this.http.get<Role>(`${this.url}/${id}`)
      .pipe(
        tap(_ => console.log(`fetched Role id=${id}`)),
        catchError(this.handleError<Role>('getRole, []'))
      );
  }

  updateRole(role: Role): Observable<any>{
    return this.http.put(`${this.url}/${role.id}`, Role)
      .pipe(
        tap(_ => console.log('updated role id=${role.id}')),
        catchError(this.handleError<any>('updateRole'))
      );
  }

  createRole(role: Role): Observable<Role>{
    return this.http.post(this.url, role)
      .pipe(
        tap(_ => console.log('created role id=${role.id}')),
        catchError(this.handleError<any>('createRole'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

import { Media } from './media';
import { Branche } from './branche';
import { User } from './user';

export class Company {
    id: number;
    name: string;
    alias: string;
    description: string;
    logo: Media;
    branches: Branche[];
    chatContact: User;
}

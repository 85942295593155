import { Component, OnInit } from '@angular/core';
import { Chatroom } from '../../_models/chatroom';
import{ChatroomService } from '../../_services/chatroom.service';

@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.css']
})
export class ChatListComponent implements OnInit {

  chatrooms: Chatroom[];

  constructor(
    private chatroomService: ChatroomService
  ) { }

  ngOnInit(): void {
    this.getChatrooms();
  }

  getChatrooms() {
    this.chatroomService.getChatrooms().subscribe(chatrooms => {
      this.chatrooms = chatrooms
    });
  }
}

<h3>Medien</h3>

<p><button class="btn btn-primary" routerLink="/admin/media/create">Neu erstellen</button></p>

<table class="table table-hover">
    <thead>
        <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Beschreibung</th>
            <th scope="col">Medientyp</th>
            <th scope="col">Mediacenter</th>
            <th scope="col">Featured</th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let media of medias" routerLink="/admin/media/edit/{{media.id}}">
            <th scope="row">{{media.id}}</th>
            <td>{{media.name}}</td>
            <td>{{media.description}}</td>
            <td>{{media.mediatype.name}}</td>
            <td>{{media.mediacenter}}</td>
            <td>{{media.Featured}}</td>
        </tr>
    </tbody>
</table>
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Agenda } from '../models/agenda';
import {DatabaseService} from './database.service';

@Injectable({ providedIn: 'root' })
export class AgendaService {
  postId: any;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient, private dbUrl: DatabaseService) {}
  private mapUrl = this.dbUrl.returnDatabaseUrl()+'agenda';  // URL to web api
  /** GET maps from the server */
  getAgenda() {
    return this.http.get(this.mapUrl + '?filter=future')
    .pipe(
        catchError(this.handleError)
      );
     /* .pipe(
        tap(_ => this.log('fetched maps')),
        catchError(this.handleError<Map[]>('getMaps', []))
      );*/
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }


  getEvent(id): Observable<Agenda> {
    return this.http.get<Agenda>(this.mapUrl+'/'+id)
    .pipe(
        catchError(this.handleError)
      );
     /* .pipe(
        tap(_ => this.log('fetched maps')),
        catchError(this.handleError<Map[]>('getMaps', []))
      );*/
  }

  getPersonalAgenda(userId){
    return this.http.get<Agenda>(this.mapUrl+'/personal/'+userId)
    .pipe(
        catchError(this.handleError)
      );
  }

  addUserToAgenda(id, userId) {
      const headers = { "accept": "application/json", "Content-Type": "application/json" }; 
      const body = {} ;
      this.http.put<any>(this.mapUrl+'/'+id+​'/addUser' +'/' + userId.toString(), body, { headers }).subscribe(data => {
          //this.postId = data.id;
      })
  }


  removeUserFromAgenda(id, userId) {
    const headers = { "accept": "application/json", "Content-Type": "application/json" }; 
    const body = {} ;
    this.http.delete<any>(this.mapUrl+'/'+id+​'/removeUser' +'/' + userId.toString(), { headers }).subscribe(data => {
        //this.postId = data.id;
    })
}
}



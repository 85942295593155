

    <video id="video" src="{{media.url}}" type="video/mp4" (play)="skipper()" (ended)="zurKarte()" autoplay></video>

  <div class="btn-group" id="starter" role="group" aria-label="Basic example">
    <button type="button" class="btn btn-primary" (click)="startevideo()">EINFÜHRUNG ANSEHEN</button>
  </div>

  <div class="skip" id="skip"><button class="btn btn-primary" (click)="skip()">ÜBERSPRINGEN...</button></div>
  <div id="bauchbinde"><h2>Joachim Wiemers</h2><p>Eventcoach</p></div>

<app-navigation></app-navigation>



import { Component, OnInit, AfterViewInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import * as Leaflet from 'leaflet';
import { __values } from 'tslib';
import { Map } from '../../_models/map';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../_services/user.service';
import { MapService } from '../../_services/map.service';
import { MediaPosition } from '../../_models/media-position';
import { Media } from '../../_models/media';
declare var $;

@Component({
  selector: 'app-map-interactive-edit',
  templateUrl: './map-interactive-edit.component.html',
  styleUrls: ['./map-interactive-edit.component.css']
})
export class MapInteractiveEditComponent implements OnInit, OnChanges {

  @Input() mapInput: Map;
  @Output() newMediaPositionEvent = new EventEmitter<MediaPosition>();
  @Output() updateMediaPositionEvent = new EventEmitter<MediaPosition>();

  title = 'leafletApps';
  map: Leaflet.Map;
  mArray: any [] = [];
  pArray: any [] = [];
  markersArray: any [] = [];

  constructor(
    private route: ActivatedRoute,
    private mapService: MapService,
    private usersService: UserService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void{
    console.log(this.mapInput);
    this.resetMap();
    this.map.on('click', event => this.addMediaPosition(event));
   }

  resetMap(): void {
    if (this.map){
      this.map.off();
      this.map.remove();
      this.mArray = [];
      this.pArray = [];
      this.markersArray = [];
    }
    this.generateMap(this.mapInput);
  }

  // Map erstellen
  generateMap(mapJson): void {

    const h = mapJson.backgroundHeight;
    const w = mapJson.backgroundWidth;
    this.map = Leaflet.map('map', {
      crs: Leaflet.CRS.Simple,
      center: [mapJson.midX, mapJson.midY],
      minZoom: mapJson.zoomMin, maxZoom: mapJson.zoomMax, zoomSnap: 0.25
    }).setView([mapJson.midX, mapJson.midY], mapJson.zoomDefault);

    const bounds: Leaflet.LatLngBoundsExpression = [[0, 0], [h, w]];
    const image = Leaflet.imageOverlay(mapJson.background.url, bounds).addTo(this.map);
    this.map.setMaxBounds(bounds);


    // define markers for media objects
    const mediaMarker = Leaflet.icon({
      iconUrl: '/assets/images/transparent.png',
      iconSize: [50, 50],
      iconAnchor: [1, 1],
      });

    // generate markers and add to map
    this.mapInput.mediapositions.forEach(mediaposition => {
      Leaflet.marker([mediaposition.positionX, mediaposition.positionY], {
        icon: mediaMarker,
      })
      .on('click', event => {
        this.updateMediaPosition(mediaposition);
      })
      .bindPopup(this.createPopup(mediaposition))
      .addTo(this.map)
      .openPopup();
    });

    this.createEventListeners(this.mapInput.mediapositions);
  }

  createPopup(mediaposition: MediaPosition): Leaflet.Popup {
    return Leaflet.popup({
      autoClose: false,
      closeOnClick: false,
      autoPan: false,
      className: 'media'
    })
    .setContent(`
    <a id="popupNr${mediaposition.id}" title="Medien-Position bearbeiten">
      <button  class="bild" (click)="updateMediaPosition(${mediaposition.id})">
        <img src="${mediaposition.icon.url}" style="max-width: 3em; max-height: 3em;" />
      </button>
      <br>
      <button class="partnerMediaBeschreibung" (click)="updateMediaPosition(${mediaposition.id})">
        ${mediaposition.title}
      </button>'
    </a>
    `);
  }

  createEventListeners(mediapositions: MediaPosition[]): void {
    mediapositions.forEach(mediaposition => {
      $(`#popupNr${mediaposition.id}`).click(() => this.updateMediaPosition(mediaposition));
    });
  }

  addMediaPosition(event): void {
    const newMediaPosition: MediaPosition = new MediaPosition();
    newMediaPosition.positionX = parseInt(event.latlng.lat, 10);
    newMediaPosition.positionY = parseInt(event.latlng.lng, 10);

    // muss noch raus
    newMediaPosition.icon = new Media();
    newMediaPosition.icon.id = 6;

    this.newMediaPositionEvent.emit(newMediaPosition);
  }

  updateMediaPosition(event): void {
    this.updateMediaPositionEvent.emit(event);
  }
}

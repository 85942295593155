import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Media } from '../_models/media';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  url = `${environment.apiUrl}/media`;

  constructor(
    private http: HttpClient
  ) { }

  getMedias(): Observable<Media[]> {
    return this.http.get<Media[]>(this.url)
      .pipe(
        tap(_ => console.log('fetched media')),
        catchError(this.handleError<Media[]>('getMedia, []'))
      );
  }

  getMediasByMediatype(mediatpyeId: number): Observable<Media[]> {
    return this.http.get<Media[]>(`${this.url}/mediatype/${mediatpyeId}`)
      .pipe(
        tap(_ => console.log('fetched media')),
        catchError(this.handleError<Media[]>('getMedia, []'))
      );
  }

  getMedia(id: number): Observable<Media>{
    return this.http.get<Media>(`${this.url}/${id}`)
      .pipe(
        tap(_ => console.log(`fetched media id=${id}`)),
        catchError(this.handleError<Media>('getMedia, []'))
      );
  }

  updateMedia(media: Media): Observable<any>{
    return this.http.put(`${this.url}/${media.id}`, media)
      .pipe(
        tap(_ => console.log('updated media id=${media.id}')),
        catchError(this.handleError<any>('updateMedia'))
      );
  }

  createMedia(media: Media): Observable<Media>{
    return this.http.post(this.url, media)
      .pipe(
        tap(_ => console.log('created media id=${media.id}')),
        catchError(this.handleError<any>('createMedia'))
      );
  }

  uploadMedia(fileToUpload: File): Observable<any>{
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.http.post(this.url + '/upload', formData)
      .pipe(
        tap(_ => console.log(`uploaded file name=${fileToUpload.name}`)),
        catchError(this.handleError<any>('createMedia'))
      );
  }


  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

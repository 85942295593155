import { Component, OnInit } from '@angular/core';
import { RouterLinkWithHref } from '@angular/router';
import { NgxCsvParser } from 'ngx-csv-parser';
import { Company } from '../../_models/company';
import { Role } from '../../_models/role';
import { User } from '../../_models/user';
import { UserService } from '../../_services/user.service';

@Component({
  selector: 'app-user-create-batch',
  templateUrl: './user-create-batch.component.html',
  styleUrls: ['./user-create-batch.component.css']
})
export class UserCreateBatchComponent implements OnInit {

  users: User[];

  constructor(
    private userService: UserService,
    private ngxCsvParser: NgxCsvParser
  ) {}

  ngOnInit(): void {
  }

  handleFileInput(files: FileList): void {
    const file = files.item(0);

    this.ngxCsvParser.parse(file, { header: true, delimiter: ';'}).pipe().subscribe( (result: Array<User>) => {
      this.users = result;
      console.log(result);
    });
  }

  createUsers(users: User[]) {
    users.forEach((user: any) => {
      const role = new Role();
      role.id = user.role;
      user.role = role;

      const company = new Company();
      company.id = user.company;
      user.company = company;

      this.userService.createUser(user).subscribe( user =>
        console.log(user)
      );
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Media } from '../../_models/media';
import { MediaService } from '../../_services/media.service';

@Component({
  selector: 'app-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.sass']
})
export class MediaListComponent implements OnInit {

  medias: Media[];

  constructor(private mediaService: MediaService) { }

  ngOnInit(): void {
    this.getMedias();
  }

  getMedias() {
    this.mediaService.getMedias().subscribe(media => {
      this.medias = media;
    });
  }

}

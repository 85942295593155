<h3>Benutzer erstellen</h3>

<form #createChatroomForm="ngForm">
    <div class="form-group">
        <label for="inputName">Bezeichnung</label>
        <input type="text" class="form-control" id="inputName" [(ngModel)]="chatroom.name" name="inputName" placeholder="Bezeichnung" required>
    </div>
    
    <div class="form-group">
        <label for="inputDescription">Beschreibung</label>
        <input type="text" class="form-control" id="inputDescription" [(ngModel)]="chatroom.description" name="description" placeholder="Beschreibung">
    </div>

    <div class="form-check">
        <input type="checkbox" class="form-check-input" id="inputPrivate" [(ngModel)]="chatroom.private" name="private" placeholder="false">
        <label class="form-check-label" for="inputPrivate">Privat</label>
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="!createChatroomForm.form.valid" (click)="save()">Speichern</button>
</form>
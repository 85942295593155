<h3>Agenda-Eintrag bearbeiten</h3>

<form #editAgendaForm="ngForm">
    <div class="form-group">
        <label for="inputName">Bezeichnung</label>
        <input type="text" class="form-control" id="inputName" [(ngModel)]="agenda.name" name="name" placeholder="Bezeichnung" required>
    </div>
    
    <div class="form-group">
        <label for="inputDescription">Beschreibung</label>
        <input type="text" class="form-control" id="inputDescription" [(ngModel)]="agenda.description" name="description" placeholder="Beschreibung" required>
    </div>

    <div class="form-row">
        <div class="col form-group">
            <label for="inpputStartTime">Startzeit</label>
            <input type="datetime-local" class="form-control" id="inpputStartTime" [ngModel]="agenda.startTime | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="agenda.startTime = $event" name="startTime" required>
        </div>
    
        <div class="col form-group">
            <label for="inputEndTime">Endzeit</label>
            <input type="datetime-local" class="form-control" id="inputEndTime" [ngModel]="agenda.endTime | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="agenda.endTime = $event" name="endTime" required>
        </div>

        <div class="col form-group">
            <label for="selectRole">Center</label>
            <select class="form-control" id="selectRole" [(ngModel)]="agenda.location.id" name="role">
                <option *ngFor="let map of maps" [ngValue]="map.id">{{map.name}}</option>
            </select>
        </div>
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="!editAgendaForm.form.valid" (click)="save()">Speichern</button>
</form>
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Media } from '../../_models/media';
import { MediaPosition } from '../../_models/media-position';
import { MediaService } from '../../_services/media.service';

@Component({
  selector: 'app-map-position-edit',
  templateUrl: './map-position-edit.component.html',
  styleUrls: ['./map-position-edit.component.css']
})
export class MapPositionEditComponent implements OnInit, OnChanges {

  @Input() mediaPosition: MediaPosition;
  @Output() saveMediaPosition = new EventEmitter<MediaPosition>();
  @Output() deleteMediaPosition = new EventEmitter<MediaPosition>();
  @Output() resetMap = new EventEmitter<any>();

  icons: Media[];
  sanitizedUrls: SafeResourceUrl[] = [];

  public selectedModule: string = null;

  constructor(
    private mediaService: MediaService,
    private domSanitizer: DomSanitizer
    ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.getIcons();

    this.sanitizedUrls = [];
    this.mediaPosition.media.filter(media => media.mediatype.id === 6)
    .forEach(media => this.sanitizeYouTubeUrl(media));

    this.mediaPosition.media.filter(media => media.mediatype.id === 10)
    .forEach(media => this.sanitizeVimeoUrl(media));
  }

  getIcons(): void {
    this.mediaService.getMediasByMediatype(9).subscribe(media =>
      this.icons = media
    );
  }

  selectModule(module: string): void {
    this.selectedModule = module;
  }

  addMedia($event): void {
    try{
      this.mediaPosition.media.push($event);
    }
    catch {
      this.mediaPosition.media = [];
      this.mediaPosition.media.push($event);
    }

    if ($event.mediatype.id === 6) {
      this.sanitizeYouTubeUrl($event);
    }

    if ($event.mediatype.id === 10) {
      this.sanitizeVimeoUrl($event);
    }

    this.selectedModule = null;
  }

  addVideochat($event): void {
    try{
      this.mediaPosition.videochatrooms.push($event);
    }
    catch {
      this.mediaPosition.videochatrooms = [];
      this.mediaPosition.videochatrooms.push($event);
    }
    this.selectedModule = null;
  }

  removeMedia(id: number): void {
    this.mediaPosition.media = this.mediaPosition.media.filter(media => media.id !== id);
  }

  removeVideochat(id: number): void {
    this.mediaPosition.videochatrooms = this.mediaPosition.videochatrooms.filter(videochatroom => videochatroom.id !== id);
  }

  sanitizeYouTubeUrl(media: Media): void {
    const videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + media.url);

    this.sanitizedUrls[media.id] = videoUrl;
    console.log(videoUrl);
  }

  sanitizeVimeoUrl(media: Media): void {
    const videoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + media.url);

    this.sanitizedUrls[media.id] = videoUrl;
    console.log(videoUrl);
  }

  save(): void {
    this.saveMediaPosition.emit(this.mediaPosition);
  }

  delete(): void {
    this.deleteMediaPosition.emit(this.mediaPosition);
  }

  reset(): void {
    this.resetMap.emit();
  }
}


<div id="interactionsContainer">
    <button (click)="addInteraction('like')" class="interactionBtn"><img src="assets/images/Thumbs_up.png" data-toggle="tooltip" data-placement="top" data-html="true" title="Gefällt mir!" /></button>
    <button (click)="addInteraction('applause')" class="interactionBtn"><img src="assets/images/applaus.png" data-toggle="tooltip" data-placement="top" data-html="true" title="Applaudieren" /></button>
    <button (click)="addInteraction('cheer')" class="interactionBtn"><img src="assets/images/cheer.png" data-toggle="tooltip" data-placement="top" data-html="true" title="Jubeln" /></button>
</div>
<ul>
    <li *ngFor="let interaction of interactions" class="interactionAnimation animation">
        <img src="{{interaction}}" />
    </li>
</ul>
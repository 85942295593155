import { Map } from './map';
import { User } from './user';

export class Agenda {
    id: number;
    name: string;
    description: string;
    startTime: Date;
    endTime: Date;
    private: boolean;
    particpiants: User[];
    location: Map;
}
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {Draggable} from 'ng2draggable/draggable.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapComponent } from './map/map.component';
import { NavigationComponent } from './navigation/navigation.component';
import { AgendaComponent } from './agenda/agenda.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FilterPipe } from './pipes/filter.pipe';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import localeDeCaExtra from '@angular/common/locales/extra/de';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import { WelcomecenterComponent } from './welcomecenter/welcomecenter.component';
import { VideochatComponent } from './videochat/videochat.component';
import {SearchPipe} from './pipes/search.pipe';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import { CityhallComponent } from './cityhall/cityhall.component';
import { MediacenterComponent } from './mediacenter/mediacenter.component';
import {AuthModule} from './auth/auth.module';
import {AuthInterceptor} from './http-interceptors/auth-interceptor';
import { ProfileNavComponent } from './profile-nav/profile-nav.component';
import { NotifierModule } from 'angular-notifier';
import {SlideshowModule} from 'ng-simple-slideshow';
import { Map2Component } from './map2/map2.component';
import { LOCALE_ID} from '@angular/core';
import localeDeExtra from '@angular/common/locales/extra/de';
import { TechnikcheckComponent } from './technikcheck/technikcheck.component';
import { InteressenComponent } from './interessen/interessen.component';
import { RegistrierungComponent } from './registrierung/registrierung.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { UserueberwachungComponent } from './userueberwachung/userueberwachung.component';
import { InteractionsComponent } from './interactions/interactions.component';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ToastsComponent } from './toasts/toasts.component';
import { PrivateChatComponent } from './private-chat/private-chat.component';
import { ChatComponent } from './chat/chat.component';
import { CityhallVotingComponent } from './cityhall-voting/cityhall-voting.component';
import { LivestreamComponent } from './livestream/livestream.component';
import { CityhallNavComponent } from './cityhall-nav/cityhall-nav.component';
import { VideochatNavComponent } from './videochat-nav/videochat-nav.component';
import { MapRedirectComponent } from './map-redirect/map-redirect.component';
import { PublicChatComponent } from './public-chat/public-chat.component';
import { TestComponent } from './test/test.component';
import {AdminNavComponent} from './admin/admin-nav/admin-nav.component';
import {MapCreateComponent} from './admin/map/map-create/map-create.component';
import {MapInteractiveEditComponent} from './admin/map/map-interactive-edit/map-interactive-edit.component';
import {MapPositionCreateComponent} from './admin/map/map-position-create/map-position-create.component';
import {MediaUploadComponent} from './admin/media/media-upload/media-upload.component';
import {VideochatCreateComponent} from './admin/videochat/videochat-create/videochat-create.component';
import {MediaYoutubeCreateComponent} from './admin/media/media-youtube-create/media-youtube-create.component';
import {MapDetailEditComponent} from './admin/map/map-detail-edit/map-detail-edit.component';
import {MediaVimeoCreateComponent} from './admin/media/media-vimeo-create/media-vimeo-create.component';
import {MapPositionEditComponent} from './admin/map/map-position-edit/map-position-edit.component';
import {MediaListCompactComponent} from './admin/media/media-list-compact/media-list-compact.component';
import {AdminLoginComponent} from './admin/admin-login/admin-login.component';
import {TopBarComponent} from './admin/top-bar/top-bar.component';
import { VolumeControlComponent } from './volume-control/volume-control.component';
import { SanitizerPipe } from './sanitizer.pipe';
import { environment } from 'src/environments/environment';
import { JwtInterceptor } from './admin/_helpers/jwt.interceptor';
import { BottomBarComponent } from './admin/bottom-bar/bottom-bar.component';
import { MediaLinkCreateComponent } from './admin/media/media-link-create/media-link-create.component';
import { StartCompanyChatComponent } from './start-company-chat/start-company-chat.component';
// import { ChatBatchCreateComponent } from './admin/chat/chat-batch-create/chat-batch-create.component';
import { AdminComponent } from './admin/admin.component';
import { MapListComponent } from './admin/map/map-list/map-list.component';
import { MapDetailComponent } from './admin/map/map-detail/map-detail.component';
import { MapPreviewComponent } from './admin/map/map-preview/map-preview.component';
import { Cityhall2Component } from './cityhall2/cityhall2.component';
import { AgendaListComponent } from './admin/agenda/agenda-list/agenda-list.component';
import { AgendaEditComponent } from './admin/agenda/agenda-edit/agenda-edit.component';
import { AgendaCreateComponent } from './admin/agenda/agenda-create/agenda-create.component';
import { AgendaCreateBatchComponent } from './admin/agenda/agenda-create-batch/agenda-create-batch.component';
import { MediaListComponent } from './admin/media/media-list/media-list.component';
// import { MediaEditComponent } from './admin/media/media-edit/media-edit.component';
import { MediaCreateComponent } from './admin/media/media-create/media-create.component';
import { UserListComponent } from './admin/user/user-list/user-list.component';
import { UserCreateComponent } from './admin/user/user-create/user-create.component';
import { UserCreateBatchComponent } from './admin/user/user-create-batch/user-create-batch.component';
import { UserEditComponent } from './admin/user/user-edit/user-edit.component';
import { VotingListComponent } from './admin/voting/voting-list/voting-list.component';
import { VotingEditComponent } from './admin/voting/voting-edit/voting-edit.component';
import { VotingViewComponent } from './admin/voting/voting-view/voting-view.component';
import { VotingCreateComponent } from './admin/voting/voting-create/voting-create.component';
import { ChatListComponent } from './admin/chat/chat-list/chat-list.component';
import { ChatViewComponent } from './admin/chat/chat-view/chat-view.component';
import { ChatCreateComponent } from './admin/chat/chat-create/chat-create.component';
import { ChatCreateBatchComponent } from './admin/chat/chat-create-batch/chat-create-batch.component';
import { ChatEditComponent } from './admin/chat/chat-edit/chat-edit.component';
import { MediaEditComponent } from './admin/media/media-edit/media-edit.component';
import { NutzungsbedingungenComponent } from './nutzungsbedingungen/nutzungsbedingungen.component';
import { VotingFrontendComponent } from './voting-frontend/voting-frontend.component';
import { ChatViewCumulatedComponent } from './admin/chat/chat-view-cumulated/chat-view-cumulated.component';
import { ChatViewModComponent } from './admin/chat/chat-view-mod/chat-view-mod.component';
import { VideochatRawComponent } from './videochat-raw/videochat-raw.component';
import { SlidoComponent } from './slido/slido.component';
import {CenterComponent} from './center/center.component';

registerLocaleData(localeDe);
const config: SocketIoConfig = { url: `${environment.frontendApiUrl}`, options: {autoConnect: false}};


registerLocaleData(localeDe, localeDeCaExtra);

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    NavigationComponent,
    CenterComponent,
    AgendaComponent,
    WelcomecenterComponent,
    VideochatComponent,
    FilterPipe,
    SearchPipe,
    CityhallComponent,
    MediacenterComponent,
    ProfileNavComponent,
    Map2Component,
    TechnikcheckComponent,
    InteressenComponent,
    RegistrierungComponent,
    UpdateUserComponent,
    UploadImageComponent,
    UserueberwachungComponent,
    InteractionsComponent,
    ToastsComponent,
    PrivateChatComponent,
    ChatComponent,
    CityhallVotingComponent,
    LivestreamComponent,
    CityhallNavComponent,
    VideochatNavComponent,
    MapRedirectComponent,
    PublicChatComponent,
    TestComponent,
    AdminComponent,
    AdminNavComponent,
    MapDetailComponent,
    MapListComponent,
    MapCreateComponent,
    MediaListComponent,
    MediaCreateComponent,
    MapPreviewComponent,
    MapInteractiveEditComponent,
    MapPositionCreateComponent,
    MediaListCompactComponent,
    MediaUploadComponent,
    MapPositionEditComponent,
    VideochatCreateComponent,
    MediaYoutubeCreateComponent,
    MediaVimeoCreateComponent,
    MapDetailEditComponent,
    AdminLoginComponent,
    TopBarComponent,
    VolumeControlComponent,
    SanitizerPipe,
    Cityhall2Component,
    BottomBarComponent,
    MediaLinkCreateComponent,
    StartCompanyChatComponent,
    ChatListComponent,
    ChatCreateComponent,
    ChatEditComponent,
    ChatCreateBatchComponent,
    AgendaListComponent,
    AgendaEditComponent,
    AgendaCreateComponent,
    AgendaCreateBatchComponent,
    UserListComponent,
    UserCreateComponent,
    UserCreateBatchComponent,
    UserEditComponent,
    VotingListComponent,
    VotingEditComponent,
    VotingViewComponent,
    VotingCreateComponent,
    ChatViewComponent,
    MediaEditComponent,
    NutzungsbedingungenComponent,
    VotingFrontendComponent,
    ChatViewCumulatedComponent,
    ChatViewModComponent,
    VideochatRawComponent,
    SlidoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    SlideshowModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    SocketIoModule.forRoot(config),
    NotifierModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: function tokenGetter() {
             return localStorage.getItem('access_token');
        },
        allowedDomains: [`${environment.frontendApiUrl}/api`],
        disallowedRoutes: [`${environment.frontendApiUrl}/api/auth/login`]
      }
    }),
    AuthModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdminUser } from '../_models/admin-user';
import { environment } from '../../../environments/environment';
import { JwtDecodeService } from '../_services/jwt-decode.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private currentUserSubject: BehaviorSubject<AdminUser>;
  public currentUser: Observable<AdminUser>;
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private jwtDecodeService: JwtDecodeService) {
    this.checkLoginState();
    this.currentUserSubject = new BehaviorSubject<AdminUser>(this.jwtDecodeService.getUser(localStorage.getItem('admin_token')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): AdminUser {
    this.checkLoginState();
    return this.currentUserSubject.value;
  }

  public get userLoginState(): Observable<boolean> {
    this.checkLoginState();
    return this.loggedIn.asObservable();
  }

  checkLoginState(): void {
    const token = localStorage.getItem('admin_token');
    const exp = this.jwtDecodeService.getExp(token);
    const now = Date.now();

    if (token && exp * 1000 < now) {
      this.logout();
    }
  }

  login(email: string, password: string): Observable<AdminUser> {
      return this.http.post<any>(`${environment.apiUrl}/admin-auth/login`, { 'email': email, 'password': password })
        .pipe(map(res => {
          // login successful if there's a jwt token in the response
          if (res && res.admin_token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('admin_token', res.admin_token);
              this.currentUserSubject.next(this.jwtDecodeService.getUser(res.admin_token));
              this.loggedIn.next(true);
          }

          return this.jwtDecodeService.getUser(res.admin_token);
        }));
  }

  logout(): void {
      // remove user from local storage to log user out
      localStorage.removeItem('admin_token');
      this.currentUserSubject.next(null);
      this.loggedIn.next(false);
  }
}

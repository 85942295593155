<table class="table table-hover">
    <thead>
        <tr>
            <th scope="col">Vorschau</th>
            <th scope="col">Name</th>
            <th scope="col">Beschreibung</th>
            <th scope="col">Medientyp</th>

        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let media of medias" (click)="selectMedia(media)">
            <td><img src="{{media.url}}" width="100"></td>
            <td>{{media.name}}</td>
            <td>{{media.description}}</td>
            <td>{{media.mediatype}}</td>
        </tr>
    </tbody>
</table>
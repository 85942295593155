import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MapService } from '../../_services/map.service';
import { Map } from '../../_models/map';
import { MapType } from '../../_models/map';
import { Company } from '../../_models/company';
import {MediaPosition} from '../../_models/media-position';
import { User } from '../../_models/user';
declare var $;

@Component({
  selector: 'app-map-detail',
  templateUrl: './map-detail.component.html',
  styleUrls: ['./map-detail.component.css']
})
export class MapDetailComponent implements OnInit {

  map: Map;
  mapBackup: Map;
  id: number;
  types = MapType;
  keys = Object.keys;
  alertclass = 'alert-danger';
  alertmessage = 'Test';
  alertshow = false;

  newMediaPosition: MediaPosition;
  selectedMediaPosition: MediaPosition;

  constructor(
    private route: ActivatedRoute,
    private mapService: MapService
  ) { }

  ngOnInit(): void {
    this.id = +this.route.snapshot.paramMap.get('id');

    this.getMap();
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforClose($event): void {
    $event.returnValue = 'Haben Sie gespeichert?';
  }

  getMap(): void {
    this.mapService.getMap(this.id)
      .subscribe(map => {
        this.map = map;
        this.mapBackup = map;

        if(!this.map.chatContact) {
          this.map.chatContact = new User();
        }
      });
  }

  save(): void {
    this.mapService.updateMap(this.map).subscribe(data =>
      this.getMap()
    );
  }

  reset($event): void {
    return this.getMap();
  }

  openMediaPositionCreateDialog($event): void {
    this.newMediaPosition = $event;
    $('#mapPositionCreateModal').modal('show');
  }

  openMediaPositionEditDialog($event): void {
    this.selectedMediaPosition = $event;
    $('#mapPositionEditModal').modal('show');
  }

  saveMediaPosition($event): void {
    this.map.mediapositions.push($event);
    this.save();
    $('#mapPositionCreateModal').modal('hide');
  }

  updateMediaPosition($event): void {
    const positionId = this.map.mediapositions.indexOf($event.id);
    this.map.mediapositions[positionId] = $event;
    this.save();
    $('#mapPositionEditModal').modal('hide');
  }

  removeMediaPosition($event): void {
    this.map.mediapositions = this.map.mediapositions.filter(mediaposition => mediaposition.id !== $event.id);
    this.save();
    $('#mapPositionEditModal').modal('hide');
  }

  updateMapDetails($event): void {
    this.map.background = $event.background;
    this.map.name = $event.name;
    this.map.description = $event.description;

    this.save();
    $('#mapDetailEditModal').modal('hide');
  }
}

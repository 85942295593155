<button class="btn btn-danger newVote" id="newVote" style="display: none;" (click)="showVotings(true)">Jetzt abstimmen!</button>

<div class="votingContainer" id="panel" style="display: none;" *ngIf="showVoting === true">
    <p>Bitte beachten Sie: Sie können nur einmal abstimmen. Eine spätere Änderung Ihrer Wahl ist nicht möglich.</p>
    <div *ngFor="let votingg of votingGets">
        <h3>{{votingg?.description}}</h3>
        <div *ngFor="let option of votingg?.options"><button class="btn btn-light result" (click)="abstimmen(option?.id, votingg?.id)">{{option?.content}}</button></div>
    </div>
    <br><br><br><br>
    <p class="close" (click)="showVotings(false)">X</p>
</div>
import { Component, OnInit } from '@angular/core';
import { MapService } from '../../_services/map.service';
import { Map } from '../../_models/map';

@Component({
  selector: 'app-map-list',
  templateUrl: './map-list.component.html',
  styleUrls: ['./map-list.component.css']
})
export class MapListComponent implements OnInit {

  maps: Map[];

  constructor(
    private mapService: MapService
  ) { }

  ngOnInit(): void {
    this.getMaps();
  }

  getMaps(): void {
    this.mapService.getMaps().subscribe(maps =>
      this.maps = maps
    );
  }
}

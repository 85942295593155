

<body>
    <img id="wes-logo" src="assets/images/wew_cropped.png" /><br>

<div class="container">
    <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="home-tab" data-toggle="tab" href="#speed" role="tab" aria-controls="speed" aria-selected="true">Internetgeschwindigkeit</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="profile-tab" data-toggle="tab" href="#speakers" role="tab" aria-controls="speakers" aria-selected="false">Lautsprecher</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="contact-tab" data-toggle="tab" href="#microphone" role="tab" aria-controls="microphone" aria-selected="false">Mikrofon</a>
        </li>
        <!--<li class="nav-item">
          <a class="nav-link" id="contact-tab" data-toggle="tab" href="#camera" role="tab" aria-controls="camera" aria-selected="false">Kamera</a>
        </li>-->
        <li class="nav-item">
            <a class="nav-link unselectable" (click)="zurueck()">Fertig</a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="speed" role="tabpanel" aria-labelledby="home-tab">
            <br>
            <h2>Internetgeschwindigkeit testen</h2>
            <div style="margin: 0 auto;"><p>Um Ihnen ein optimales Erlebnis garantieren zu können, testen wir im Folgenden kurz Ihre Technik. Um Ihre Internetgeschwindigkeit zu testen klicken Sie bitte auf "Speed-Test starten". Minimale Werte wären:<br>↓ 2 Mbps<br>↑ 1,5 Mbps</p></div>
            <div id="sc-container" style="margin: 0 auto;">
                <div id="sc-branding" class="sc-bb">
                    <a target="_blank" href="https://www.speedcheck.org/de/">
                        <img src="https://cdn.speedcheck.org/branding/speedcheck-logo-18.png" alt="Speedcheck"/>
                    </a>
                </div>
            </div>
        </div>
        <div class="tab-pane fade" id="speakers" role="tabpanel" aria-labelledby="profile-tab">
            <br>
            <h2>Lautsprecher testen</h2>
            <p>Hören Sie einen Ton?</p>
            <button (click)="speakerTest()" class="btn btn-primary" style="margin: 0 auto;">Testsound abspielen</button>
        </div>
        <div class="tab-pane fade" id="microphone" role="tabpanel" aria-labelledby="contact-tab">
            <br>
            <h2>Mikrofon testen</h2>
            <div id="mikrofon" style="margin: 0 auto;"></div>
        </div><div class="tab-pane fade" id="camera" role="tabpanel" aria-labelledby="contact-tab">
            <br>
            <h2>Kamera testen</h2>

        </div>
      </div>
</div>

</body>


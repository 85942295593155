<h3>Benutzer erstellen</h3>

<form #createUserForm="ngForm">
    <div class="form-group">
        <label for="inputFirstName">Vorname</label>
        <input type="text" class="form-control" id="inputFirstName" [(ngModel)]="user.firstName" name="firstname" placeholder="Vorname" required>
    </div>
    
    <div class="form-group">
        <label for="inputLastName">Nachname</label>
        <input type="text" class="form-control" id="inputLastName" [(ngModel)]="user.lastName" name="lastname" placeholder="Nachname" required>
    </div>

    <div class="form-group">
        <label for="inputEmail">E-Mail</label>
        <input type="email" class="form-control" id="inputEmail" [(ngModel)]="user.eMail" name="email" placeholder="name@provider.de" required>
    </div>

    <div class="form-group">
        <label for="inputPassword">Passwort</label>
        <input type="password" class="form-control" id="inputPassword" [(ngModel)]="user.passwordHash" name="password" required>
    </div>

    <div class="form-group">
        <label for="selectRole">Rolle</label>
        <select class="form-control" id="selectRole" [(ngModel)]="user.role.id" name="role">
            <option *ngFor="let role of roles" [ngValue]="role.id">{{role.id}}</option>
        </select>
    </div>

    <div class="form-group">
        <label for="selectCompany">Firma</label>
        <select class="form-control" id="selectCompany" [(ngModel)]="user.company.id" name="company">
            <option *ngFor="let company of companies" [ngValue]="company.id">{{company.name}}</option>
        </select>
    </div>

    <button type="submit" class="btn btn-primary" [disabled]="!createUserForm.form.valid" (click)="save()">Speichern</button>
</form>
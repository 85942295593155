import { Map } from './map';

export class AdminUser {
    id: number;
    firstName: string;
    lastName: string;
    eMail: string;
    maps: Map[];
    passwordHash: string;
    token: string;
}

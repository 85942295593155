import { Component, OnInit } from '@angular/core';
import { AgendaService } from '../services/agenda.service';
import { DecodeTokenService } from '../services/decode-token.service';
declare var $;

@Component({
  selector: 'app-toasts',
  templateUrl: './toasts.component.html',
  styleUrls: ['./toasts.component.css']
})
export class ToastsComponent implements OnInit {

  agenda: any;
  constructor(private agendaService: AgendaService, private decodeTokenService: DecodeTokenService) { }

  ngOnInit(): void {
    this.agendaService.getPersonalAgenda(this.decodeTokenService.decodeUserId())
    .subscribe(agenda => this.updateAgenda(agenda));
    //this.agendaService.getPersonalAgenda(this.decodeTokenService.decodeUserId())
    //.subscribe(agenda => this.agenda = agenda);
    for (let index = 0; index < 50; index++) {
      try {
        this.openToast(index);
      } catch (error) {
        
      }
      
      
    }
    
  }

  updateAgenda(data){
    var test = new Array();
    setInterval(function(){
      for (let id = 0; id < data.length; id++) {
        
        try {
          var time = new Date();
          var timestamp2 = time.getTime();
          var date = new Date(data[id].startTime);
          var timestamp = date.getTime();
          var remainingTimestamp = (timestamp - timestamp2)/1000/86400;
          if (remainingTimestamp < 0){
            if(localStorage.getItem('toast'+data[id].id)){}else{
              test.push({id: data[id].id, name: data[id].name});
              console.log(test);
              this.agenda = test;
              console.log(this.agenda);
              console.log(data[id].name+' beginnt');
              $('#toast'+data[id].id).toast({delay: 20000});
              $('#toast'+data[id].id).toast('show');
              $('.toast').toast({delay: 20000});
              $('.toast').toast('show');
              localStorage.setItem('toast'+data[id].id, 'true');
            }
          }else{
            //console.log(data[id].name+' nicht vorbei');
            
          }
          console.log(this.getRemainingTime(data[id]));
        } catch (error) {}       
      }
    }, 2000)
  }
  

  getRemainingTime(agendaData){
    console.log(agendaData);
    /*var time = new Date();
    var timestamp2 = time.getTime();
    var date = new Date(agendaData.startTime);
    var timestamp = date.getTime();
    var remainingTimestamp = (timestamp - timestamp2)/1000/86400;
    if (remainingTimestamp*24*60*60 > 86400){
      remainingTimestamp = Math.round(remainingTimestamp);
      return remainingTimestamp + ' Tag(e)';
    }else if (remainingTimestamp < 0){
      return 'vorbei';
    }else if(remainingTimestamp*24*60*60 < 60) {
      remainingTimestamp = Math.round(remainingTimestamp*24*60*60);
      return remainingTimestamp + ' Sekunde(n)';
    }else if(remainingTimestamp*24*60*60 < 3600) {
      remainingTimestamp = Math.round(remainingTimestamp*24*60);
      return remainingTimestamp + ' Minute(n)'
    }else if (remainingTimestamp*24*60*60 < 86400) {
      remainingTimestamp = Math.round(remainingTimestamp*24);
      return remainingTimestamp + ' Stunde(n)'
    }*/
  }

  openToast(id){
    $('#toast'+id).toast({delay: 8000});
    $('#toast'+id).toast('show');
  }

}

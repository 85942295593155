import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Users } from '../models/users';
import {DatabaseService} from './database.service';

@Injectable({ providedIn: 'root' })
export class UsersService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient, private dbUrl: DatabaseService) {}
  private mapUrl = this.dbUrl.returnDatabaseUrl()+'users/';  // URL to web api
  postId: any;
  /** GET partner from the server */
  getUser(id) {
    return this.http.get(this.mapUrl+id)
    .pipe(
        catchError(this.handleError)
      );
     
  }

  register(firstName, lastName, eMail, passwort) {
    const headers = { "accept": "application/json", "Content-Type": "application/json" }; 
    const body = {"firstName": firstName, "lastName": lastName, "eMail": eMail, "passwordHash": passwort, role:{id: 1}} ;
    this.http.post<any>(this.dbUrl.returnDatabaseUrl()+'users', body, { headers }).subscribe(data => {
        this.postId = data.id;
    })
    //this.http.post(this.mapUrl, data);  
  }

  update(id, data){
    const headers = { "accept": "application/json", "Content-Type": "application/json" }; 
    const body = data ;
    this.http.put<any>(this.dbUrl.returnDatabaseUrl()+'users/'+id, body, { headers }).subscribe(data => {
        this.postId = data.id;
    })
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  getUsers(){
    return this.http.get(this.mapUrl)
    .pipe(
        catchError(this.handleError)
      );
  }

  sendMail(){
    const email = 'Test';
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        this.http.post('https://formspree.io/mnqgkkql',
          { name: email, replyto: 'rauch_ludwig@web.de', message: email },
          { 'headers': headers }).subscribe(
            response => {
              console.log(response);
            }
          );
  }

  
}



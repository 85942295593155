<div>
    <h3>Abstimmungen</h3>

    <p>
        <a type="button" class="btn btn-primary mr-1" routerLink="/admin/voting/create">Neu erstellen</a>
    </p>
    
    <table class="table table-hover">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Bezeichnung</th>
                <th scope="col">Frage</th>
                <th scope="col">Benutzer</th>
                <th scope="col">Aktionen</th>
            </tr>
        </thead>
    
        <tbody>
            <tr *ngFor="let voting of votings; let i = index" class="mouse-pointer" routerLink="/admin/voting/view/{{voting.id}}">
                <th scope="row">{{voting.id}}</th>
                <td>{{voting.name}}</td>
                <td>{{voting.description}}</td>
                <td>{{voting.creator?.firstName}}&nbsp;{{voting.creator?.lastName}}</td>
                <td>
                    <button type="button" class="btn btn-danger btn-sm ml-1" (click)="delete(user.id)">Löschen</button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Media } from '../../_models/media';
import { Mediatype } from '../../_models/mediatype';
import { MediaService } from '../../_services/media.service';
import { MediatypeService } from '../../_services/mediatype.service';

@Component({
  selector: 'app-media-create',
  templateUrl: './media-create.component.html',
  styleUrls: ['./media-create.component.sass']
})
export class MediaCreateComponent implements OnInit {

  fileToUpload: File = null;
  media: Media = new Media();
  mediatypes: Mediatype[];

  constructor(
    private mediaService: MediaService,
    private mediatypeService: MediatypeService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getMediatypes();
    this.media.mediatype = new Mediatype();
  }

  getMediatypes(): void {
    this.mediatypeService.getMediatypes().subscribe(mediatypes => {
      console.log(mediatypes);
      this.mediatypes = mediatypes;
    });
  }

  handleFileInput(files: FileList): void {
    this.fileToUpload = files.item(0);
    const name = this.fileToUpload.name.split('.');

    let mediatype: number;
    switch (name[name.length - 1]) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'JPG':
      case 'JPEG':
      case 'PNG':
      case 'GIF':
        mediatype = 1;
        break;
      case 'pdf':
      case 'PDF':
        mediatype = 2;
        break;
      case 'mp4':
      case 'MP4':
        mediatype = 4;
        break;
      case 'mp3':
      case 'MP3':
        mediatype = 7;
        break;
    }
    this.media.mediatype.id = mediatype;
    this.media.name = name[0];
  }

  uploadFileToActivity(): void {
    if (this.media.mediatype) {
      console.log('FILE UPLOAD STARTED');
      this.mediaService.uploadMedia(this.fileToUpload).subscribe(data => {
        // do something, if upload success
        console.log(data);
        this.media.url = `downloads/${data.filename}`;
        this.saveMedia();
        }, error => {
          console.log(error);
        });
    }
    else {
      console.log('Please add mediatype');
    }
  }

  saveMedia(): void {
    this.mediaService.createMedia(this.media).subscribe(() =>
      this.router.navigate(['/admin/media'])
    );
  }
}

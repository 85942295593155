import { VotingOption } from './voting-option';
import { User } from './user';
import { Company } from './company';

export class Voting {
    id: number;
    name: string;
    description: string;
    startdate: Date;
    expiredate: Date;
    anonymous: boolean;
    options: VotingOption[];
    creator: User;
    company: Company;
}
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cityhall-nav',
  templateUrl: './cityhall-nav.component.html',
  styleUrls: ['./cityhall-nav.component.css']
})
export class CityhallNavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    document.addEventListener('mousemove', e => {
      this.menuEin();
      setTimeout(this.menuAus, 5000);
    });
  }



  menuEin(){
    document.getElementById("nav2ul").style.opacity = "100";
  }

  menuAus(){
    document.getElementById("nav2ul").style.opacity = "0";
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-volume-control',
  templateUrl: './volume-control.component.html',
  styleUrls: ['./volume-control.component.css']
})
export class VolumeControlComponent implements OnInit {
  generalVolume: string = '0';
  agendaVolume: string = '0';
  chatVolume: string = '0';

  constructor() { }

  ngOnInit(): void {
    if (localStorage.getItem('generalVolume')) {
      this.generalVolume = localStorage.getItem('generalVolume');
    }else{
      localStorage.setItem('generalVolume', '100');
      this.generalVolume = '100';
    }

    // Slider Input
    var slider = document.getElementById("myRange") as HTMLInputElement;

    // Update the current slider value (each time you drag the slider handle)
    slider.oninput = () => {
      localStorage.setItem('generalVolume', slider.value);
      this.generalVolume = slider.value;
    }


    if (localStorage.getItem('agendaVolume')) {
      this.agendaVolume = localStorage.getItem('agendaVolume');
    }else{
      localStorage.setItem('agendaVolume', '100');
      this.agendaVolume = '100';
    }

    var slider2 = document.getElementById("myRangeAgenda") as HTMLInputElement;

    // Update the current slider value (each time you drag the slider handle)
    slider2.oninput = () => {
      localStorage.setItem('agendaVolume', slider2.value);
      this.agendaVolume = slider2.value;
    }


    if (localStorage.getItem('chatVolume')) {
      this.chatVolume = localStorage.getItem('chatVolume');
    }else{
      localStorage.setItem('chatVolume', '100');
      this.chatVolume = '100';
    }

    var slider3 = document.getElementById("myRangeChat") as HTMLInputElement;

    // Update the current slider value (each time you drag the slider handle)
    slider3.oninput = () => {
      localStorage.setItem('chatVolume', slider3.value);
      this.chatVolume = slider3.value;
    }
  }


  
}

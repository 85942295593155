import { Component, OnChanges, OnInit } from '@angular/core';
import { ChatService } from '../services/chat.service';
import { Chatroom } from '../models/chatroom';
import {SharedService} from '../services/shared.service';
import { Subject } from 'rxjs';
declare var $;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnChanges {
  eventsSubject: Subject<void> = new Subject<void>();
  selectedChatroom: number;
  public userId: number;
  ben : any;
  benAnzahl: number;
  notify: number = 0;
  receivedChildMessage: string;
  receivedChildMessagePrivate: string;
  privBen: boolean = false;

  constructor(
    private chatService: ChatService
    ) { }

  ngOnInit(): void {
    this.chatService.connect();
    this.benAnzahl = 0;

    this.chatService.receiveUserid().subscribe((userId: number) => {
      this.userId = userId;
      // console.log('UserId: '+userId);
    });
    //this.showChat();
    //this.toggle('private');
  }

  ngOnChanges():void {
    this.chatService.connect();

  }

  onSelect(chatroomId: number) {
    this.selectedChatroom = chatroomId;
  }

  getMessage(message: string) {
    this.receivedChildMessage = message;
    console.log(message);
    this.benAnzahl = this.benAnzahl +1;
  }

  getMessagePrivate(message: string) {
    this.receivedChildMessagePrivate = message;
    console.log(message);
    this.benAnzahl = this.benAnzahl +1;
    this.privBen = true;
  }

  createAnsprech(message: any) {
    let bestaetigt = window.confirm('Dies startet einen neuen Chat mit dem/der Ansprechpertner/in dieser Firma.');
    if(bestaetigt === true){
      this.toggle('private');
      this.eventsSubject.next(message);
    }
    
  }


  toggle(type: string): void{
    const privateBtn = document.getElementById('privateBtn') as HTMLInputElement;
    const ansprechBtn = document.getElementById('ansprechBtn') as HTMLInputElement;
    const privateChat = document.getElementById('privateChatDiv');
    const ansprechChat = document.getElementById('companyChatDiv');
  if(type === 'private'){
      ansprechBtn.classList.remove('active');
      privateBtn.classList.add('active');
      privateChat.style.display = 'block';
      ansprechChat.style.display = 'none';
      this.privBen = false;
    }
    else if(type === 'ansprechpartner'){
      privateBtn.classList.remove('active');
      ansprechBtn.classList.add('active');
      privateChat.style.display = 'none';
      ansprechChat.style.display = 'block';
      this.privBen = false;
    }
  }

  showChat(){
    this.chatService.connect();
    document.getElementById("chatfenster").style.right= "0px";
    this.testton('assets/sounds/slide.mp3');
    this.resetNot();
    this.ben = [];
    const grau = document.getElementById("ausgrauen");
    grau.style.display = "block";
    setTimeout(()=>{grau.style.opacity = "100%"}, 100);
  }

  hideChat(){
    document.getElementById("chatfenster").style.right= "-2000px";
    this.testton('assets/sounds/blop.wav');
    const grau = document.getElementById("ausgrauen");
    grau.style.opacity = "0%"
    setTimeout(()=>{grau.style.display = "none";}, 500);
    this.resetNot();
  }

  testton(url):void{
    var audio = new Audio(url);
    audio.volume = Number(localStorage.getItem('chatVolume'))/100;
    audio.play();
  }

  resetNot(){
    this.benAnzahl = 0;
  }

  addBen(){
    this.benAnzahl = this.benAnzahl + 1;
    
  }

  fadebenAnzeige(){
    let element = document.getElementById('benAnzeige');
    element.style.right = '3.3em';
    element.style.opacity = '100%';
  }

  fadeOut(){
    let element = document.getElementById('benAnzeige');
    element.style.right = '-10em';
    element.style.opacity = '0%';
    let element2 = document.getElementById('userAnzeige');
    element2.style.right = '-10em';
    element2.style.opacity = '0%';
  }
}
import { MapToMap } from './map-to-map';
import { MediaToMap } from './media-to-map';
import { Company } from './company';
import { MediaPosition } from './media-position';
import { Media } from './media';
import { User } from './user';
import { Chatroom } from './chatroom';

export enum MapType {
    DEFAULT = 'Default',
    PARTNER = 'Partner'
}

export class Map {
    id: number;
    name: string;
    description: string;
    background: Media;
    backgroundWidth: number;
    backgroundHeight: number;
    zoomMin: number;
    zoomMax: number;
    zoomDefault: number;
    midX: number;
    midY: number;
    type: MapType;
    company: Company;
    mapChilds: MapToMap[];
    media: MediaToMap[];
    mediapositions: MediaPosition[];
    startupMedia: Media;
    icon: Media;
    link: string;
    chatroom: Chatroom;
    chatContact: User;
}

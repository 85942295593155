<h3>Medien hochladen</h3>

<div class="form-group mb-3">    
    <label for="file">Datei auswählen</label>
    <input type="file" class="form-control" id="file" (change)="handleFileInput($event.target.files)">
</div>

<div class="row">
    <div class="col-md-8">
        <div class="form-row mb-3">
            <label for="inputName">Bezeichnung</label>
            <input type="text" class="form-control" id="inputName" [(ngModel)]="media.name" name="name" placeholder="Geben Sie hier die Bezeichnung des Mediums an" required>
        </div>
        <div class="form-row mb-3">
            <label for="inputDescription">Beschreibung</label>
            <textarea class="form-control" id="inputDescription" [(ngModel)]="media.description" name="description" placeholder="Hier können Sie optional eine Beschreibung hinzufügen" rows="4" maxlength="255"></textarea>
        </div>                            
    </div>
    <div class="col-md-4">
        <div class="form-row mb-3">
            <label for="selectMediatype">Medientyp</label>
            <select class="form-control selectpicker" id="selectMediatype" [(ngModel)]="media.mediatype.id" name="mediatype">
                <option *ngFor="let mediatype of mediatypes" [ngValue]="mediatype.id">{{mediatype.name}}</option>
            </select>
        </div>
        <div class="form-row mb-3">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" id="inputMediacenter" [(ngModel)]="media.mediacenter" name="mediacenter">
                <label class="form-check-label" for="inputMediacenter">Im Mediacenter anzeigen</label>
            </div>
        </div>
    </div>
</div>

<button type="button" class="btn btn-primary" (click)="uploadFileToActivity()">Hochladen</button>

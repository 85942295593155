import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BenachrichtigungService {
  sharedData: any;
  constructor() { }

  add(value){
      this.sharedData.push(value);
      console.log(this.sharedData);
  }

  get(){
    return this.sharedData;
  }
  
}

<h3>Agenda-Einträge erstellen</h3>

<div class="form-group mb-3">    
    <label for="file">CSV auswählen</label>
    <input type="file" class="form-control" id="file" (change)="handleFileInput($event.target.files)">
</div>

<div *ngIf="agendaEntries">
    <table class="table table-hover">
        <thead>
            <tr>
                <th scope="col">Name</th>
                <th scope="col">Beschreibung</th>
                <th scope="col">Beginn</th>
                <th scope="col">Ende</th>
                <th scope="col">Center</th>
            </tr>
        </thead>
    
        <tbody>
            <tr *ngFor="let agenda of agendaEntries">
                <td>{{agenda.name}}</td>
                <td>{{agenda.description}}</td>
                <td>{{agenda.startTime}}</td>
                <td>{{agenda.endTime}}</td>
                <td>{{agenda.location}}</td>
            </tr>
        </tbody>
    </table>
    
    <button type="button" class="btn btn-primary" (click)="createAgendaEntries(agendaEntries)">Agenda-Einträge erstellen</button>
</div>


<body>
    <div id="abstimmung">
        <p id="closeCityhallVote" (click)="closeVote()">X</p>
        <div id="inhalt">
            <h3>Umfrage</h3>
            {{data?.name}}

            <div class="btn-group" role="group" aria-label="Basic example">
                <button *ngFor="let option of data?.options" type="button" class="btn btn-light" (click)="vote(option.id)">{{option.content}}</button>
                
            </div>
        </div>

        <div id="ergebnisse" style="display: none;">
            <h3>Ergebnis ({{howManyVotes}} Stimmen):</h3>
            <table>
                <tr *ngFor="let result of results?.options">
                    <td style="min-width: 8em;">{{result.content}}</td>
                    <td><progress id="affe{{result?.id}}" value="{{result?.results.length}}" max="{{howManyVotes}}" style="width: 60vw; height: 2em; transition: 0.5s ease;" title="{{result?.results.length}} Stimme(n)"> {{result?.results.length}} </progress></td>
                    <td>{{result?.results.length}}</td>
                </tr>
            </table>
        </div>
    </div>
</body>

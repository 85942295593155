import { Map } from './map';
import { Media } from './media';
import { Videochatroom } from './videochatroom';

export class MediaPosition {
    id: number;
    map: Map;
    title: string;
    description: string;
    icon: Media;
    videochatrooms: Videochatroom[];
    media: Media[];
    positionX: number;
    positionY: number;
}

<div>
    <h3>Agenda</h3>

    <p>
        <a type="button" class="btn btn-primary mr-1" routerLink="/admin/agenda/create">Neu erstellen</a>
        <a type="button" class="btn btn-secondary ml-1" routerLink="/admin/agenda/createbatch">CSV Import</a>
    </p>
    
    <table class="table table-hover">
        <thead>
            <tr>
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Beschreibung</th>
                <th scope="col">Beginn</th>
                <th scope="col">Ende</th>
                <th scope="col">Center</th>
            </tr>
        </thead>
    
        <tbody>
            <tr *ngFor="let agenda of agendaEntries" class="mouse-pointer" routerLink="/admin/agenda/edit/{{agenda.id}}">
                <th scope="row">{{agenda.id}}</th>
                <td>{{agenda.name}}</td>
                <td>{{agenda.description}}</td>
                <td>{{agenda.startTime}}</td>
                <td>{{agenda.endTime}}</td>
                <td>{{agenda.location?.name}}</td>
            </tr>
        </tbody>
    </table>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Chatroom } from '../_models/chatroom';

@Injectable({
  providedIn: 'root'
})
export class ChatroomService {

  url = `${environment.apiUrl}/chatrooms`;

  constructor(
    private http: HttpClient
  ) { }

  getChatrooms(): Observable<Chatroom[]> {
    return this.http.get<Chatroom[]>(this.url)
      .pipe(
        tap(_ => console.log('fetched chatrooms')),
        catchError(this.handleError<Chatroom[]>('getChatrooms, []'))
      );
  }

  getChatroom(id: number): Observable<Chatroom>{
    return this.http.get<Chatroom>(`${this.url}/${id}`)
      .pipe(
        tap(_ => console.log(`fetched chatroom id=${id}`)),
        catchError(this.handleError<Chatroom>('getChatroom, []'))
      );
  }

  updateChatroom(chatroom: Chatroom): Observable<any>{
    return this.http.put(`${this.url}/${chatroom.id}`, chatroom)
      .pipe(
        tap(_ => console.log('updated chatroom id=${chatroom.id}')),
        catchError(this.handleError<any>('updateChatroom'))
      );
  }

  createChatroom(chatroom: Chatroom): Observable<Chatroom>{
    return this.http.post(this.url, chatroom)
      .pipe(
        tap(_ => console.log('created chatroom id=${chatroom.id}')),
        catchError(this.handleError<any>('createChatroom'))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}

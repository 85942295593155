<div class="edit-component">
    <div class="row mt-3">
        <p class="mx-3">Klicken Sie auf "Speichern", um Ihre Änderungen zu übernehmen!</p>
        <div class="col-6">
            <button type="button" class="btn btn-block btn-secondary" data-dismiss="modal" (click)="reset()">Abbrechen</button>
        </div>
        <div class="col-6">
            <button type="button" class="btn btn-block btn-danger" (click)="save()">Speichern</button>
        </div>
    </div>
    
    <div id="mapDetailEditAccordion">
        <div class="card my-3">
            <div class="card-header" id="headingOne">
                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <h5>Details</h5>
                </button>
            </div>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#mapDetailEditAccordion">
                <div class="card-body">
                    <div>
                        <div class="form-group mb-3">
                            <label for="inputName">Bezeichnung</label>
                            <input type="text" class="form-control" id="inputName" [(ngModel)]="map.name" name="name" placeholder="Die Bezeichnung des Centers erscheint auf der Karte" required>
                        </div>
                        <div class="form-group mb-3">
                            <label for="inputDescription">Beschreibung</label>
                            <textarea class="form-control" id="inputDescription" [(ngModel)]="map.description" name="description" placeholder="Hier können Sie optional eine Beschreibung hinzufügen" rows="4"></textarea>
                        </div>
                        <div class="form-group mb-3">
                            <label for="inputLink">Externer Link</label>
                            <p class="small">Dieser Link ist mit ihrem Logo (oben Mitte im Center) verknüpft.</p>
                            <input type="text" class="form-control" id="inputLink" [(ngModel)]="map.link" name="link" placeholder="https://webeventstudios.com">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="card my-3">
            <div class="card-header" id="headingTwo">
                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <h5>Hintergrund</h5>
                </button>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#mapDetailEditAccordion">
                <div class="card-body">
                    <div *ngIf="map.background">
                        <img src="{{map.background.url}}" width="100%">
                        <p class="small">{{map.background.name}}</p>
                        <button type="button" class="btn btn-outline-primary btn-block" (click)="openUploadBackgroundModal()">Hintergrund ändern</button>
                        <button type="button" class="btn btn-outline-primary btn-block" (click)="resetBackground()">Standard wiederherstellen</button>
                    </div>
                    <div *ngIf="!map.background">
                        <p class="small">Bitte beachten Sie folgene Anforderungen für den Hintergrund:</p>
                        <ul class="small">
                            <li>Dateiformate: PNG, JPG oder GIF</li>
                            <li>Dateigröße: max. 1 MB</li>
                            <li>Seitenverhältnis: 16:9</li>
                        </ul>
                        <button type="button" class="btn btn-outline-primary btn-block" (click)="openUploadBackgroundModal()">Hintergrund ändern</button>
                        <button type="button" class="btn btn-outline-primary btn-block" (click)="resetBackground()">Standard wiederherstellen</button>
                    </div>                    
                </div>
            </div>
        </div>
    
        <div class="card my-3">
            <div class="card-header" id="headingThree">
                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <h5>Logo</h5>
                </button>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#mapDetailEditAccordion">
                <div class="card-body">
                    <div *ngIf="map.icon">
                        <img src="{{map.icon.url}}" width="100%">
                        <p class="small">{{map.icon.name}}</p>
                        <button type="button" class="btn btn-block btn-outline-danger" (click)="removeLogo()">Entfernen</button>
                    </div>
                    <div *ngIf="!map.icon">
                        <p class="small">Bitte beachten Sie folgene Anforderungen für das Logo:</p>
                        <ul class="small">
                            <li>Dateiformate: PNG, JPG oder GIF</li>
                            <li>Dateigröße: max. 1 MB</li>
                            <button type="button" class="btn btn-outline-primary btn-block" (click)="openUploadLogoModal()">Logo einfügen</button>
                        </ul>
                    </div>
                    
                </div>
            </div>
        </div>
    
        <div class="card my-3">
            <div class="card-header" id="headingFour">
                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                    <h5>Begrüßungsmusik</h5>
                </button>
            </div>
            <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#mapDetailEditAccordion">
                <div class="card-body">
                    <div *ngIf="map.startupMedia">
                        <audio controls>
                            <source src="{{map.startupMedia.url}}" tpye="audio/mpeg">
                        </audio>
                        <p class="small">{{map.startupMedia.name}}</p>
                        <button type="button" class="btn btn-block btn-outline-danger" (click)="removeStartupMedia()">Entfernen</button>
                    </div>
                    <div *ngIf="!map.startupMedia">
                        <p class="small">Bitte beachten Sie folgene Anforderungen für die Begrüßungsmusik:</p>
                        <ul class="small">
                            <li>Dateiformat: MP3</li>
                            <li>Dateigröße: max. 1 MB</li>
                        </ul>
                        <p class="small">Bitte stellen Sie sicher, dass Sie für die verwendete Musik über die entsprechenden Vervielfältigkeitsrechte verfügen.</p>
                    </div>
                    <button *ngIf="!map.startupMedia" type="button" class="btn btn-outline-primary btn-block" (click)="openUploadStartupMediaModal()">Begrüßungsmusik einfügen</button>
                </div>
            </div>
        </div>

        <div class="card my-3">
            <div class="card-header" id="headingFive">
                <button class="btn btn-link" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                    <h5>Chat-Ansprechpartner</h5>
                </button>
            </div>
            <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#mapDetailEditAccordion">
                <div class="card-body">
                    <div class="form-group">
                        <label for="selectChatContact">Chat-Ansprechpartner</label>
                        <select class="form-control" id="selectRole" [(ngModel)]="map.chatContact.id" name="chatContact">
                            <option *ngFor="let user of users" [ngValue]="user.id">{{user.firstName}}&nbsp;{{user.lastName}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="uploadBackgroundModal" tabindex="-1" role="dialog" aria-labelledby="uploadBackgroundModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="uploadBackgroundModalTitle">Hintergrund hochladen ...</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-media-upload (selectMediaEvent)="addBackground($event)"></app-media-upload>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="uploadLogoModal" tabindex="-1" role="dialog" aria-labelledby="uploadLogoModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="uploadLogoModalTitle">Logo hochladen ...</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-media-upload (selectMediaEvent)="addLogo($event)"></app-media-upload>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="uploadStartupMediaModal" tabindex="-1" role="dialog" aria-labelledby="uploadStartupMediaModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="uploadStartupMediaModalTitle">Begrüßungsmusik hochladen ...</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <app-media-upload (selectMediaEvent)="addStartupMedia($event)"></app-media-upload>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="mapPositionCreateModal" tabindex="-1" role="dialog" aria-labelledby="mapPositionCreateModalTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="mapPositionCreateModalTitle">Medien zum Center hinzufügen ...</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="reset()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" *ngIf="mediaPosition">
                <div *ngIf="selectedModule === null"> 
                    <div class="row">
                        <div class="col-md-8">
                            <div class="form-row mb-3">
                                <label for="inputTitle">Titel</label>
                                <input type="text" class="form-control" id="inputTitle" [(ngModel)]="mediaPosition.title" name="title" placeholder="Geben Sie hier den Titel für diese Position an" required>
                            </div>
                            <div class="form-row mb-3">
                                <label for="inputDescription">Beschreibung</label>
                                <textarea class="form-control" id="inputDescription" [(ngModel)]="mediaPosition.description" name="description" placeholder="Hier können Sie optional eine Beschreibung hinzufügen" rows="4" maxlength="255"></textarea>
                            </div>                            
                        </div>
                        <div class="col-md-4">
                            <div class="form-row mb-3">
                                <label for="selectIcon">Symbol</label>
                                <select class="form-control selectpicker" id="selectIcon" [(ngModel)]="mediaPosition.icon.id" name="icon">
                                    <option *ngFor="let icon of icons" [ngValue]="icon.id">{{icon.name}}</option>
                                </select>
                            </div>
                            <div class="form-row mb-3">
                                <div class="col-md-6 mb-3">
                                    <label for="inputPosX">Position X</label>
                                    <input type="number" class="form-control" id="inputPosX" [(ngModel)]="mediaPosition.positionX" name="positionX" placeholder="X">
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label for="inputPosX">Position Y</label>
                                    <input type="number" class="form-control" id="inputPosY" [(ngModel)]="mediaPosition.positionY" name="positionY" placeholder="Y">
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="card my-3">
                        <div class="card-header">
                            <h5>Medien</h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-hover" *ngIf="mediaPosition.media">
                                <thead>
                                    <tr>
                                        <th scope="col">Vorschau</th>
                                        <th scope="col">Bezeichnung</th>
                                        <th scope="col">Aktionen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let media of mediaPosition.media">
                                        <td [ngSwitch]="media?.mediatype?.id">
                                            <!-- image -->
                                            <img *ngSwitchCase="1" src="{{media.url}}" width="250">

                                            <!-- pdf -->
                                            <a *ngSwitchCase="2" href={{media.url}} target="blank">PDF anzeigen</a>

                                            <!-- mp4 -->
                                            <video *ngSwitchCase="4" controls width="250">
                                                <source src="{{media-url}}" type="video/mp4">
                                                Ihr Browser kann leider keine Videos abspielen. Klicken Sie <a href="{{media.url}}" target="blank">hier</a> um die Datei in einem neuen Tab zu öffnen.
                                            </video>

                                            <!-- youtube -->
                                            <iframe *ngSwitchCase="6" width="250" height="141" [src]="sanitizedUrls[media.id]" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                            <!-- mp3 -->
                                            <audio *ngSwitchCase="7" controls src="{{media.url}}">Ihr Browser kann leider keine Audiodateien abspielen. Klicken Sie <a href="{{media.url}}" target="blank">hier</a> um die Datei in einem neuen Tab zu öffnen</audio>
                                           
                                            <!-- vimeo -->
                                            <iframe *ngSwitchCase="10" [src]="sanitizedUrls[media.id]" width="250" height="141" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>

                                            <!-- pdf -->
                                            <a *ngSwitchCase="11" href={{media.url}} target="blank">Firmeninfo anzeigen</a>

                                            <!-- pdf -->
                                            <a *ngSwitchCase="12" href={{media.url}} target="blank">Link öffnen</a>
                                        </td>
                                        <td>{{media.name}}</td>
                                        <td>
                                            <button type="button" class="btn btn-block btn-outline-danger my-1" (click)="removeMedia(media.id)">Entfernen</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <button type="button" class="btn btn-outline-primary btn-block" (click)="selectModule('upload')">Bilder hochladen (JPG, PNG, GIF)</button>
                            <button type="button" class="btn btn-outline-primary btn-block" (click)="selectModule('upload')">Dokumente hochladen (PDF)</button>
                            <button type="button" class="btn btn-outline-primary btn-block" (click)="selectModule('link')">Link hinzufügen</button>
                            <button type="button" class="btn btn-outline-primary btn-block" (click)="selectModule('youtube')">YouTube-Video hinzufügen</button>
                            <button type="button" class="btn btn-outline-primary btn-block" (click)="selectModule('vimeo')">Vimeo-Video hinzufügen</button>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header">
                            <h5>Videochats</h5>
                        </div>
                        <div class="card-body">
                            <table class="table table-hover" *ngIf="mediaPosition.videochatrooms">
                                <thead>
                                    <tr>
                                        <th scope="col">Bezeichnung</th>
                                        <th scope="col">Aktionen</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let videochatroom of mediaPosition.videochatrooms">
                                        <td>{{videochatroom.name}}</td>
                                        <button type="button" class="btn btn-block btn-outline-danger my-1" (click)="removeVideochat(videochatroom.id)">Entfernen</button>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row">
                                <div class="col-12">
                                    <button type="button" class="btn btn-outline-primary btn-block" (click)="selectModule('videochat')">Videochat hinzufügen</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedModule === 'upload'">
                    <button type="button" class="btn btn-secondary mb-3" (click)="selectModule(null)">Zurück</button>
                    <app-media-upload (selectMediaEvent)="addMedia($event)"></app-media-upload>
                </div>

                <div *ngIf="selectedModule === 'link'">
                    <button type="button" class="btn btn-secondary mb-3" (click)="selectModule(null)">Zurück</button>
                    <app-media-link-create (selectMediaEvent)="addMedia($event)"></app-media-link-create>
                </div>

                <div *ngIf="selectedModule === 'youtube'">
                    <button type="button" class="btn btn-secondary mb-3" (click)="selectModule(null)">Zurück</button>
                    <app-media-youtube-create (selectMediaEvent)="addMedia($event)"></app-media-youtube-create>
                </div>

                <div *ngIf="selectedModule === 'vimeo'">
                    <button type="button" class="btn btn-secondary mb-3" (click)="selectModule(null)">Zurück</button>
                    <app-media-vimeo-create (selectMediaEvent)="addMedia($event)"></app-media-vimeo-create>
                </div>

                <div *ngIf="selectedModule === 'mediathek'">
                    <button type="button" class="btn btn-secondary mb-3" (click)="selectModule(null)">Zurück</button>
                    <app-media-list-compact [options]="byCompany" (selectMediaEvent)="addMedia($event)"></app-media-list-compact>
                </div>

                <div *ngIf="selectedModule === 'videochat'">
                    <button type="button" class="btn btn-secondary mb-3" (click)="selectModule(null)">Zurück</button>
                    <app-videochat-create (selectVideochatEvent)="addVideochat($event)"></app-videochat-create>
                </div>

            </div>
            <div class="modal-footer" *ngIf="selectedModule === null">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="reset()">Abbrechen</button>
                <button type="button" class="btn btn-primary" (click)="save()">Speichern</button>
            </div>
        </div>
    </div>
</div>
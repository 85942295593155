<h3>Abstimmung bearbeiten</h3>

<form #editVotingOptionForm="ngForm">
    <div class="form-group">
        <label for="inputName">Bezeichnung</label>
        <input type="text" class="form-control" id="inputName" [(ngModel)]="voting.name" name="name" placeholder="Bezeichnung" required>
    </div>
    
    <div class="form-group">
        <label for="inputDescription">Frage</label>
        <input type="text" class="form-control" id="inputDescription" [(ngModel)]="voting.description" name="description" placeholder="Frage" required>
    </div>

    <!--
    <div class="form-group">
        <label for="inputStartdate">Startzeit</label>
        <input type="datetime-local" class="form-control" id="inputStartdate" [(ngModel)]="voting.startdate" name="startdate">
    </div>

    <div class="form-group">
        <label for="inputExpiredate">Endzeit</label>
        <input type="datetime-local" class="form-control" id="inputExpiredate" [(ngModel)]="voting.expiredate" name="expiredate">
    </div>
    -->
    
    <button type="submit" class="btn btn-primary" [disabled]="!editVotingOptionForm.form.valid" (click)="saveAndClose()">Speichern</button>
</form>

<h4 class="mt-3">Antwortmöglichkeiten</h4>
<div class="list-group">
    <div *ngFor="let option of voting.options" class="list-group-item">
        <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{option.content}}</h5>
            <button type="button" class="btn btn-danger btn-sm" (click)="removeVotingOption(option.id)">Entfernen</button>
        </div>            
        <p class="mb-1">{{option.description}}</p>

    </div>
    <div class="list-group-item">
        <form #createVotingOptionForm="ngForm">
            <h5 class="mb-1">Antwortmöglichkeit hinzufügen ...</h5>
            <div class="form-row">
                <div class="col-md-4 form-group">
                    <label for="inputOptionContent">Antwort</label>
                    <input type="text" class="form-control" id="inputOptionContent" [(ngModel)]="newOption.content" name="content" placeholder="Antwortmöglichkeit" required>
                </div>
                <div class="col-md-8 form-group">
                    <label for="inputOptionDescription">Beschreibung</label>
                    <input type="text" class="form-control" id="inputOptionDescription" [(ngModel)]="newOption.description" name="description" placeholder="Beschreibung" required>
                </div>
            </div>    
            <button type="submit" class="btn btn-success btn-sm" [disabled]="!createVotingOptionForm.form.valid" (click)="addVotingOption()">Hinzufügen</button>        
        </form>
    </div>
</div>
import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import './external_api.js';
import { ActivatedRoute } from '@angular/router';
import { DecodeTokenService } from '../services/decode-token.service.js';
declare var JitsiMeetExternalAPI: any;
@Component({
  selector: 'app-videochat-raw',
  templateUrl: './videochat-raw.component.html',
  styleUrls: ['./videochat-raw.component.css']
})
export class VideochatRawComponent {
  @Input() roomId: string; // decorate the property with @Input()
  title = 'app';
  	domain:string = "meet.webeventstudios.com";
  	options: any;
  	api: any;

	constructor(private route: ActivatedRoute, private decodeTokenService: DecodeTokenService,
		private elementRef: ElementRef,
        private renderer: Renderer2)
	{
	}

	ngAfterViewInit(): void {
		const name= this.decodeTokenService.decodeFirstName() + ' '+ this.decodeTokenService.decodeLastName(); 
		const email= this.decodeTokenService.decodeEmail();
		const id = this.roomId;
		this.options = {
			roomName: "webeventstudiosPartner"+id,
      parentNode: document.querySelector('#meet'),
      userInfo:{email: email, displayName: name}
		}

		this.api = new JitsiMeetExternalAPI(this.domain, this.options);
	}

	mousedown(e){
		console.log(e);
	}
	
	
}
